enum UserRoleSystem {
  Admin = 1,
  Validator = 2,
}

enum Functions {
  AppSettingRead = 'APP_SETTING_R',
  AppSettingUpdate = 'APP_SETTING_U',

  PermissionGet = 'PERMISSION_G',
  PermissionList = 'PERMISSION_L',
  PermissionUpdate = 'PERMISSION_U',

  RoleCreate = 'ROLE_C',
  RoleDelete = 'ROLE_D',
  RoleGet = 'ROLE_G',
  RoleList = 'ROLE_L',
  RoleUpdate = 'ROLE_U',
  RolePermissionCreate = 'ROLE_PERMISSION_C',
  RolePermissionGet = 'ROLE_PERMISSION_G',
  RolePermissionDelete = 'ROLE_PERMISSION_D',
  RolePermissionList = 'ROLE_PERMISSION_L',
  RolePermissionUpdate = 'ROLE_PERMISSION_U',

  TraceCreate = 'TRAC_C',
  TraceGet = 'TRAC_G',
  TraceList = 'TRAC_L',

  UserCreate = 'USER_C',
  UserDelete = 'USER_D',
  UserGet = 'USER_G',
  UserList = 'USER_L',
  UserRoleGet = 'USER_ROLE_G',
  UserRoleList = 'USER_ROLE_L',
  UserRoleUpdate = 'USER_ROLE_U',
  UserUpdate = 'USER_U',

  UserProfileUpdate = 'USER_PROFILE_U',
  ProfilePictureCreate = 'PROFILE_PICTURE_C',
  ProfilePictureRead = 'PROFILE_PICTURE_R',
  ProfilePictureUpdate = 'PROFILE_PICTURE_U',
  ProfilePictureDelete = 'PROFILE_PICTURE_D',

  AdministrationMenuRead = 'ADMINISTRATION_MENU_R',

  TenantCreate = 'TENANT_C',
  TenantUpdate = 'TENANT_U',
  TenantDelete = 'TENANT_D',
  TenantList = 'TENANT_L',

  EventCreate = 'EVENT_C',
  EventUpdate = 'EVENT_U',
  EventDelete = 'EVENT_D',
  EventList = 'EVENT_L',

  SubscriptionCreate = 'SUBSCRIPTION_C',
  SubscriptionRead = 'SUBSCRIPTION_R',
  SubscriptionUpdate = 'SUBSCRIPTION_U',
  SubscriptionDelete = 'SUBSCRIPTION_D',
  SubscriptionList = 'SUBSCRIPTION_L',

  CreateTenantPicture = 'TENANT_PICTURE_C',
  ReadTenantPicture = 'TENANT_PICTURE_R',
  UpdateTenantPicture = 'TENANT_PICTURE_U',
  DeleteTenantPicture = 'TENANT_PICTURE_D',

  CreatePointOfSale = 'POINT_OF_SALE_C',
  ReadPointOfSale = 'POINT_OF_SALE_R',
  UpdatePointOfSale = 'POINT_OF_SALE_U',
  DeletePointOfSale = 'POINT_OF_SALE_D',

  CheckTicket = 'CHECK_TICKET',

  CreateOrderInPointOfSale = 'POINT_OF_SALE_ORDER_C',
}

/**
 * Numeric Types
 * Name	Storage Size	Range
 * smallint	2 bytes	-32768 to +32767
 * integer	4 bytes	-2147483648 to +2147483647
 * bigint	8 bytes	-9223372036854775808 to 9223372036854775807
 */
enum MaxLengthInputs {
  SystemUser_UserName = 20,
  SystemUser_Email = 100,
  StringInputs = 200,
  NumberInputs = 20,
  NumberInputsMaxInt = 2147483647,
  NumberInputsMaxBigInt = 9223372036854775807,
  CodeSap = 4,
  Region_DeliveryTerm = 2,
}

enum LocalStorageKeys {
  RoleSelected = 'role_selected',
  TranslationsVersions = 'translation_versions',
  Translations = 'translations',
  TwoFactorValidated = 'two_factor_validated',
  Token = 'token',
  UserId = 'userId',
  TwoFAOmitted = 'TwoFAOmitted',
}

enum TwoFactorValidatedValues {
  True = 'true',
  False = 'false',
}

export enum SystemLimits {
  //Se define limite de tamaño de archivos en bytes
  /*
    Recordar que este enum debe corresponder con el de backend.
  */
  MaxSizeOfFiles = 20000000, //20 MB,
}
export enum DefaultValues {
  Title = 'Eventos App',
}
export enum SettingNames {
  RecoveryPasswordFrom = 'RECOVERY_PASSWORD_FROM',
  RecoveryPasswordSubject = 'RECOVERY_PASSWORD_SUBJECT',
  NodeMailerHost = 'NODEMAILER_HOST',
  NodeMailerPort = 'NODEMAILER_PORT',
  NodeMailerSecure = 'NODEMAILER_SECURE',
  NodeMailerUser = 'NODEMAILER_USER',
  NodeMailerPassword = 'NODEMAILER_PASSWORD',
  NodeMailerAccountType = 'NODEMAILER_ACCOUNT_TYPE',
  UrlSite = 'URL_SITE',
  PasswordRegex = 'PASSWORD_REGEX',
  ProfileRequired = 'PROFILE_REQUIRED',
  SecurityTokenExpiresin = 'SECURITY_TOKEN_EXPIRESIN',
  SecurityRefreshTokenExpiresin = 'SECURITY_REFRESH_TOKEN_EXPIRESIN',
  FirstLogin = 'FIRST_LOGIN',
  LimitMaxSizeFile = 'LIMIT_MAX_SIZE_FILE',
  GAClientID = 'GA_ID',
  PageTitle = 'PAGE_TITLE',
}

export enum RoutesKeys {
  DefaultRedirect = 'defaultRedirect',
  App = 'app',
  Home = 'home',
  Config2fa = 'config2fa',
  Administration = 'administration',
  Event = 'event',
  Permission = 'permission',
  Role = 'role',
  Subscription = 'subscription',
  Tenant = 'tenant',
  User = 'user',
  Setting = 'setting',
  About = 'about',
  Profile = 'profile',
  ResetPassword = 'resetPassword',
  SetPassword = 'setPassword',
  Login = 'login',
  Reminder = 'reminder',
  Generate = 'generate',
  ValidateTotp = 'validateTotp',
  PublicLayout = 'publicLayout',
  HomePublic = 'homePublic',
  LoginRedirect = 'loginRedirect',
  ByTenantPage = 'byTenantPage',
  EventPublicPage = 'eventPublicPage',
  SubscriptionPublicPage = 'subscriptionPublicPage',
  ManagementUsersLayout = 'managementUsersLayout',
  CheckTicket = 'checkticket',
  purchaseConfirmationPage = 'purchaseConfirmationPage',
  CreatePersonPage = 'createPersonPage',
  Approved = 'payApproved',
  Disapproved = 'payDisapproved',
  PointsOfSale = 'pointsofsale',
  PointsOfSaleList = 'pointsofsalelist',
  PointsOfSaleEvent = 'pointsofsaleevent',
  PointsOfSaleEventSelection = 'pointsofsaleeventselection',
  PointsOfSaleTicketGeneration = 'pointsofsaleticketgeneration',
  AdminPointsOfSale = 'adminpointsofsale',
}

export enum ScanLogAction {
  ValidTicket = 1,
  InvalidTicket,
  UnknownQR,
  TicketAdmitted,
  TicketExpired,
  TicketExhausted,
}

export enum CustomErrorStatusCode {
  invalidTicket = 81,
  quantityTicketExhaustedCode = 82,
  ticketExpiredCode = 83,
}

export enum OrderStatus {
  draft = 1,
  approved = 2,
  rejected = 3,
}

export class EnumsValues {
  static readonly Functions = Functions;

  readonly Functions = EnumsValues.Functions;

  static readonly UserRoleSystem = UserRoleSystem;

  readonly UserRoleSystem = EnumsValues.UserRoleSystem;

  static readonly MaxLengthInputs = MaxLengthInputs;

  readonly MaxLengthInputs = EnumsValues.MaxLengthInputs;

  static readonly LocalStorageKeys = LocalStorageKeys;

  readonly LocalStorageKeys = EnumsValues.LocalStorageKeys;

  static readonly TwoFactorValidatedValues = TwoFactorValidatedValues;

  readonly TwoFactorValidatedValues = EnumsValues.TwoFactorValidatedValues;

  static readonly SettingNames = SettingNames;

  readonly SettingNames = EnumsValues.SettingNames;

  static readonly SystemLimits = SystemLimits;

  readonly SystemLimits = EnumsValues.SystemLimits;

  static readonly RoutesKeys = RoutesKeys;

  readonly RoutesKeys = EnumsValues.RoutesKeys;

  static readonly DefaultValues = DefaultValues;

  readonly DefaultValues = EnumsValues.DefaultValues;
  constructor(init?: Partial<EnumsValues>) {
    Object.assign(this, init);
  }
}
