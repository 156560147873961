export const EVENTS = `
query events(
  $orderBy: EventOrderInput
  $filter: FilterEventInput
  $searchText: String
  $skip: Int
  $take: Int
) {
  events(
    orderBy: $orderBy
    filter: $filter
    searchText: $searchText
    skip: $skip
    take: $take
  ) {
    __typename
    ... on Event {
      id
      name
      description
      card_description
      ubication
      published
      tenant_id
      event_picture_id
      visible_from
      visible_to
      its
      uts
      tenant{
        name
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;
export const PUBLISHED_EVENTS = `
query publishedEvents(
  $orderBy: EventOrderInput
  $filter: FilterEventInput
  $searchText: String
  $skip: Int
  $take: Int
) {
  publishedEvents(
    orderBy: $orderBy
    filter: $filter
    searchText: $searchText
    skip: $skip
    take: $take
  ) {
    __typename
    ... on Event {
      id
      name
      description
      card_description
      ubication
      published
      tenant_id
      event_picture_id
      visible_from
      visible_to
      its
      uts
      subscription {
        id
        start_date
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;

export const EVENT = `
query event($id: Int!) {
  event(id: $id) {
    __typename
    ... on Event {
      id
      name
      description
      card_description
      ubication
      published
      tenant_id
      event_picture_id
      visible_from
      visible_to
      its
      uts
      subscription{
        id
        ubication
        start_date
        price
        quantity
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;
export const PUBLIC_EVENT = `
query publicEvent($id: Int!) {
  publicEvent(id: $id) {
    __typename
    ... on Event {
      id
      name
      description
      card_description
      ubication
      published
      tenant_id
      event_picture_id
      subscription{
        id
        name
        ubication
        start_date
        price
        quantity
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;

export const EVENT_COUNT = `
query eventCount($filter: FilterEventInput, $orderBy: EventOrderInput) {
  eventCount(filter: $filter, orderBy: $orderBy) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
