import moment from 'moment-timezone';
import { useContext } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import {
  DEFAULT_FORMAT_DATE,
  DEFAULT_TIMEZONE,
  FORMAT_DATE_TIME_1,
  FORMAT_DATE_TIME_7,
} from '../../shared/MomentJS';

interface IGetDateWithTimeInput {
  element: Date | number | string;
}
const MomentTimezoneService = () => {
  const { user } = useContext(ContextApp);

  const getDateUTC = (date: IGetDateWithTimeInput) => {
    const { element } = date;
    return moment.utc(element).format(FORMAT_DATE_TIME_7);
  };

  const getDateWithOffsetAndTimezone = (date: IGetDateWithTimeInput) => {
    let timezone = user?.profile?.timezone;
    let date_moment = '';
    const { element } = date;
    if (moment(element).isValid()) {
      if (timezone && moment.tz.names().some((ele) => ele === timezone)) {
        date_moment = moment(element).tz(timezone).format(FORMAT_DATE_TIME_1);
        date_moment = `(GMT ${moment(element)
          .tz(timezone)
          .format(`Z`)}) ${date_moment} (${timezone})`;
      } else {
        date_moment = moment(element)
          .tz(
            process.env.REACT_APP_TIMEZONE
              ? process.env.REACT_APP_TIMEZONE
              : '',
          )
          .format(FORMAT_DATE_TIME_1);
        date_moment = `(GMT ${moment(element)
          .tz(
            process.env.REACT_APP_TIMEZONE
              ? process.env.REACT_APP_TIMEZONE
              : '',
          )
          .format(`Z`)}) ${date_moment} (${
          process.env.REACT_APP_TIMEZONE ? process.env.REACT_APP_TIMEZONE : ''
        })`;
      }
      return date_moment;
    }
  };

  const getDateWithTime = (date: IGetDateWithTimeInput) => {
    let timezone = user?.profile?.timezone;
    let date_moment = '';
    const { element } = date;
    if (moment(element).isValid()) {
      if (timezone && moment.tz.names().some((ele) => ele === timezone)) {
        date_moment = moment(element).tz(timezone).format(FORMAT_DATE_TIME_1);
      } else {
        date_moment = moment(element)
          .tz(process.env.REACT_APP_TIMEZONE ?? DEFAULT_TIMEZONE)
          .format(FORMAT_DATE_TIME_1);
      }
      return date_moment;
    }
  };

  const getDate = (date: IGetDateWithTimeInput) => {
    let timezone = user?.profile?.timezone;
    let date_moment = '';
    const { element } = date;
    if (moment(element).isValid()) {
      if (timezone && moment.tz.names().some((ele) => ele === timezone)) {
        date_moment = moment(element).tz(timezone).format(DEFAULT_FORMAT_DATE);
      } else {
        date_moment = moment(element)
          .tz(
            process.env.REACT_APP_TIMEZONE
              ? process.env.REACT_APP_TIMEZONE
              : '',
          )
          .format(DEFAULT_FORMAT_DATE);
      }
      return date_moment;
    }
  };

  return {
    getDateWithOffsetAndTimezone,
    getDateWithTime,
    getDate,
    getDateUTC,
  };
};

export default MomentTimezoneService;
