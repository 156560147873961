import { PageLoading } from '@ant-design/pro-layout';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import ExceptionManager from '../../components/common/ExceptionManager';
import { PublicContext } from '../../contexts/PublicContext';

import { HeaderComponent } from './HeaderComponent';

const PublicLayout = ({ route }: any) => {
  const [navbarText, setNavbarText] = useState<string>(
    process.env.REACT_APP_NAME ? process.env.REACT_APP_NAME : '',
  );
  const [navbarColor, setNavbarColor] = useState<string>('#D34848');
  const [subscriptionId, setSubscriptionId] = useState(0);
  const [quantity, setQuantity] = useState<number>(1);

  return (
    <PublicContext.Provider
      value={{
        navbarText,
        setNavbarText,
        navbarColor,
        setNavbarColor,
        subscriptionId,
        setSubscriptionId,
        quantity,
        setQuantity,
      }}
    >
      <Layout className="layout" style={{ height: '100%' }}>
        <HeaderComponent routes={route.routes} />
        <Content className="site-layout">
          <Suspense fallback={<PageLoading />}>
            <ExceptionManager>{renderRoutes(route.routes)}</ExceptionManager>{' '}
          </Suspense>
        </Content>
      </Layout>
    </PublicContext.Provider>
  );
};

export default PublicLayout;
