import { useContext, useState } from 'react';
import { CustomMessage } from '../../hooks';
import GraphqlService from '../../services/graphql/GraphqlService';
import './createPersonPage.less';
import { NotFound404 } from '../../components/NotFound404/NotFound404';
import { Typography, Input, Form, Button } from 'antd';
import { ContextApp } from '../../contexts/ContextApp';
import { NavContext } from '@ionic/react';

const CreatePersonPage = () => {
  const [savingData, setSavingData] = useState(false);
  const {
    messageError,
    messageCreating,
    messageCreateSuccess,
    messageCreateError,
  } = CustomMessage();
  const [form] = Form.useForm();
  // services and hooks
  const { customRequest, Mutation } = GraphqlService();
  // contexts
  const { navigate } = useContext(NavContext);
  const { user } = useContext(ContextApp);

  const formItemLayout = {
    className: 'form-createPerson',
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
  };

  const createPerson = async (value: any) => {
    setSavingData(() => true);
    messageCreating({
      context: 'CreatePersonPage.createPerson.1',
      message: 'Usuario',
    });
    try {
      await customRequest({
        mutation: Mutation.createAsociatedPerson,
        variables: { input: value },
      });
      messageCreateSuccess({
        context: 'CreatePersonPage.createPerson.2',
      });
      navigate('/app/home');
    } catch (error: any) {
      setSavingData(() => false);
      if (error.status_code && error.message) {
        return messageError({
          context: 'CreatePersonPage.createPerson.3',
          message: error.message,
        });
      }
      messageCreateError({ context: 'CreatePersonPage.createPerson.3' });
    }
    setSavingData(() => false);
  };

  return (
    <>
      {user ? (
        <>
          <div className="main-card-createPerson__container">
            <div className="main-card-createPerson">
              <div className="create-person-title">
                <Typography.Title level={3}>Registro</Typography.Title>
              </div>
              <div>
                <Form
                  onFinish={(values) => createPerson(values)}
                  {...formItemLayout}
                  form={form}
                >
                  <Typography.Title level={5}>
                    Para poder continuar debe ingresar sus datos personales
                  </Typography.Title>
                  <Form.Item
                    name="firstname"
                    label="Nombre"
                    rules={[
                      {
                        required: true,
                        message: 'El nombre es obligatorio',
                      },
                    ]}
                  >
                    <Input className="input-data" placeholder="Nombre" />
                  </Form.Item>
                  <Form.Item
                    name="lastname"
                    label="Apellido"
                    rules={[
                      {
                        required: true,
                        message: 'El apellido es obligatorio',
                      },
                    ]}
                  >
                    <Input className="input-data" placeholder="Apellido" />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: 'El e-mail es obligatorio',
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      className="input-data"
                      placeholder="Email"
                    />
                  </Form.Item>
                  <Form.Item name="phone" label="Teléfono">
                    <Input className="input-data" placeholder="Teléfono" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={savingData}
                      className="save-personal-data-button"
                    >
                      Guardar
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <NotFound404 subtitle="Lo sentimos, hubo un error en la página. Vuelva a intentarlo" />
        </>
      )}
    </>
  );
};
export default CreatePersonPage;
