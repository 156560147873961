import { ShoppingCartOutlined } from '@ant-design/icons';
import { Button, Card, Image, Skeleton } from 'antd';
import Meta from 'antd/lib/card/Meta';
import moment from 'moment';
import 'moment/locale/es';
import { ReactNode } from 'react';
import imageNotFound from '../../../assets/imageNotFound.jpg';

import './eventCard.less';

interface IEventCardProps {
  title: string;
  description: string;
  image: string | null;
  date?: Date;
  ubication?: string;
  onCardClick?: () => void;
  onButtonClick?: (() => void) | false;
  price?: number | false;
  color?: string;
  label?: string | ReactNode;
}

/**
 * @param title (string)
 * @param description (string - max 120 chars)
 * @param image (string - ideal size: 300x150)
 * @param date (Date) --OPTIONAL
 * @param ubication (string) --OPTIONAL
 * @param onCardClick (function) --OPTIONAL
 * @param onButtonClick (function) --OPTIONAL - false hide cart button
 * @param price (default: true) --OPTIONAL -- 0 or undefined --> shows "GRATIS" - false shows nothing
 * @param color (string - default:#6981d1db) --OPTIONAL
 * @param label (string | ReactNode) --OPTIONAL
 */
export const EventCard = (props: IEventCardProps) => {
  const {
    description,
    price,
    image,
    date,
    title,
    ubication,
    onCardClick,
    onButtonClick = false,
    color = '#6981d1db',
    label,
  } = props;
  return (
    <>
      <Card
        hoverable
        className={`event-card ${!onCardClick && 'event-card--cursor-default'}`}
        onClick={onCardClick}
        cover={
          <>
            {image !== null ? (
              <Image
                alt="example"
                placeholder={
                  image ? (
                    <Skeleton.Image className="event-card__image ant-skeleton-active" />
                  ) : (
                    imageNotFound
                  )
                }
                src={image}
                preview={false}
                className="event-card__image"
                fallback={imageNotFound}
              />
            ) : (
              <Image
                alt="example"
                preview={false}
                className="event-card__image"
                src={imageNotFound}
              />
            )}
            {price !== false && (
              <div className="event-card__price">
                {price ? `$${price}` : 'Gratis'}
              </div>
            )}
          </>
        }
      >
        <Meta
          avatar={
            date && (
              <>
                <div
                  className="event-card__meta-month"
                  style={{ color: color }}
                >
                  {moment(date).format('MMM').toUpperCase()}
                </div>
                <div className="event-card__meta-day">
                  {moment(date).format('DD')}
                </div>
                <div className="event-card__meta-hour">
                  {moment(date).format('HH:mm')}hs
                </div>
              </>
            )
          }
          title={
            <>
              <div className="event-card__meta-container-title">
                {title.toUpperCase()}
                {label ? (
                  <span className="event-card__meta-container-title__label">
                    {label}
                  </span>
                ) : null}
              </div>
            </>
          }
          description={
            <>
              <p>{description}</p>
            </>
          }
          className="event-card__body"
        />
        {ubication && (
          <div
            className="event-card__ubication"
            style={{ backgroundColor: color }}
          >
            {ubication}
          </div>
        )}
        {onButtonClick && (
          <Button
            type="primary"
            shape="circle"
            className="event-card__button"
            style={{ backgroundColor: color }}
            size="large"
            onClick={(e) => {
              e.stopPropagation();
              onButtonClick();
            }}
            icon={<ShoppingCartOutlined />}
          />
        )}
      </Card>
    </>
  );
};
