import {
  LogoutOutlined,
  TranslationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Menu, Modal } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserService } from '../../../../services/UserService';
import { LanguageButton } from './LanguageButton';
import './index.less';
import { NavContext } from '@ionic/react';
import { ContextApp } from '../../../../contexts/ContextApp';

export const AvatarComponent = () => {
  const { user, languages, userDataFirebase } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const { logout } = UserService();
  const [languageModalVisible, setLanguageModalVisible] = useState(false);
  const [, i18n] = useTranslation();

  const userMenu = (
    <Menu>
      <Menu.Item key="2" icon={<UserOutlined />}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          onClick={() => {
            navigate('/app/profile');
          }}
        >
          Mi perfil
        </a>
      </Menu.Item>
      <Menu.Item key="1" icon={<TranslationOutlined />}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          onClick={() => {
            setLanguageModalVisible(() => true);
          }}
        >
          Lenguaje
        </a>
      </Menu.Item>
      <Menu.Item key="0" icon={<LogoutOutlined />}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={() => logout()}>Cerrar sesión</a>
      </Menu.Item>
    </Menu>
  );

  return user?.person?.firstname || user?.username ? (
    <>
      <div className="AvatarComponent">
        <Dropdown overlay={userMenu} trigger={['click']}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <Avatar
              className="Avatar"
              src={
                userDataFirebase?.photoURL
                  ? userDataFirebase?.photoURL
                  : undefined
              }
            >
              {user?.person?.firstname
                ? user.person.firstname[0].toUpperCase()
                : user?.username[0].toUpperCase()}
            </Avatar>
            <span className="AvatarName">
              {user?.person?.firstname || user?.username}
            </span>
          </a>
        </Dropdown>
      </div>
      <Modal
        visible={languageModalVisible}
        onCancel={() => setLanguageModalVisible(() => false)}
        footer={null}
        title="Lenguaje"
      >
        {
          <div className="container-flag-buttons">
            {languages.length &&
              languages.map((language, index) => (
                <LanguageButton
                  onClick={() => {
                    i18n.changeLanguage(language.language_code);
                    setLanguageModalVisible(false);
                  }}
                  language={language}
                  i18nCurrentLanguage={i18n.language}
                  key={index}
                />
              ))}
          </div>
        }
      </Modal>
    </>
  ) : null;
};
