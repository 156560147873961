import { useContext } from 'react';
import { Button, Card } from 'antd';
import { NavContext } from '@ionic/react';
import './enable2FA.less';

const Enable2FA = () => {
  const { navigate } = useContext(NavContext);

  return (
    <>
      <Card title="Habilitar autenticación 2FA" className="enable2fa__card">
        <p>
          La autenticación en dos pasos agrega una capa adicional de seguridad a
          su cuenta al requerir más que solo una contraseña para iniciar sesión.
        </p>
        <Button
          type="primary"
          onClick={() => {
            navigate('/signin/generate');
          }}
        >
          Habilitar autenticación en dos pasos
        </Button>
      </Card>
    </>
  );
};

export default Enable2FA;
