import { useContext, useEffect, useState } from 'react';
import { CustomMessage } from '../../hooks';
import GraphqlService from '../../services/graphql/GraphqlService';
import './SubscriptionPublicPage.less';
import { NotFound404 } from '../../components/NotFound404/NotFound404';
import { PageLoading } from '@ant-design/pro-layout';
import { ISubscription } from '../../interfaces/Subscription';
import { Select, Typography, Button } from 'antd';
import moment from 'moment';
import { FORMAT_DATE_TIME_1 } from '../../shared/MomentJS';
import { PublicContext } from '../../contexts/PublicContext';
import { useHistory } from 'react-router';

const SubscriptionPublicPage = (props: any) => {
  const [subscription, setSubscription] = useState<ISubscription>();
  const [loadingSubscription, setLoadingSubscription] = useState(true);
  const { showMessageError } = CustomMessage();
  const [loadingEventPicture, setLoadingEventPicture] =
    useState<boolean>(false);
  const [urlFile, setUrlFile] = useState<string>();
  //context
  const { setSubscriptionId, setQuantity, quantity } =
    useContext(PublicContext);
  const history = useHistory();

  // services and hooks
  const { Query, customRequest } = GraphqlService();

  const getSubscription = async () => {
    setLoadingSubscription(true);
    try {
      setSubscription(
        await customRequest({
          query: Query.publicSubscription,
          variables: {
            id: parseInt(props.match.params.id),
          },
        }),
      );
    } catch (error: any) {
      showMessageError({
        context: 'getPublicSubscription.1',
        error: error,
      });
    }
    setLoadingSubscription(false);
  };

  const getEventPicture = async (event_picture_id?: number) => {
    if (!event_picture_id) return;
    setLoadingEventPicture(true);
    try {
      const data = await customRequest({
        query: Query.eventPictureLink,
        variables: { id: event_picture_id },
      });
      setUrlFile(data.result);
    } catch (error: any) {
      showMessageError({
        context: 'CompleteEvent.getEventPicture.1',
        error: error,
      });
    } finally {
      setLoadingEventPicture(false);
    }
  };

  useEffect(() => {
    getSubscription();
    setSubscriptionId(parseInt(props.match.params.id));
  }, []);

  useEffect(() => {
    getEventPicture(subscription?.event?.event_picture_id);
  }, [subscription]);

  useEffect(() => {
    setQuantity(1);
  }, []);

  return (
    <>
      {loadingSubscription || loadingEventPicture ? (
        <PageLoading />
      ) : (
        <>
          {subscription ? (
            <>
              <div className="container-subscription">
                {(loadingEventPicture || urlFile) && (
                  <div className="banner-container">
                    {loadingEventPicture ? (
                      <div className="banner-loading">
                        <PageLoading />
                      </div>
                    ) : (
                      <img
                        className="banner"
                        src={urlFile}
                        alt="subscription banner"
                      />
                    )}
                  </div>
                )}
                <div className="subscription-section ticket-section">
                  <div className="ticket-quantity">
                    <Typography.Title level={2}>
                      Cantidad de tickets
                    </Typography.Title>
                    <Select
                      placeholder="Seleccione cantidad"
                      options={new Array(Math.min(10, subscription.quantity))
                        .fill(0)
                        .map((_v, i) => ({
                          value: i + 1,
                          label: i + 1 === 1 ? '1 ticket' : `${i + 1} tickets`,
                        }))}
                      defaultValue={subscription.quantity === 0 ? undefined : 1}
                      disabled={subscription.quantity === 0}
                      onChange={(value) => setQuantity(value)}
                    />
                  </div>
                  {/*TODO: dar color del tenant al botón*/}
                  <Button
                    type="primary"
                    onClick={() => {
                      history.push({
                        pathname: '/purchaseConfirmation',
                        state: {
                          subscriptionId: subscription.id,
                          quantity: quantity,
                        },
                      });
                    }}
                    className="continue-button"
                    disabled={subscription.quantity === 0}
                  >
                    Continuar
                  </Button>
                </div>
                <div className="subscription-section details-section">
                  <Typography.Title level={3}>
                    {subscription.name}
                  </Typography.Title>
                  <div className="details-item">
                    Fecha inicio:{' '}
                    {moment(subscription.start_date).format(FORMAT_DATE_TIME_1)}
                  </div>
                  <div className="details-item">
                    Fecha fin:{' '}
                    {moment(subscription.end_date).format(FORMAT_DATE_TIME_1)}
                  </div>
                  <div className="details-item">
                    Ubicación:{' '}
                    {subscription.ubication ||
                      subscription.event?.ubication ||
                      'Sin ubicación'}
                  </div>
                  <div className="description-item">
                    {subscription.description}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <NotFound404 subtitle="Lo sentimos, el evento no existe o no está disponible." />
            </>
          )}
        </>
      )}
    </>
  );
};
export default SubscriptionPublicPage;
