import { Alert, Button, Form, Input } from 'antd';
import React, { useState, useContext, useEffect } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import { UserOutlined, LockOutlined, MobileOutlined } from '@ant-design/icons';
import GraphqlService from '../../services/graphql/GraphqlService';
import './style.less';
import { Link, Redirect } from 'react-router-dom';
import { PageLoading } from '@ant-design/pro-layout';
import { NavContext } from '@ionic/react';
import { Tools } from '../../shared';
import { Mutation } from '../../services/graphql/mutation';
import LoginFirebase from './LoginFirebase/LoginFirebase';
import { IAppSetting } from '../../interfaces/AppSetting';
import { IUser, IUserFirebase } from '../../interfaces/user';
import { CustomMessage } from '../../hooks';
import { EnumsValues } from '../../enums/EnumsValues';
import Text from 'antd/lib/typography/Text';
import { version } from '../../../package.json';

const LoginMessage = ({ content }: { content: string }) => (
  <Alert
    style={{
      marginBottom: 24,
    }}
    message={content}
    type="error"
    showIcon
  />
);

export interface LoginProps {
  location: any;
  onFinish?: () => {};
}

interface IDataLogin {
  password: string;
  remember: boolean;
  username: string;
}

// const loginForm = (el: any) => {
const LoginPage: React.FC<LoginProps> = (props) => {
  const [loginMessageError, setLoginMessageError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { customRequest, Query } = GraphqlService();
  const { checkAuth, setUserDataFirebase, user } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const { getErrorMessage, showMessageError } = CustomMessage();
  const [firstLogin, setFirstLogin] = useState<string>(
    process.env.REACT_APP_FIRST_LOGIN
      ? process.env.REACT_APP_FIRST_LOGIN
      : 'user_password',
  );

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const { username, password } = values as IDataLogin;

      const data: any = await customRequest({
        query: Mutation.login,
        variables: { username, password },
      });

      if (data.token) {
        localStorage.setItem(EnumsValues.LocalStorageKeys.UserId, data.user.id);
        localStorage.setItem(EnumsValues.LocalStorageKeys.Token, data.token);
        checkAuth();
        if (data.user.person_id) navigate('/app/home');
        else navigate('/signin/createPerson');
      } else {
        if (data.status) {
          localStorage.setItem(
            EnumsValues.LocalStorageKeys.UserId,
            data.response,
          );
          navigate('/signin/validateTotp');
        } else {
          showMessageError({
            context: 'LoginPage.onFinish.1',
            error: data.response,
          });
        }
      }
    } catch (error: any) {
      setLoginMessageError(
        Tools.capitalize(getErrorMessage(error) || 'Login incorrecto.'),
      );
    }
    setLoading(false);
  };

  const authUserFirebase = async (userFirebase: any) => {
    setLoading(true);
    try {
      let input: IUserFirebase = { uid: '', token: '' };
      if (userFirebase) {
        input.uid = userFirebase.uid;
        input.token = userFirebase.multiFactor.user.accessToken;
        if (userFirebase.displayName) {
          input.displayName = userFirebase.displayName;
        }
        if (userFirebase.email) {
          input.email = userFirebase.email;
        }
        if (userFirebase.photoURL) {
          input.photoURL = userFirebase.photoURL;
        }
        if (userFirebase.phoneNumber) {
          input.phoneNumber = userFirebase.phoneNumber;
        }
      }
      const res: IUser = await customRequest({
        query: Mutation.upsertUserFirebase,
        variables: { input: { ...input } },
      });

      const dataLogin = await customRequest({
        query: Mutation.loginFirebase,
        variables: { uid: res.uid },
      });

      if (dataLogin.token) {
        localStorage.setItem(
          EnumsValues.LocalStorageKeys.UserId,
          dataLogin.user.id,
        );
        localStorage.setItem(
          EnumsValues.LocalStorageKeys.Token,
          dataLogin.token,
        );
        checkAuth();
        if (dataLogin.user.person_id) navigate('/app/home');
        else navigate('/signin/createPerson');
      } else {
        if (dataLogin.status) {
          localStorage.setItem(
            EnumsValues.LocalStorageKeys.UserId,
            dataLogin.response,
          );
          navigate('/signin/validateTotp');
        } else {
          showMessageError({
            context: 'LoginPage.authUserFirebase.1',
            error: dataLogin.response,
          });
        }
      }
      setUserDataFirebase(userFirebase);
    } catch (error: any) {
      setLoginMessageError(
        Tools.capitalize(getErrorMessage(error) || 'Login incorrecto.'),
      );
      setLoading(false);
    }
  };

  //Ejemplo de utilización de subscripción por SSE

  // useEffect(() => {
  //   const events = new EventSource(
  //     `${process.env.REACT_APP_BACKEND_BASE}/eventSender/sse`,
  //   );

  //   if (!isListening) {
  //     events.onmessage = (event) => {
  //       console.log(event.data);
  //       test++;
  //       if (test >= 100) {
  //         events.close();
  //         console.log('Carga completa');
  //         setIsListening(false);
  //       }
  //     };
  //     setIsListening(true);
  //   }
  // }, [isListening]);

  const getFirstLoginSetting = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.FirstLogin },
        },
      });
      setFirstLogin(data.setting_value);
    } catch (error) {}
  };

  useEffect(() => {
    getFirstLoginSetting();
  }, []);

  if (user) {
    return <Redirect to={'/app/home'} />;
  }

  return loading ? (
    <PageLoading />
  ) : (
    <div className="Login">
      {firstLogin === 'user_password' ? (
        <>
          <Form
            title="Ionic App"
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={props?.onFinish || onFinish}
          >
            {loginMessageError && <LoginMessage content={loginMessageError} />}
            <Form.Item
              name="username"
              rules={[
                { required: true, message: 'Por favor ingrese su Usuario!' },
              ]}
            >
              <Input
                aria-label="username"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Usuario"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Por favor ingrese su Contraseña!' },
              ]}
            >
              <Input
                aria-label="password"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Contraseña"
              />
            </Form.Item>
            <Form.Item>
              <Button
                data-testid="ButtonSubmit"
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Iniciar sesión
              </Button>
            </Form.Item>

            <Link className="login-form-forgot" to="/signin/resetPassword">
              ¿Olvidó su contraseña?
            </Link>
          </Form>
          {/* Intentional */}
          {/* {!loading ? (
            <div className="container-buttons-login">
              <Button onClick={() => setFirstLogin('phone')} type="default">
                <>
                  <MobileOutlined style={{ marginRight: '10px' }} />
                  Iniciar sesión con el teléfono
                </>
              </Button>
            </div>
          ) : null} */}
        </>
      ) : firstLogin === 'phone' ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              marginTop: '40px',
            }}
          >
            <LoginFirebase
              onSignIn={(user) => {
                setLoading(true);
                authUserFirebase(user);
              }}
              messageError={(error) => {
                setLoginMessageError(error);
              }}
            />
          </div>
          {!loading ? (
            <div className="container-buttons-login">
              <Button
                onClick={() => setFirstLogin('user_password')}
                type="default"
              >
                <>
                  <UserOutlined style={{ marginRight: '10px' }} />
                  Iniciar sesión con usuario y contraseña
                </>
              </Button>
              {/* <LoginGoogle /> */}
            </div>
          ) : null}
        </>
      ) : firstLogin === 'google' ? (
        <>
          <div className="container-buttons-login">
            {/* <LoginGoogle /> */}
            {!loading ? (
              <>
                <Button onClick={() => setFirstLogin('phone')} type="default">
                  <>
                    <MobileOutlined style={{ marginRight: '10px' }} />
                    Iniciar sesión con el teléfono
                  </>
                </Button>
                <Button
                  onClick={() => setFirstLogin('user_password')}
                  type="default"
                >
                  <>
                    <UserOutlined style={{ marginRight: '10px' }} />
                    Iniciar sesión con usuario y contraseña
                  </>
                </Button>
              </>
            ) : null}
          </div>
        </>
      ) : (
        <>
          {setFirstLogin(
            process.env.REACT_APP_FIRST_LOGIN
              ? process.env.REACT_APP_FIRST_LOGIN
              : 'user_password',
          )}
        </>
      )}
      <Text type="secondary" style={{ marginTop: '10px' }}>
        Versión: {version}
      </Text>
    </div>
  );
};

export default LoginPage;
