import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';

import './index.less';

export const MenuTrigger = () => {
  const { mainMenuCollapsed, setMainMenuCollapsed } = useContext(ContextApp);
  function getStyles(): React.CSSProperties | undefined {
    if (mainMenuCollapsed) {
      return {
        left: 0,
      };
    }
    return {
      left: 200,
    };
  }
  return (
    <div className="menuTrigger" style={getStyles()}>
      {mainMenuCollapsed ? (
        <MenuUnfoldOutlined
          className="trigger"
          onClick={() => setMainMenuCollapsed(() => !mainMenuCollapsed)}
        />
      ) : (
        <MenuFoldOutlined
          className="trigger"
          onClick={() => setMainMenuCollapsed(() => !mainMenuCollapsed)}
        />
      )}
    </div>
  );
};
