import { useContext, useEffect, useState } from 'react';
import { ITenant } from '../../interfaces';
import GraphqlService from '../../services/graphql/GraphqlService';
import { Col, Layout, Row } from 'antd';
import './ByTenantPage.less';
import { EventCard } from '../../components/common/EventCard/EventCard';
import { NotFound404 } from '../../components/NotFound404/NotFound404';
import { IEvent } from '../../interfaces/Event';
import { PageLoading } from '@ant-design/pro-layout';
import { PublicContext } from '../../contexts/PublicContext';
import { Tools } from '../../shared';
import { Footer } from 'antd/lib/layout/layout';
import { NavContext } from '@ionic/react';
import EventService from '../../services/Event.service';
import { colSpans } from '../../constants/colSpans';
import moment from 'moment';

const ByTenantPage = (props: any) => {
  const [tenant, setTenant] = useState<ITenant>();
  const [tenantEvents, setTenantEvents] = useState<
    { event: IEvent; image: string | null }[]
  >([]);
  const [loadingTenant, setLoadingTenant] = useState(true);
  const [loadingTenantPicture, setLoadingTenantPicture] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [urlFile, setUrlFile] = useState<string>('');
  //context
  const { setNavbarText, navbarColor } = useContext(PublicContext);
  const { navigate } = useContext(NavContext);
  // services and hooks
  const { Query, customRequest } = GraphqlService();
  const { getLinkEventPicture } = EventService();

  const getTenantPicture = async (tenant_picture_id?: number) => {
    if (!tenant_picture_id) return;
    setLoadingTenantPicture(true);
    try {
      const data = await customRequest({
        query: Query.getTenantPicture,
        variables: { id: tenant_picture_id },
      });
      setUrlFile(
        Tools.getUrlOfBase64File({
          mimetype: data.mimetype,
          fileBase64: data.file,
        }),
      );
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoadingTenantPicture(false);
    }
  };

  const getTenant = async () => {
    setLoadingTenant(true);
    try {
      const tenant: ITenant = await customRequest({
        query: Query.tenant,
        variables: {
          input: {
            slug: props.match.params.slug,
            only_active: true,
          },
        },
      });
      if (tenant) {
        await getTenantPicture(tenant.tenant_picture_id);
      }
      setTenant(tenant);
    } catch (error: any) {
      console.error(error);
    }
    setLoadingTenant(false);
  };

  const getEvents = async () => {
    if (tenant?.id && tenant.id !== 0) {
      setLoadingEvents(true);
      try {
        const events: IEvent[] = await customRequest({
          query: Query.publishedEvents,
          variables: {
            filter: {
              tenant_id: tenant.id,
              visible_range: [new Date().getTime(), new Date().getTime()],
            },
          },
        });
        setTenantEvents(
          events.map((event) => ({
            event,
            image: null,
          })),
        );
        events.map(async (event) => {
          try {
            let currentPicture: string | null | undefined;
            currentPicture = await getLinkEventPicture(event.event_picture_id);
            setTenantEvents((currEvents) => {
              const currEvent = currEvents.find(
                (ev) => ev.event.id === event.id,
              );
              if (!currEvent) return currEvents;
              currEvent.image = currentPicture ? currentPicture : '';
              return [...currEvents];
            });
          } catch (error: any) {
            console.error(error);
          }
        });
      } catch (error: any) {
        console.error(error);
      }
      setLoadingEvents(false);
    }
  };

  useEffect(() => {
    getTenant();
  }, []);

  useEffect(() => {
    if (tenant) {
      getEvents();
      setNavbarText(tenant?.name);
    }
  }, [tenant]);

  const image: string = 'url(' + urlFile + ')';
  return (
    <>
      {loadingTenant ? (
        <PageLoading />
      ) : (
        <>
          <div className="tenant-page">
            <Layout>
              {tenant ? (
                <>
                  <div className="container-form-tenant">
                    <div className="content-container-center">
                      {(loadingTenantPicture || urlFile) && (
                        <div
                          className="container-avatar"
                          style={
                            urlFile
                              ? {
                                  backgroundImage: image,
                                }
                              : { background: 'lightgrey' }
                          }
                        >
                          {loadingTenantPicture && <PageLoading />}
                        </div>
                      )}

                      <div className="cards-events-container">
                        <div className="events-title">
                          Eventos / Suscripciones
                        </div>
                        {loadingEvents ? (
                          <PageLoading />
                        ) : (
                          <>
                            <div className="events-cards">
                              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                {tenantEvents.length ? (
                                  <>
                                    {tenantEvents.map((item) => {
                                      let eventDate = undefined;
                                      if (item.event.subscription.length) {
                                        eventDate =
                                          item.event.subscription.reduce(
                                            (prev, current) =>
                                              moment(prev).isBefore(
                                                current.start_date,
                                              )
                                                ? prev
                                                : current.start_date,
                                            new Date(
                                              new Date().getFullYear() + 1000,
                                              0,
                                              1,
                                            ) /* Fecha imposible de pasar */,
                                          );
                                      }
                                      return (
                                        <Col
                                          span={12}
                                          className="gutter-row"
                                          {...colSpans}
                                          key={item.event.id}
                                        >
                                          <div className="cards">
                                            <EventCard
                                              title={item.event.name}
                                              description={
                                                item.event.card_description
                                                  ? item.event.card_description
                                                  : ''
                                              }
                                              image={item.image}
                                              date={eventDate}
                                              ubication={item.event.ubication}
                                              price={false}
                                              onCardClick={() => {
                                                navigate(
                                                  '/event/' + item.event.id,
                                                );
                                              }}
                                            />
                                          </div>
                                        </Col>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <>
                                    <NotFound404
                                      title="No se encontraron eventos"
                                      subtitle="Lo sentimos, no hay eventos disponibles actualmente."
                                      hideButton
                                    />
                                  </>
                                )}
                              </Row>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <NotFound404 subtitle="Lo sentimos, el tenant no existe o no está disponible." />
                </>
              )}
            </Layout>
            <Footer
              style={{ backgroundColor: navbarColor, textAlign: 'center' }}
              className="footer-tenant"
            ></Footer>
          </div>
        </>
      )}
    </>
  );
};
export default ByTenantPage;
