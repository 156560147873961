import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { isPlatform } from '@ionic/react';
import moment from 'moment-timezone';
import ProTable, { ActionType } from '@ant-design/pro-table';
import { Badge, Button, Divider, Input, Modal, Select, Tooltip } from 'antd';
import { ExporterDropdown, SaveForm } from '../../components/common/ABM';
import { useContext, useCallback, useRef, useState, useEffect } from 'react';
import { ExportableColumn, ExportableTable } from '../../shared/Exporter';
import { SorterResult } from 'antd/lib/table/interface';
import GraphqlService from '../../services/graphql/GraphqlService';
import { Authorization, MomentJS, Tools, ABM } from '../../shared';
import ModalConfigureMultiple from '../../components/common/ModalConfigureMultiple/Index';
import { showCollapseRender } from '../../shared/showCollapseRender';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import type { IUser, IProfile, IAppSetting, IRole } from '../../interfaces';
import AssignTenantModal, {
  AssignTenant,
} from './TenantModal/AssignTenantModal';
import './User.less';

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = [
  'id',
  'username',
  'firstname',
  'lastname',
  'email',
  'role_id',
];
const LIST_SORTER = ['username', 'firstname', 'lastname'];

/**
 * Se configura por cada ABM diferente
 */
const TITLE_PRO_TABLE = 'Formulario de consulta';
const TITLE_CREATE_FORM = 'Crear usuario';
const TITLE_UPDATE_FORM = 'Editar usuario';
const INPUT_SEARCH_PLACEHOLDER = 'Buscar...';
const TITLE_UPDATE_PROFILE_FORM = 'Editar perfil de usuario';

export default function UserPage(props: any) {
  // props

  // states
  const { functions, languages, user, setUser } = useContext(ContextApp);
  const [searchText, setSearchText] = useState('');
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [updateProfileModalVisible, handleUpdateProfileModalVisible] =
    useState<boolean>(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [manageRolesModalVisible, setManageRolesModalVisible] = useState(false);
  const [editForm, setEditFormValues] = useState<any>({});
  const [editFormProfileValues, setEditFormProfileValues] =
    useState<any>(undefined);
  const [dataManageRoles, setDataManageRoles] = useState<IUser>({
    id: 0,
    username: '',
    password: '',
    roles: [],
    is_system_user: false,
    two_factor_auth_active: false,
    language_id: 2,
  });
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>('');
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [dataUserCombo, setDataUserCombo] = useState<IUser[]>([]);
  const [dataRoles, setDataRoles] = useState<IRole[]>([]);
  const [passwordRegex, setPasswordRegex] = useState<IAppSetting>();
  const [userId, setUserId] = useState<number>(0);

  // services and hooks
  const { mobileOnSizeChangeProTable, changeView, showComponent } =
    useProTableForMobile({
      layout: 'horizontal',
    });
  const { Query, Mutation, customRequest } = GraphqlService();
  const {
    messageError,
    messageDeleteSuccess,
    messageDeleting,
    messageCreating,
    messageCreateSuccess,
    messageCreateError,
    messageUpdating,
    messageUpdateSuccess,
    messageUpdateError,
  } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});
  const reloadUserCombo = useRef<boolean>(true);
  const assignTenantRef = useRef<AssignTenant>();

  const newUrlParams: { [key: string]: string } = {};
  const newUrlParamsArr: string[][] = props.location.search
    .replace('?', '')
    .split('&')
    .map((param: string) => param.split('='));
  newUrlParamsArr.map((param: string[]) => {
    if (param[1]) {
      newUrlParams[param[0]] = param[1];
      return param;
    }
    return undefined;
  });

  const [defaultRoleValue, setDefaultRoleValue] =
    useState<number | undefined>();

  useEffect(() => {
    setDefaultRoleValue(() => Number(newUrlParams.role_id) || undefined);
  }, [newUrlParams.role_id]);

  // contexts

  // methods

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const getRoles = async () => {
    try {
      const data: IRole[] = await customRequest({
        query: Query.roles,
      });
      setDataRoles(() => data);
      return {
        current: 1,
        data: data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  useEffect(() => {
    getRegex();
    getRoles();
  }, [setDataRoles]);

  const request = async (params: any) => {
    delete variables.current.filter;
    delete variables.current.orderBy;
    variables.current = {};

    if (defaultRoleValue && !params.role_id) {
      params.role_id = defaultRoleValue;
    }

    const search: any = ABM.valuesResult(params);
    if (searchText) {
      variables.current.searchText = searchText;
    } else {
      delete variables.current.searchText;
    }

    LIST_FILTER.forEach((element) => {
      try {
        if (search[element]) {
          if (!variables.current.filter) {
            variables.current.filter = {};
          }
          variables.current.filter[element] = search[element];
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    LIST_SORTER.forEach((element) => {
      try {
        if (search.sorter[element]) {
          if (!variables.current.orderBy) {
            variables.current.orderBy = {};
          }
          variables.current.orderBy.direction =
            Tools.getTypeOrderByTableSortParam(search.sorter[element]);
          variables.current.orderBy.field = element;
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });
    try {
      const data: IUser[] = await customRequest({
        query: Query.users,
        variables: variables.current,
      });
      if (reloadUserCombo.current) {
        setDataUserCombo(() => data);
        reloadUserCombo.current = false;
      }
      setDataTable(() => data);
      return {
        current: 1,
        data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const createUser = async (value: any) => {
    delete value.repeatPassword;
    const defaultLanguage = languages.find(
      (language) => language.default === true,
    );
    value.language_id = defaultLanguage?.id;
    if (dataUserCombo.some((ele) => ele.username === value.username)) {
      messageError({
        context: 'TableUser.createUser.3',
        message: 'Ese nombre de usuario ya existe. Intente de nuevo',
      });
    } else {
      setFormLoading(() => true);
      messageCreating({
        context: 'TableUser.createUser.1',
        message: 'Usuario',
      });
      const { email, firstname, lastname, ...input } = value;
      try {
        await customRequest({
          mutation: Mutation.createUser,
          variables: {
            input,
            inputPerson: { email, firstname, lastname },
          },
        });
        setCreateModalVisible(false);
        messageCreateSuccess({
          context: 'TableUser.createUser.2',
        });
        if (actionRef.current?.reset) {
          reloadUserCombo.current = true;
          actionRef.current.reset();
        }
      } catch (error: any) {
        if (error.status_code && error.message) {
          return messageError({
            context: 'TableUser.createUser.3',
            message: error.message,
          });
        }
        messageCreateError({ context: 'TableUser.createUser.3' });
      }
      setFormLoading(() => false);
    }
  };

  const upsertUserRoles = async (
    id: number,
    addRoles: number[],
    deleteRoles: number[],
  ) => {
    if (!addRoles.length && !deleteRoles.length) {
      return;
    }
    messageUpdating({
      context: 'TableUser.updateUser.1',
      message: 'usuario',
    });
    try {
      await customRequest({
        mutation: Mutation.upsertUserRole,
        variables: {
          input: {
            user_id: id,
            addRoleIds: addRoles,
            deleteRoleIds: deleteRoles,
          },
        },
      });
      messageUpdateSuccess({
        context: 'TableUser.updateUserRoles.2',
      });
      if (actionRef.current?.reset) {
        reloadUserCombo.current = true;
        actionRef.current.reset();
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'TableUser.createUser.3',
          message: error.message,
        });
      }
      messageUpdateError({ context: 'TableUser.updateUserRoles.3' });
    }
    setFormLoading(() => false);
    setManageRolesModalVisible(false);
  };

  const updateUser = async (value: any) => {
    delete value.repeatPassword;
    let inputProfile: any = {};
    let inputPerson: any = {};

    const { timezone, phone, firstname, lastname, email } = value;

    if (timezone) {
      inputProfile.timezone = timezone;
      delete value.timezone;
    }
    if (phone || firstname || lastname || email) {
      inputPerson.phone = phone;
      delete value.phone;
      inputPerson.firstname = firstname;
      delete value.firstname;
      inputPerson.lastname = lastname;
      delete value.lastname;
      inputPerson.email = email;
      delete value.email;
    }
    if (dataUserCombo.some((ele) => ele.id === editForm.id)) {
      let dataUser = dataUserCombo.find((ele) => ele.id === editForm.id);
      if (value.email === dataUser?.person?.email) {
        delete value.email;
      }
    }
    setFormLoading(() => true);
    messageUpdating({
      context: 'TableUser.updateUser.1',
      message: 'usuario',
    });

    try {
      const data = await customRequest({
        mutation: Mutation.updateUser,
        variables: {
          input: {
            ...value,
            id: editFormProfileValues?.idUser
              ? editFormProfileValues?.idUser
              : editForm.id,
          },
          inputProfile: inputProfile ? { ...inputProfile } : undefined,
          inputPerson: inputPerson ? { ...inputPerson } : undefined,
        },
      });
      const { profile, profile_id } = data;
      if (user?.profile !== profile) {
        if (user?.id === data.id) {
          setUser((oldState: IUser) => {
            const newState = { ...oldState };
            newState.profile = profile;
            newState.profile_id = profile_id;
            return newState;
          });
        }
        messageUpdateSuccess({
          context: 'TableUser.updateUser.2',
        });
        handleUpdateModalVisible(false);
        handleUpdateProfileModalVisible(false);
        if (actionRef.current?.reset) {
          reloadUserCombo.current = true;
          actionRef.current.reset();
        }
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'TableUser.createUser.3',
          message: error.message,
        });
      }
      messageUpdateError({ context: 'TableUser.updateUser.3' });
    }
    setFormLoading(() => false);
  };

  const removeConfirmedUser = async (value: any) => {
    messageDeleting({
      context: 'User.removeConfirmedUser.1',
      message: 'usuario',
    });
    try {
      await customRequest({
        mutation: Mutation.deleteUser,
        variables: {
          id: value.id,
        },
      });
      messageDeleteSuccess({
        context: 'User.removeConfirmedUser.2',
      });
      if (actionRef.current?.reset) {
        reloadUserCombo.current = true;
        actionRef.current.reset();
      }
    } catch (error: any) {
      messageError({
        context: 'User.removeConfirmedUser.3',
        message: error.message,
      });
    }
  };

  const removeUser = (value: any) => {
    Modal.confirm({
      content: (
        <>
          <div>¿Seguro que desea eliminar al usuario {value.username}?</div>
        </>
      ),
      cancelText: 'Cancelar',
      okText: 'Aceptar',
      onOk: () => {
        removeConfirmedUser(value);
      },
    });
  };

  const getRegex = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.PasswordRegex },
        },
      });
      setPasswordRegex(() => data);
    } catch (error: any) {
      messageError({
        context: 'User.getRegex.1',
        message: error?.message,
      });
    }
  };

  const columns = useCallback(
    (editMode: boolean): ExportableColumn<IUser>[] => [
      {
        export: false,
        dataIndex: 'idUser',
        title: 'id',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: 'username',
        title: 'Usuario',
        sorter: true,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'El nombre de usuario es obligatorio',
            },
            {
              max: EnumsValues.MaxLengthInputs.SystemUser_UserName,
              message: `El nombre de usuario debe tener un maximo de ${EnumsValues.MaxLengthInputs.SystemUser_UserName} caracteres`,
            },
            {
              validator: (_, value) => {
                if (dataUserCombo.some((ele) => ele.username === value)) {
                  return Promise.reject(
                    new Error('El nombre de usuario no está disponible.'),
                  );
                }
                return Promise.resolve();
              },
            },
          ],
        },
        render: (_: any, record: { username: any }) => record.username || '-',
        renderFormItem: () => (
          <Input placeholder="Ingrese nombre de usuario..." maxLength={100} />
        ),
        hideInTable: false,
        hideInSearch: true,
        hideInForm: editMode,
      },
      {
        export: false,
        dataIndex: 'id',
        title: 'Usuario',
        valueType: 'select',
        renderFormItem: () => (
          <Select
            options={dataUserCombo.map((dataUser) => ({
              label: dataUser.username,
              value: dataUser.id,
            }))}
            placeholder="Seleccione usuario..."
            allowClear
            showSearch
            filterOption={(inputValue, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'firstname',
        title: 'Nombre',
        sorter: true,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'El nombre es obligatorio',
            },
          ],
        },
        render: (_: any, record) => record.person?.firstname || '-',
        renderFormItem: () => (
          <Input placeholder="Ingrese nombre..." maxLength={200} />
        ),
        align: 'left',
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'lastname',
        title: 'Apellido',
        sorter: true,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'El nombre es obligatorio',
            },
          ],
        },
        render: (_: any, record) => record.person?.lastname || '-',
        renderFormItem: () => (
          <Input placeholder="Ingrese apellido..." maxLength={200} />
        ),
        align: 'left',
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'email',
        title: 'Email',
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'El nombre es obligatorio',
            },
            {
              max: EnumsValues.MaxLengthInputs.SystemUser_Email,
              message: `El email debe tener un maximo de ${EnumsValues.MaxLengthInputs.SystemUser_Email} caracteres`,
            },
            {
              type: 'email',
              message: 'No es un E-mail válido!',
            },
            {
              validator: (_, value) => {
                if (editMode) {
                  let dataUser = dataUserCombo.find(
                    (ele) => ele.id === editForm.id,
                  );
                  if (dataUser?.person?.email === value) {
                    return Promise.resolve();
                  }
                  if (
                    dataUserCombo.some(
                      (ele) =>
                        ele.person?.email === value && ele.id !== dataUser?.id,
                    )
                  ) {
                    return Promise.reject(
                      new Error('El email no está disponible.'),
                    );
                  }
                  return Promise.resolve();
                } else {
                  if (
                    dataUserCombo.some((ele) => ele.person?.email === value)
                  ) {
                    return Promise.reject(
                      new Error('El email no está disponible.'),
                    );
                  }
                  return Promise.resolve();
                }
              },
            },
          ],
        },
        render: (_: any, record) => record.person?.email || '-',
        renderFormItem: () => (
          <Input placeholder="Ingrese email..." maxLength={200} />
        ),
        align: 'left',
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        dataIndex: 'role',
        export: true,
        title: 'Rol',
        render: (_: any, record: IUser) => {
          return (
            <>
              {record.user_role?.map((elemento) => (
                <p>{elemento.role.name}</p>
              ))}
            </>
          );
        },
        align: 'left',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        renderDataExport: (record: any) => {
          let roles: string = '';
          if (record.user_role?.length) {
            record.user_role.map((element: any, index: number) => {
              roles = roles + element.role.name;
              if (index < record.user_role.length - 1) {
                roles = roles + ' - ';
              }
              return roles;
            });
          }
          return roles;
        },
      },
      {
        export: false,
        dataIndex: 'role_id',
        title: 'Role',
        valueType: 'select',
        renderFormItem: () => (
          <Select
            options={dataRoles.map((rol) => ({
              label: rol.name,
              value: rol.id,
            }))}
            {...(defaultRoleValue ? { value: defaultRoleValue } : {})}
            onDeselect={() => setDefaultRoleValue(undefined)}
            onClear={() => setDefaultRoleValue(undefined)}
            placeholder="Seleccione rol..."
            mode="multiple"
            allowClear
            showSearch
            filterOption={(inputValue, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        title: 'Op.',
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 170,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.UserUpdate,
            ) && !record.is_system_user ? (
              <>
                <Tooltip
                  key="edit_user"
                  trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                  title="Modificar usuario"
                >
                  <EditOutlined
                    className="pointer"
                    onClick={() => {
                      setEditFormValues(() => record);
                      handleUpdateModalVisible(() => true);
                    }}
                  />
                </Tooltip>
              </>
            ) : null}
            {Authorization.security(
              functions,
              EnumsValues.Functions.UserRoleUpdate,
            ) && !record.is_system_user ? (
              <>
                <Divider type="vertical" />
                <Tooltip
                  key="configure_rol_tooltip"
                  title="Configurar rol"
                  trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                >
                  <Badge dot count={record.user_role?.length === 0 ? 1 : 0}>
                    <UserOutlined
                      className="pointer"
                      onClick={() => {
                        setDataManageRoles(() => record);
                        setManageRolesModalVisible(true);
                      }}
                    />
                  </Badge>
                </Tooltip>
              </>
            ) : null}
            {Authorization.security(
              functions,
              EnumsValues.Functions.UserDelete,
            ) && !record.is_system_user ? (
              <>
                <Divider type="vertical" />
                <Tooltip
                  key="remove_user_tooltip"
                  trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                  title="Eliminar usuario"
                >
                  <DeleteOutlined
                    className="pointer"
                    onClick={() => {
                      removeUser(record);
                    }}
                  />
                </Tooltip>
              </>
            ) : null}

            {(Authorization.security(
              functions,
              EnumsValues.Functions.UserUpdate,
            ) ||
              Authorization.security(
                functions,
                EnumsValues.Functions.UserProfileUpdate,
              )) &&
            !record.is_system_user ? (
              <>
                <Divider type="vertical" />
                <Tooltip
                  key="edit_profile_user"
                  trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                  title="Modificar perfil"
                >
                  <ToolOutlined
                    className="pointer"
                    onClick={() => {
                      setEditFormProfileValues({
                        idUser: record.id,
                        timezone: record.profile?.timezone,
                        phone: record.person?.phone,
                      });
                      handleUpdateProfileModalVisible(() => true);
                    }}
                  />
                </Tooltip>
              </>
            ) : null}

            {Authorization.security(
              functions,
              EnumsValues.Functions.UserDelete,
            ) && !record.is_system_user ? (
              <>
                <Divider type="vertical" />
                <Tooltip
                  key="remove_user_tooltip"
                  trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                  title="Asignacion de tenants"
                >
                  <TeamOutlined
                    className="pointer"
                    onClick={() => {
                      setUserId(record.id);
                      assignTenantRef?.current?.toggleVisibility();
                    }}
                  />
                </Tooltip>
              </>
            ) : null}
          </>
        ),
      },
      {
        export: false,
        dataIndex: 'password',
        title: 'Contraseña',
        formItemProps: {
          rules: [
            {
              required: !editMode,
              message: 'Debe ingresar una contraseña',
            },
            {
              validator(_, value) {
                let regex = new RegExp(String(passwordRegex?.setting_value));
                if (!value || regex.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('La contraseña no cumple con los requisitos'),
                );
              },
            },
          ],
        },
        renderFormItem: () => (
          <Input.Password
            autoComplete="password"
            placeholder="Ingrese una contraseña..."
            maxLength={100}
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        renderFormItem: () => (
          <div className="messageRegExp">
            <Badge color="#606366" status="default" />
            <p className="textRegExp">{passwordRegex?.description}</p>
          </div>
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'repeatPassword',
        title: 'Repetir contraseña',
        formItemProps: {
          rules: [
            {
              required: !editMode,
              message: 'Debe repetir la contraseña',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Las contraseñas no coinciden!'),
                );
              },
            }),
          ],
        },
        renderFormItem: () => (
          <Input.Password
            placeholder="Repita la contraseña..."
            maxLength={200}
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
        dependencies: ['password'],
      },
    ],
    [dataUserCombo, editForm, passwordRegex, dataRoles, defaultRoleValue],
  );

  const columnsProfile = useCallback(
    (): ExportableColumn<IProfile & { phone: string }>[] => [
      {
        export: false,
        dataIndex: 'id',
        title: 'id',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: 'phone',
        title: 'Teléfono',
        sorter: true,
        render: (_: any, record) => record.phone || '-',
        renderFormItem: () => <Input placeholder="Ingrese teléfono..." />,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'timezone',
        title: 'Zona horaria',
        sorter: true,
        render: (_: any, record: { timezone: any }) => record.timezone || '-',
        renderFormItem: () => (
          <Select
            options={moment.tz
              .names()
              .map((option) => ({
                value: option,
                label: `(GMT ${moment.tz(option).format('Z')}) ${option.replace(
                  '_',
                  ' ',
                )}`,
              }))
              .sort(Tools.sortByZone)}
            placeholder="Seleccione zona horaria..."
            showArrow
            showSearch
            allowClear
            filterOption={(inputValue, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
    ],
    [],
  );

  let LIST_FILTER_NAMES = columns(false)
    .filter((value) => {
      if (
        LIST_FILTER.find(
          (element) =>
            element === value.dataIndex && value.hideInTable === false,
        )
      ) {
        return value.title;
      }
      return false;
    })
    .map((element) => {
      return element.title;
    });

  const exportableTable: ExportableTable<IUser> = {
    columns: columns(false),
    items: dataTable,
    filename: `user_${MomentJS.momentDefaultFormat()}`,
  };

  return (
    <>
      <ProTable<IUser>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        headerTitle={TITLE_PRO_TABLE}
        actionRef={actionRef}
        rowKey="id"
        search={{
          resetText: 'Limpiar',
          searchText: 'Buscar',
          // defaultCollapsed: false,
          collapseRender: showCollapseRender(columns(false)),
        }}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<IUser>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setDefaultRoleValue(() => undefined);
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <div className="content-search-table">
            <Tooltip
              key="searchtext"
              title={'Buscar por: ' + LIST_FILTER_NAMES.join(', ')}
            >
              <Input.Search
                size="middle"
                placeholder={INPUT_SEARCH_PLACEHOLDER}
                enterButton
                value={searchText}
                onSearch={handleSearch}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                allowClear={true}
              />
            </Tooltip>
          </div>,
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.UserCreate,
            ) ? (
              <Button
                type="primary"
                onClick={() => {
                  setCreateModalVisible(true);
                }}
              >
                <PlusOutlined /> Nuevo
              </Button>
            ) : null}
          </>,
          <>
            <ExporterDropdown exportable={exportableTable} />
          </>,
          <Button onClick={changeView} className="buttonChangeProTableView">
            Cambiar Vista
          </Button>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns(false)}
      />
      <SaveForm
        loading={formLoading}
        title={TITLE_CREATE_FORM}
        onCancel={() => {
          setCreateModalVisible(false);
        }}
        modalVisible={createModalVisible}
        onOk={(value) => createUser(value)}
        columns={columns(false)}
      />
      {editForm && (
        <SaveForm
          loading={formLoading}
          title={TITLE_UPDATE_FORM}
          modalVisible={updateModalVisible}
          values={{
            ...editForm,
            firstname: editForm.person?.firstname,
            lastname: editForm.person?.lastname,
            email: editForm.person?.email,
          }}
          columns={columns(true)}
          onOk={(value) => updateUser(value)}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues({});
          }}
        />
      )}
      <AssignTenantModal userId={userId} ref={assignTenantRef} />
      <SaveForm
        loading={formLoading}
        title={TITLE_UPDATE_PROFILE_FORM}
        modalVisible={updateProfileModalVisible}
        values={editFormProfileValues}
        columns={columnsProfile()}
        onOk={(value) => updateUser(value)}
        onCancel={() => {
          handleUpdateProfileModalVisible(false);
          setEditFormProfileValues(undefined);
        }}
      />
      {Authorization.security(
        functions,
        EnumsValues.Functions.UserRoleUpdate,
      ) && (
        <ModalConfigureMultiple<IRole>
          renderLabel={(entity) => entity.name}
          checks={dataRoles}
          data={[
            dataManageRoles.id,
            dataManageRoles.user_role?.map((dataRole) => dataRole.role.id) ||
              [],
          ]}
          onCancel={() => {
            setManageRolesModalVisible(false);
          }}
          onOk={(id_user, add_roles, delete_roles) => {
            return upsertUserRoles(id_user, add_roles, delete_roles);
          }}
          modalVisible={manageRolesModalVisible}
          titleModal="Administrar roles"
        />
      )}
    </>
  );
}
