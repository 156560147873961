import { Header } from 'antd/lib/layout/layout';
import { useContext } from 'react';
import { PublicContext } from '../../../contexts/PublicContext';
import { IRouteConfig } from '../../../interfaces/routeConfig';
import './index.less';

interface IHeaderComponentProps {
  routes: IRouteConfig[];
}
export const HeaderComponent = (_props: IHeaderComponentProps) => {
  const { navbarText, navbarColor } = useContext(PublicContext);

  return (
    <Header
      className="header-component"
      style={{ backgroundColor: navbarColor, fontFamily: 'Work Sans' }}
    >
      <div className="header-component--container-title-app">
        <h1>
          <a
            style={{ color: 'white', fontFamily: "'Poppins', sans-serif" }}
            href="/"
          >
            {navbarText}
          </a>
        </h1>
      </div>
    </Header>
  );
};
