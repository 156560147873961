import { CheckCircleOutlined } from '@ant-design/icons';
import { NavContext } from '@ionic/react';
import { Button } from 'antd';
import { useContext, useEffect } from 'react';
import { PublicContext } from '../../contexts/PublicContext';
import './PayApproved.less';

export const PayApproved = () => {
  const { navigate } = useContext(NavContext);
  const { setNavbarText } = useContext(PublicContext);

  useEffect(() => {
    if (process.env.REACT_APP_NAME) setNavbarText(process.env.REACT_APP_NAME);
  }, []);

  return (
    <div className="pay-approved">
      <div className="pay-approved__container">
        <div className="pay-approved__container__container-icon">
          <CheckCircleOutlined className="pay-approved__container__container-icon__icon" />
        </div>
        <div className="pay-approved__container__container-title">
          <span>¡Compra realizada!</span>
        </div>
        <div className="pay-approved__container__container-subtitle">
          <span>Recibirás los tickets por e-mail.</span>
        </div>
        <div className="pay-approved__container__container-button">
          <Button
            onClick={() => {
              navigate('/home');
            }}
            type="ghost"
          >
            Ir al inicio
          </Button>
        </div>
      </div>
    </div>
  );
};
