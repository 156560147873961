import { useContext, useState } from 'react';
import { Button, Card, Form } from 'antd';
import './Validate2FA.less';
import { NavContext } from '@ionic/react';
import GraphqlService from '../../../services/graphql/GraphqlService';
import { IResponseMessage } from '../../../interfaces/ResponseMessage';
import ReactCodeInput from 'react-code-input';
import { PageLoading } from '@ant-design/pro-layout';
import { CustomMessage } from '../../../hooks';
import { ContextApp } from '../../../contexts/ContextApp';
import { UserService } from '../../../services/UserService';
import { EnumsValues } from '../../../enums/EnumsValues';

const Validate2FA = () => {
  // services and hooks
  const [token2fa, setToken2fa] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [valid, setValid] = useState(true);
  const { Mutation, customRequest } = GraphqlService();
  const { logout } = UserService();
  const { showMessageError, messageError } = CustomMessage();

  const { checkAuth } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);

  const validateTwoFactorAuth = async () => {
    setLoading(true);
    try {
      const userId = localStorage.getItem(EnumsValues.LocalStorageKeys.UserId);
      const data: IResponseMessage = await customRequest({
        mutation: Mutation.twoFactorAuth,
        variables: { id: Number(userId), token: token2fa },
      });

      if (data.status) {
        localStorage.setItem(EnumsValues.LocalStorageKeys.Token, data.response);
        localStorage.setItem(
          EnumsValues.LocalStorageKeys.TwoFactorValidated,
          EnumsValues.TwoFactorValidatedValues.True,
        );
        await checkAuth();
        navigate('/app/home');
      } else {
        messageError({
          context: 'Validate2FA.validateTwoFactorAuth.1',
          message: data.response,
        });
      }
    } catch (error: any) {
      showMessageError({
        context: 'Validate2FA.validateTwoFactorAuth.2',
        error,
      });
    }
    setLoading(false);
  };

  const handleTokenChange = (value: string) => {
    setToken2fa(value);

    if (value.replace(/[0-9]/g, '').length) {
      setValid(false);
    } else {
      setValid(true);
    }
  };

  const returnToLogin = () => {
    navigate('/signin/login');
    localStorage.removeItem(EnumsValues.LocalStorageKeys.UserId);
    logout();
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      <Card title="Validar código" className="main-card-validate2FA">
        <p>
          Ingrese el código de acceso generado por la aplicación autenticadora.
        </p>

        <Form>
          <div className="input-token">
            <div className="container-input">
              <ReactCodeInput
                fields={6}
                type={'text'}
                name={'token-totp'}
                inputMode={'numeric'}
                onChange={handleTokenChange}
                isValid={valid}
              />

              {!valid && (
                <p className="warning-input">
                  El token de acceso es numérico. No se permiten letras ni
                  símbolos.
                </p>
              )}
            </div>

            <div className="container-buttons">
              <Button type="text" onClick={returnToLogin}>
                Volver al login
              </Button>
              <Button
                onClick={validateTwoFactorAuth}
                disabled={!valid}
                type={'primary'}
                htmlType="submit"
              >
                Ingresar
              </Button>
            </div>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default Validate2FA;
