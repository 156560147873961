import VCard from 'vcard-creator';

interface vCardInput {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  emailType?: string;
  phoneType?: string;
}

export const vCardGenerator = (input: vCardInput) => {
  const myVCard = new VCard();
  const { firstname, lastname, email, phone, emailType, phoneType } = input;

  myVCard
    .addName(lastname, firstname)
    .addEmail(email, emailType)
    .addPhoneNumber(phone, phoneType);
  return myVCard.toString();
};
