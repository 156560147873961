import { useContext, useState } from 'react';
import { Button, Card } from 'antd';
import { NavContext } from '@ionic/react';
import GraphqlService from '../../../services/graphql/GraphqlService';
import { IResponseMessage } from '../../../interfaces/ResponseMessage';
import { CustomMessage } from '../../../hooks';
import { ContextApp } from '../../../contexts/ContextApp';
import { PageLoading } from '@ant-design/pro-layout';
import QrCode from '../../../components/common/QrCode';
import './generate2FA.less';

const Generate2FA = () => {
  // services and hooks
  const [totpUrl, setTotpUrl] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { Mutation, customRequest } = GraphqlService();
  const { showMessageError } = CustomMessage();

  const { user, setShow2FAReminder } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);

  const generateTotpUrl = async () => {
    setLoading(true);
    try {
      const data: IResponseMessage = await customRequest({
        mutation: Mutation.generateTotpUrl,
      });
      setTotpUrl(data.response);
    } catch (error: any) {
      showMessageError({
        context: 'Generate2FA.generateTotpUrl.1',
        error,
      });
    }
    setLoading(false);
  };

  const qrContainer = totpUrl && (
    <Card className="main-card-generate2FA" type="inner">
      <p>
        Escanee el código QR con un autenticador para poder generar los tokens
        de acceso.
      </p>
      <QrCode value={totpUrl} />
      <Button
        className="main-card-generate2FA__button"
        onClick={() => {
          navigate('/signin/validateTotp');
        }}
      >
        Continuar
      </Button>
    </Card>
  );

  return (
    <>
      <div className="main-card-generate2FA__container">
        <Card
          title="Configure la autenticación en dos pasos"
          className="main-card-generate2FA"
          type="inner"
        >
          <p>
            Para utilizar la autenticación en dos pasos (2FA) necesitará una
            aplicación TOTP basada en la nube como Autenticador de Google, Authy
            o Microsoft Authenticator. Haga click en el botón de "Generar código
            QR" para generar la imágen.
          </p>

          <Button
            type="text"
            onClick={() => {
              if (user) {
                setShow2FAReminder(true);
                navigate('/app/home');
              } else {
                navigate('/');
              }
            }}
          >
            Volver
          </Button>

          <Button type="text" onClick={generateTotpUrl}>
            Generar código QR
          </Button>
        </Card>
        {loading ? <PageLoading /> : qrContainer}
      </div>
    </>
  );
};

export default Generate2FA;
