import {
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Modal, Form, Row, Col, Select, List, Button, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ITenant, IUserTenant } from '../../../interfaces';
import { CustomMessage } from '../../../hooks';
import GraphqlService from '../../../services/graphql/GraphqlService';

const { Option } = Select;

interface IProps {
  userId: number;
}

export interface AssignTenant {
  toggleVisibility: () => void;
}

const AssignTenantModal = forwardRef(({ userId }: IProps, ref) => {
  const [form] = Form.useForm();

  const { messageError, messageUpdateSuccess } = CustomMessage();
  const { Query, Mutation, customRequest } = GraphqlService();

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [tenants, setTenants] = useState<ITenant[]>([]);
  const [tenantAssigned, setTenantAssigned] = useState<ITenant[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleVisibility = (): void => setIsVisible(!isVisible);

  useImperativeHandle(ref, () => {
    return {
      toggleVisibility,
    };
  });

  const upsertTenant = async (addTenant: number[], removeTenant: number[]) => {
    if ((!addTenant.length && !removeTenant.length) || !userId) return;
    setIsLoading(true);

    try {
      await customRequest({
        mutation: Mutation.upsertUserTenant,
        variables: {
          input: {
            user_id: userId,
            removeTenantId: removeTenant,
            addTenantId: addTenant,
          },
        },
      });
      messageUpdateSuccess({
        context: 'upsertTenant',
      });
      await getUserTenants();
    } catch (error: any) {
      messageError({
        context: 'upsertTenant',
        message: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getUserTenants = async () => {
    if (!userId) return;
    try {
      const data = await customRequest({
        query: Query.findManyUserTenants,
        variables: {
          filter: {
            user_id: userId,
          },
        },
      });

      setTenantAssigned(data.map((item: IUserTenant) => item.tenant));
    } catch (error) {
      messageError({
        context: 'upsertTenant',
        message: 'Error al recuperar los tenants del usuario',
      });
    }
  };

  const getTenants = async () => {
    if (!userId) return;
    try {
      const response: ITenant[] = await customRequest({
        query: Query.tenants,
      });
      setTenants(() => response);
    } catch (error) {
      messageError({
        context: 'upsertTenant',
        message: 'Error al recuperar tenants',
      });
    }
  };

  const tenantsToShow = useMemo(() => {
    return tenants.filter((item: ITenant) => {
      return !tenantAssigned.find((item2: ITenant) => item2.id === item.id);
    });
  }, [tenants, tenantAssigned]);

  useEffect(() => {
    getUserTenants();
    getTenants();
  }, [userId]);

  return (
    <>
      <Modal
        title="Asignación de tenants"
        visible={isVisible}
        style={{ minWidth: '60%' }}
        footer={null}
        onCancel={toggleVisibility}
      >
        <Row gutter={40}>
          <Col lg={10} xs={24}>
            <Form
              layout="vertical"
              form={form}
              onFinish={(value) => {
                upsertTenant([value.tenant], []);
                form.resetFields();
              }}
            >
              <Form.Item
                label={'Buscar tenant'}
                name="tenant"
                rules={[
                  { required: true, message: `Por favor, ingrese valor` },
                ]}
              >
                <Select
                  showSearch
                  allowClear
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  dropdownMatchSelectWidth={false}
                  placeholder="Seleccione"
                  filterOption={(inputValue: string, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {tenantsToShow &&
                    tenantsToShow.map((item: ITenant) => {
                      return (
                        <Option key={item.name} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}{' '}
                </Select>
              </Form.Item>
              <div className="buttons">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={isLoading}
                  style={{ marginBottom: '15px' }}
                >
                  Asignar
                </Button>
              </div>
            </Form>
          </Col>
          <Col lg={14} xs={24}>
            <List
              size="small"
              header={<b>Tenants asignados</b>}
              bordered
              style={{ marginBottom: '10px', height: '300px' }}
              dataSource={tenantAssigned}
              renderItem={(item: ITenant, index) => (
                <List.Item
                  key={index}
                  actions={[
                    <Button
                      key={index}
                      onClick={() => upsertTenant([], [item.id])}
                      loading={isLoading}
                    >
                      <Space>
                        <DeleteOutlined />
                        Quitar
                      </Space>
                    </Button>,
                  ]}
                >
                  {item.name}
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
});

export default AssignTenantModal;
