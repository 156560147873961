import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  EyeOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import ProTable, { ActionType } from '@ant-design/pro-table';
import {
  Alert,
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Switch,
  Tooltip,
  Upload,
} from 'antd';
import { ExporterDropdown, SaveForm } from '../../components/common/ABM';
import {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { ExportableColumn, ExportableTable } from '../../shared/Exporter';
import { SorterResult } from 'antd/lib/table/interface';
import GraphqlService from '../../services/graphql/GraphqlService';
import {
  ABM,
  Authorization,
  MomentJS,
  Tools,
  tooltipTrigger,
} from '../../shared';
import { isPlatform } from '@ionic/react';
import { showCollapseRender } from '../../shared/showCollapseRender';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import { ParamsType } from '@ant-design/pro-provider';
import { getPaginationArgs } from '../../shared/getPaginationArgs';
import { IEvent, IEventCreate } from '../../interfaces/Event';
import { FORMAT_DATE_TIME_1, FORMAT_DATE_TIME_4 } from '../../shared/MomentJS';
import MomentTimezoneService from '../../services/moment-timezone/MomentTimezoneService';
import moment from 'moment';
import { IAppSetting, ITenant } from '../../interfaces';
import { EventCard } from '../../components/common/EventCard/EventCard';
import { RcFile } from 'antd/lib/upload/interface';

const { TextArea } = Input;

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = [
  'name',
  'published',
  'ubication',
  'description',
  'card_description',
  'visible_range',
];
const LIST_SORTER = [
  'name',
  'its',
  'uts',
  'ubication',
  'visible_from',
  'visible_to',
];

/**
 * Se configura por cada ABM diferente
 */
const TITLE_PRO_TABLE = 'Formulario de consulta';
const TITLE_CREATE_FORM = 'Formulario de alta';
const TITLE_UPDATE_FORM = 'Editar Evento';
const INPUT_SEARCH_PLACEHOLDER = 'Buscar...';

export default function EventPage() {
  // props
  // states
  const [searchText, setSearchText] = useState('');
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] = useState<IEvent | undefined>(undefined);
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>('');
  const [dataTable, setDataTable] = useState<IEvent[]>([]);
  const [tenantsCombo, setTenantsCombo] = useState<ITenant[]>([]);
  const [published, setPublished] = useState(false);
  const [previsualizedEventId, setPrevisualizedEventId] = useState<number>();
  const [maxSizeFile, setMaxSizeFile] = useState<number>(
    EnumsValues.SystemLimits.MaxSizeOfFiles,
  );
  const [errorFile, setErrorFile] = useState<string>();
  const [urlFile, setUrlFile] = useState<string>();
  const [fileMetaData, setFileMetaData] =
    useState<RcFile & { filename?: string }>();
  const [uploadImage, setUploadImage] = useState<boolean>(false);
  const [deleteCurrentImage, setDeleteCurrentImage] = useState<boolean>(false);
  const [loadedPictureId, setLoadedPictureId] = useState<undefined | number>();
  // services and hooks
  const { getDateWithTime } = MomentTimezoneService();
  const { mobileOnSizeChangeProTable, changeView, showComponent } =
    useProTableForMobile({
      layout: 'horizontal',
    });
  const { Query, Mutation, customRequest, customFileRequest } =
    GraphqlService();
  const [loadingEventPicture, setLoadingEventPicture] =
    useState<boolean>(false);

  const {
    messageError,
    messageDeleteSuccess,
    messageDeleting,
    messageUpdating,
    messageUpdateSuccess,
    messageUpdateError,
    messageCreating,
    messageCreateSuccess,
    messageCreateError,
    showMessageError,
  } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  // contexts
  const { functions, user } = useContext(ContextApp);

  // methods

  const cleanupState = () => {
    setPublished(false);
  };

  useEffect(() => {
    if (!createModalVisible && !updateModalVisible) {
      cleanupState();
    }
  }, [createModalVisible, updateModalVisible]);

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const getLimitMaxSizeFileSetting = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.LimitMaxSizeFile },
        },
      });
      setMaxSizeFile(Number(data.setting_value));
    } catch (error) {
      // Intentional
    }
  };

  const getTenantsForCombo = async () => {
    try {
      const data: ITenant[] = await customRequest({
        query: Query.tenants,
      });
      setTenantsCombo(data);
    } catch {
      // Intentional
    }
  };

  useEffect(() => {
    if (user?.profile_picture_id) {
      getLimitMaxSizeFileSetting();
    }
  }, []);

  useEffect(() => {
    if (user) {
      getTenantsForCombo();
    }
  }, [user]);

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    },
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search = ABM.valuesResult(params);

      if (searchText) {
        variables.current.searchText = searchText;
      } else {
        delete variables.current.searchText;
      }

      LIST_FILTER.forEach((element) => {
        try {
          if (search[element]) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }

            variables.current.filter[element] = search[element];

            if (element === 'visible_range') {
              const newValues = Tools.fullDaysInRange({
                minDate: search[element][0],
                maxDate: search[element][1],
              });
              variables.current.filter[element] = [
                newValues.minDate,
                newValues.maxDate,
              ];
            }
            if (variables.current.filter[element] === 'true') {
              variables.current.filter[element] = true;
            } else if (variables.current.filter[element] === 'false') {
              variables.current.filter[element] = false;
            }
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      LIST_SORTER.forEach((element) => {
        try {
          if (search.sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search.sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      const countPromise = customRequest({
        query: Query.eventCount,
        variables: variables.current,
      }).then((data: { count: number }) => data.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current,
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = customRequest({
        query: Query.events,
        variables: variables.current,
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);
      setDataTable(data);
      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const resetImageStates = () => {
    setErrorFile(() => undefined);
    setFileMetaData(() => undefined);
    setLoadingEventPicture(() => false);
    setUploadImage(() => false);
    setUrlFile(() => undefined);
    setDeleteCurrentImage(() => false);
  };

  const createEvent = async (value: IEventCreate) => {
    setFormLoading(true);
    messageCreating({
      context: 'EventPage.createEvent.1',
      message: 'Evento',
    });

    delete value.event_picture_id;

    try {
      await customFileRequest(
        {
          mutation: Mutation.createEvent,
          variables: {
            input: { ...value, published: published },
            file:
              fileMetaData && uploadImage
                ? {
                    filename: fileMetaData?.filename,
                    mimetype: fileMetaData?.type,
                    encoding: 'base64',
                  }
                : undefined,
          },
        },
        fileMetaData && uploadImage
          ? [
              {
                file: fileMetaData,
                path: 'variables.file',
              },
            ]
          : [],
      );
      setCreateModalVisible(false);
      messageCreateSuccess({
        context: 'EventPage.createEvent.2',
      });
      resetImageStates();
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        setFormLoading(false);
        return messageError({
          context: 'EventPage.createEvent.3',
          message: error.message,
        });
      }
      messageCreateError({ context: 'EventPage.createEvent.3' });
    }
    setFormLoading(false);
  };

  const updateEvent = async (value: IEventCreate) => {
    if (!editForm) return;

    setFormLoading(true);
    messageUpdating({
      context: 'EventPage.updateEvent.1',
      message: 'Evento',
    });

    delete value.event_picture_id;

    try {
      await customFileRequest(
        {
          mutation: Mutation.updateEvent,
          variables: {
            id: editForm.id,
            input: {
              ...value,
              tenant_id: isNaN(value.tenant_id)
                ? editForm.tenant_id
                : value.tenant_id,
              published: published,
              deleteCurrentImage,
            },
            file:
              fileMetaData && uploadImage
                ? {
                    filename: fileMetaData?.name,
                    mimetype: fileMetaData?.type,
                    encoding: 'base64',
                  }
                : undefined,
          },
        },
        fileMetaData && uploadImage
          ? [
              {
                file: fileMetaData,
                path: 'variables.file',
              },
            ]
          : [],
      );
      messageUpdateSuccess({
        context: 'EventPage.updateEvent.2',
      });
      resetImageStates();
      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      setFormLoading(false);
      if (error.status_code && error.message) {
        return messageError({
          context: 'EventPage.updateEvent.3',
          message: error.message,
        });
      }
      messageUpdateError({ context: 'EventPage.updateEvent.3' });
    }
    setFormLoading(false);
  };

  const removeConfirmedEvent = async (value: { id: number; name: string }) => {
    messageDeleting({
      context: 'EventPage.deleteEvent.1',
      message: 'Evento',
    });
    try {
      await customRequest({
        mutation: Mutation.deleteEvent,
        variables: {
          id: value.id,
        },
      });
      messageDeleteSuccess({
        context: 'EventPage.deleteEvent.2',
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      messageError({
        context: 'EventPage.deleteEvent.3',
        message: error.message,
      });
    }
  };

  const removeEvent = (value: { id: number; name: string }) => {
    Modal.confirm({
      content: (
        <>
          <div>¿Seguro que desea eliminar el evento {value.name}?</div>
        </>
      ),
      cancelText: 'Cancelar',
      okText: 'Aceptar',
      onOk: () => {
        removeConfirmedEvent(value);
      },
    });
  };

  const getEventPicture = async (event_picture_id: number) => {
    let currentPictureId: number | undefined | null;
    setLoadedPictureId(event_picture_id);
    if (!event_picture_id) return;
    setLoadingEventPicture(true);
    try {
      const data = await customRequest({
        query: Query.eventPictureLink,
        variables: { id: event_picture_id },
      });
      const url = data.result;

      setLoadedPictureId((oldValue) => {
        currentPictureId = oldValue;
        return event_picture_id;
      });
      if (event_picture_id === currentPictureId) {
        setUrlFile(url);
      }
    } catch (error: any) {
      showMessageError({
        context: 'CompleteEvent.getEventPicture.1',
        error: error,
      });
    } finally {
      setLoadingEventPicture(false);
    }
  };

  const columns = useCallback(
    (
      editMode: boolean,
      createMode: boolean = false,
    ): ExportableColumn<IEvent>[] => [
      {
        export: false,
        dataIndex: 'id',
        title: 'Nombre',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: 'name',
        title: 'Nombre',
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'El nombre es obligatorio',
            },
          ],
        },
        render: (_, record: { name: string }) => record.name || '-',
        renderFormItem: () => (
          <Input placeholder="Ingrese nombre" minLength={4} />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: 'event_picture_id',
        title: 'Imagen',
        type: ABM.TYPE_COLUMN.STRING,
        renderFormItem: () => {
          const uploadButton = (
            <Button>
              <UploadOutlined />
              Upload
            </Button>
          );
          return (
            <>
              {errorFile ? (
                <Alert
                  style={{ marginTop: '14px' }}
                  message={errorFile}
                  type="error"
                  showIcon
                />
              ) : null}
              <Form.Item>
                <Upload
                  fileList={
                    loadingEventPicture || fileMetaData
                      ? [
                          {
                            status: loadingEventPicture
                              ? 'uploading'
                              : undefined,
                            name:
                              fileMetaData?.filename ||
                              fileMetaData?.name ||
                              '-',
                            uid: '1',
                            url: urlFile,
                          },
                        ]
                      : undefined
                  }
                  listType="picture"
                  className="avatar-uploader"
                  showUploadList={true}
                  onRemove={() => {
                    setErrorFile(() => undefined);
                    setFileMetaData(() => undefined);
                    setLoadingEventPicture(() => false);
                    setUploadImage(() => false);
                    setUrlFile(() => undefined);
                    setDeleteCurrentImage(() => true);
                  }}
                  multiple={false}
                  maxCount={1}
                  customRequest={(uploadRequestOptions) => {
                    setLoadingEventPicture(() => true);
                    const { onSuccess, file } = uploadRequestOptions;
                    const fileRc = file as RcFile;
                    if (fileRc.size > maxSizeFile) {
                      setLoadingEventPicture(() => false);
                      setErrorFile(
                        'El tamaño de la imagen excede el límite permitido.',
                      );
                    } else {
                      setErrorFile(undefined);
                      Tools.getBase64WithCallback(file, (fileUrl: string) => {
                        setUrlFile(fileUrl);
                      });
                      setFileMetaData(fileRc);
                      if (onSuccess) {
                        // setToDeleteEventPicture(false);
                        setLoadingEventPicture(() => false);
                        setUploadImage(true);
                        onSuccess('');
                      }
                    }
                  }}
                >
                  {uploadButton}
                </Upload>
              </Form.Item>
            </>
          );
        },
        align: 'left',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'tenant_id',
        title: 'Tenant',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'El tenant es obligatorio',
            },
          ],
        },
        render: (_, record) => record.tenant.name,
        renderDataExport: (record) => record.tenant.name,
        renderFormItem: () => (
          <Select
            options={tenantsCombo.map((event) => ({
              label: event.name,
              value: event.id.toString(),
            }))}
            className={tenantsCombo.length === 1 ? 'select-is-disabled' : ''}
            placeholder="Seleccione tenant..."
            allowClear
            showSearch
            disabled={tenantsCombo.length === 1}
            optionFilterProp="label"
          />
        ),
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: 'card_description',
        title: 'Descripción corta',
        type: ABM.TYPE_COLUMN.STRING,
        render: (_, record: { card_description?: string }) =>
          record.card_description || '-',
        renderFormItem: () => {
          if (editMode || createMode) {
            return (
              <TextArea
                placeholder="Ingrese descripción"
                showCount
                maxLength={120}
                autoSize
              />
            );
          } else {
            return <Input placeholder="Ingrese descripción" />;
          }
        },
        align: 'left',
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'description',
        title: 'Descripción',
        type: ABM.TYPE_COLUMN.STRING,
        ellipsis: true,
        render: (_, record: { description?: string }) =>
          record.description || '-',
        renderFormItem: () => {
          if (editMode || createMode) {
            return <TextArea placeholder="Ingrese descripción" autoSize />;
          } else {
            return <Input placeholder="Ingrese descripción" />;
          }
        },
        align: 'left',
        hideInTable: true,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'ubication',
        title: 'Ubicación',
        type: ABM.TYPE_COLUMN.STRING,
        render: (_, record) => record.ubication || '-',
        renderFormItem: () => (
          <Input placeholder="Ingrese ubicación" minLength={4} />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'published',
        title: 'Publicado',
        type: ABM.TYPE_COLUMN.BOOLEAN,
        render: (_, record: { published: boolean }) =>
          record.published ? 'Si' : 'No' || '-',
        renderFormItem: () => {
          if (editMode || createMode) {
            return <Switch onChange={setPublished} checked={published} />;
          } else {
            return (
              <Select
                options={[
                  {
                    label: 'Si',
                    value: 'true',
                  },
                  {
                    label: 'No',
                    value: 'false',
                  },
                ]}
                placeholder="Seleccione si o no..."
                allowClear
                showSearch
              />
            );
          }
        },
        align: 'left',
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'visible_from',
        title: 'Visible desde',
        type: ABM.TYPE_COLUMN.DATE,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'La fecha es obligatoria',
            },
            {
              validator: (_rule, value) => {
                const minDate = moment(editMode ? editForm?.its : undefined);
                const visibleFrom = moment(value);
                if (visibleFrom.isBefore(minDate)) {
                  return Promise.reject(
                    `La fecha debe ser posterior a la hora ${
                      editMode ? 'de creación' : 'actual'
                    }`,
                  );
                }
                return Promise.resolve();
              },
            },
          ],
        },
        render: (_, record: { visible_from: Date }) =>
          record.visible_from
            ? getDateWithTime({ element: record.visible_from })
            : '-',
        renderFormItem: (_, __, formInstance) => (
          <DatePicker
            format={[FORMAT_DATE_TIME_1]}
            placeholder="Seleccione fecha"
            showTime={{
              showHour: true,
              showMinute: true,
            }}
            allowClear={false}
            onChange={(value: any) => {
              formInstance.setFieldsValue({ visible_from: value });
              formInstance.validateFields(['visible_from', 'visible_to']);
            }}
            inputReadOnly
          />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'visible_to',
        title: 'Visible hasta',
        type: ABM.TYPE_COLUMN.DATE,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'La fecha es obligatoria',
            },
            ({ getFieldValue }) => {
              return {
                validator: (_rule, value) => {
                  const minDate = moment(editMode ? editForm?.its : undefined);
                  const visibleFromValue = getFieldValue('visible_from');
                  const visibleFrom = moment(visibleFromValue);
                  const visibleTo = moment(value);
                  if (visibleFromValue && visibleTo.isBefore(visibleFrom)) {
                    return Promise.reject(
                      'La fecha de fin debe ser posterior a la fecha de inicio',
                    );
                  }
                  if (visibleTo.isBefore(minDate)) {
                    return Promise.reject(
                      `La fecha debe ser posterior a la hora ${
                        editMode ? 'de creación' : 'actual'
                      }`,
                    );
                  }
                  return Promise.resolve();
                },
              };
            },
          ],
        },
        render: (_, record: { visible_to: Date }) =>
          record.visible_to
            ? getDateWithTime({ element: record.visible_to })
            : '-',
        renderFormItem: () => (
          <DatePicker
            format={[FORMAT_DATE_TIME_1]}
            placeholder="Seleccione fecha"
            showTime={{
              showHour: true,
              showMinute: true,
            }}
            allowClear={false}
            inputReadOnly
          />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: 'visible_range',
        title: 'Visible',
        initialValue: [
          moment(new Date()),
          moment(new Date().setMonth(new Date().getMonth() + 1)),
        ],
        renderFormItem: () => (
          <DatePicker.RangePicker
            style={{ height: 32 }}
            name="visible_range"
            format={FORMAT_DATE_TIME_4}
            placeholder={['Fecha desde', 'Fecha hasta']}
            allowEmpty={[true, true]}
            className="renderFormItem"
            inputReadOnly
            allowClear
          />
        ),
        align: 'left',
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'its',
        title: 'Creado',
        type: ABM.TYPE_COLUMN.DATE,
        render: (_, record: { its: Date }) =>
          record.its ? getDateWithTime({ element: record.its }) : '-',
        renderFormItem: () => (
          <DatePicker
            format={[FORMAT_DATE_TIME_1]}
            placeholder="Seleccione fecha"
            showTime={{
              showHour: true,
              showMinute: true,
            }}
            allowClear={false}
            inputReadOnly
          />
        ),
        align: 'left',
        defaultSortOrder: 'descend',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'uts',
        title: 'Actualizado',
        type: ABM.TYPE_COLUMN.DATE,
        render: (_, record: { uts?: Date }) =>
          record.uts ? getDateWithTime({ element: record.uts }) : '-',
        renderFormItem: () => (
          <DatePicker
            format={[FORMAT_DATE_TIME_1]}
            placeholder='"Seleccione fecha"'
            showTime={{
              showHour: true,
              showMinute: true,
            }}
            allowClear={false}
            inputReadOnly
          />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        title: 'Op.',
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 100,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => {
          return (
            <>
              <Tooltip
                key="preview_event"
                trigger={tooltipTrigger}
                title="Previsualizar card de evento"
              >
                <EyeOutlined
                  className="pointer"
                  onClick={() => {
                    setPrevisualizedEventId(record.id);
                    getEventPicture(record.event_picture_id);
                  }}
                />
              </Tooltip>
              {Authorization.security(
                functions,
                EnumsValues.Functions.EventUpdate,
              ) && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="edit_event_tooltip"
                    trigger={tooltipTrigger}
                    title="Modificar evento"
                  >
                    <EditOutlined
                      className="pointer"
                      onClick={() => {
                        handleUpdateModalVisible(true);
                        setEditFormValues(record);
                        setPublished(record.published);
                        getEventPicture(record.event_picture_id);
                      }}
                    />
                  </Tooltip>
                </>
              )}
              {Authorization.security(
                functions,
                EnumsValues.Functions.EventDelete,
              ) && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="remove_event_tooltip"
                    trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                    title="Eliminar evento"
                  >
                    <DeleteOutlined
                      className="pointer"
                      onClick={() => {
                        removeEvent(record);
                      }}
                    />
                  </Tooltip>
                </>
              )}
            </>
          );
        },
      },
    ],
    [
      dataTable,
      published,
      errorFile,
      fileMetaData,
      urlFile,
      maxSizeFile,
      loadingEventPicture,
    ],
  );
  let LIST_FILTER_NAMES = columns(false)
    // eslint-disable-next-line array-callback-return
    .filter((value) => {
      if (
        LIST_FILTER.find(
          (element) =>
            element === value.dataIndex && value.hideInTable === false,
        )
      ) {
        return value.title;
      }
    })
    .map((element) => {
      return element.title;
    });

  const exportableTable: ExportableTable<IEvent> = {
    columns: columns(false),
    items: dataTable,
    filename: `event_${MomentJS.momentDefaultFormat()}`,
  };

  const previsualizedEventModal = useMemo(() => {
    const previsualizedEvent = dataTable.find(
      (event) => event.id === previsualizedEventId,
    );
    if (!previsualizedEvent) {
      return null;
    }
    const { card_description, name, ubication, id } = previsualizedEvent;
    return (
      <Modal
        visible
        footer={null}
        width="max-content"
        bodyStyle={{ paddingBottom: 48 }}
        onCancel={() => {
          setPrevisualizedEventId(undefined);
          resetImageStates();
        }}
        title="Previsualización"
        destroyOnClose
      >
        {!urlFile && loadedPictureId != null ? (
          <div
            className="tenant-image-preview"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <EventCard
              price={false}
              description={card_description ? card_description : ''}
              title={name}
              image={urlFile || ''}
              key={id}
              ubication={ubication}
              onButtonClick={() => {}}
            />
          </div>
        )}
      </Modal>
    );
  }, [previsualizedEventId, dataTable, urlFile]);

  return (
    <>
      <ProTable<IEvent>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        headerTitle={TITLE_PRO_TABLE}
        actionRef={actionRef}
        rowKey="id"
        search={{
          resetText: 'Limpiar',
          searchText: 'Buscar',
          collapseRender: showCollapseRender(columns(false)),
        }}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<IEvent>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <div className="content-search-table">
            <Tooltip
              key="searchtext"
              title={'Buscar por: ' + LIST_FILTER_NAMES.join(', ')}
            >
              <Input.Search
                size="middle"
                placeholder={INPUT_SEARCH_PLACEHOLDER}
                enterButton
                value={searchText}
                onSearch={handleSearch}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                allowClear={true}
              />
            </Tooltip>
          </div>,
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.EventCreate,
            ) && (
              <Button
                type="primary"
                onClick={() => {
                  setCreateModalVisible(true);
                }}
                icon={<PlusOutlined />}
              >
                Nuevo
              </Button>
            )}
          </>,
          <>
            <ExporterDropdown exportable={exportableTable} />
          </>,
          <Button onClick={changeView} className="buttonChangeProTableView">
            Cambiar Vista
          </Button>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        scroll={{
          x: 'max-content',
        }}
        columns={columns(false)}
      />
      <SaveForm
        loading={formLoading}
        title={TITLE_CREATE_FORM}
        onCancel={() => {
          setCreateModalVisible(false);
          resetImageStates();
        }}
        values={{
          tenant_id: tenantsCombo.length === 1 ? tenantsCombo[0].id : undefined,
        }}
        modalVisible={createModalVisible}
        onOk={createEvent}
        columns={columns(false, true)}
      />
      {editForm && (
        <SaveForm
          loading={formLoading}
          title={TITLE_UPDATE_FORM}
          modalVisible={updateModalVisible}
          values={{
            ...editForm,
            visible_from: moment(new Date(editForm.visible_from)),
            visible_to: moment(new Date(editForm.visible_to)),
            tenant_id: tenantsCombo.find(
              (tenant) => tenant.id === editForm.tenant_id,
            )?.name,
          }}
          columns={columns(true)}
          onOk={updateEvent}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues(undefined);
            resetImageStates();
          }}
        />
      )}
      {previsualizedEventModal}
    </>
  );
}
