import React, { useEffect, useLayoutEffect, useState } from 'react';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import {
  firebaseApp,
  initializeFirebaseApp,
} from '../../../configs/firebase/firebase-config';

export interface LoginFirebaseProps {
  onSignIn?: (user: any) => void;
  messageError?: (message: string) => void;
}
const LoginFirebase: React.FC<LoginFirebaseProps> = (props) => {
  const { onSignIn, messageError } = props;
  const [user, setUser] = useState<any>(undefined);

  const firebaseUi = () => {
    const uiConfig: firebaseui.auth.Config = {
      signInOptions: [
        {
          provider: firebaseApp.auth.PhoneAuthProvider.PROVIDER_ID,
          defaultCountry: 'AR',
        },
      ],
      callbacks: {
        signInSuccessWithAuthResult: (authResult: any) => {
          setUser(authResult.user);
          return false;
        },
        signInFailure: (error: firebaseui.auth.AuthUIError) => {
          if (messageError) {
            messageError(error.message);
          }
        },
      },
    };
    initializeFirebaseApp();
    if (firebaseui.auth.AuthUI.getInstance()) {
      const ui = firebaseui.auth.AuthUI.getInstance();
      if (ui) ui.start('#firebaseui-auth-container', uiConfig);
    } else {
      const ui = new firebaseui.auth.AuthUI(firebaseApp.auth());
      ui.start('#firebaseui-auth-container', uiConfig);
    }
  };

  useLayoutEffect(() => {
    firebaseUi();
  }, []);

  useEffect(() => {
    if (onSignIn) {
      if (user) {
        onSignIn(user);
      }
    }
  }, [user]);

  return <div id="firebaseui-auth-container"></div>;
};

export default LoginFirebase;
