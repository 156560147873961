import {
  HomeOutlined,
  SettingOutlined,
  ControlOutlined,
  LockOutlined,
  BulbOutlined,
  CheckCircleOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import DefaultRedirectPage from '../pages/DefaultRedirect/DefaultRedirectPage';
import HomePage from '../pages/Home/HomePage';
import LoginPage from '../pages/Login/LoginPage';
import PublicLayout from '../layouts/publicLayout';
import RegisteredLayout from '../layouts/registered';
import AppSettingPage from '../pages/AppSetting/AppSettingPage';
import EmptyLayout from '../layouts/emptyLayout';
import ResetPasswordContentPage from '../pages/ResetPassword/ResetPasswordContentPage';
import SetPasswordContentPage from '../pages/SetPassword/SetPasswordPage';
import ProfilePage from '../pages/Profile/ProfilePage';
import { EnumsValues } from '../enums/EnumsValues';
import RolePage from '../pages/Role/RolePage';
import PermissionPage from '../pages/Permission/PermissionPage';
import UserPage from '../pages/User/UserPage';
import { IRouteConfig } from '../interfaces/routeConfig';
import AboutPage from '../pages/About/AboutPage';
import Reminder2FA from '../pages/Totp2FA/Reminder2FA/Reminder2FA';
import Generate2FA from '../pages/Totp2FA/Generate2FA/Generate2FA';
import Validate2FA from '../pages/Totp2FA/Validate2FA/Validate2FA';
import Config2FA from '../pages/Totp2FA/Config2FA/Config2FA';
import TenantPage from '../pages/Tenant/TenantPage';
import ManagementUsersLayout from '../layouts/managementUsersLayout';
import { Redirect } from 'react-router';
import EventPage from '../pages/Event/EventPage';
import ByTenantPage from '../pages/Tenant/ByTenantPage';
import SubscriptionPage from '../pages/Subscription/SubscriptionPage';
import LandingPage from '../pages/LandingPage/LandingPage';
import EventPublicPage from '../pages/Event/EventPublicPage';
import SubscriptionPublicPage from '../pages/Subscription/SubscriptionPublicPage';
import CheckTicket from '../pages/CheckTicket/CheckTicket';
import PurchaseConfirmationPage from '../pages/PurchaseConfirmation/PurchaseConfirmationPage';
import CreatePersonPage from '../pages/User/CreatePersonPage';
import { PayApproved } from '../pages/PayApproved/PayApproved';
import { PayDisapproved } from '../pages/PayDisapproved/PayDisapproved';
import AdminPointOfSalePage from '../pages/PointOfSale/AdminPointOfSale';
import PointOfSaleProviderPage from '../pages/PointOfSale/PointOfSaleProviderPage';
import PointOfSaleSelectionPage from '../pages/PointOfSale/PointOfSaleSelectionPage';
import PointOfSaleEventPage from '../pages/PointOfSale/PointOfSaleEventPage';
import SelectEventInPointOfSale from '../pages/PointOfSale/SelectEventInPointOfSale';
import PointOfSaleTicketGeneration from '../pages/PointOfSale/PointOfSaleTicketGeneration';

const routes: IRouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: DefaultRedirectPage,
    key: EnumsValues.RoutesKeys.DefaultRedirect,
  },
  {
    path: '/app',
    component: RegisteredLayout,
    key: EnumsValues.RoutesKeys.App,
    routes: [
      {
        path: '/app/home',
        component: HomePage,
        name: 'Home',
        icon: <HomeOutlined />,
        key: EnumsValues.RoutesKeys.Home,
      },
      {
        path: '/app/config2fa',
        component: Config2FA,
        name: 'Autenticación 2FA',
        icon: <LockOutlined />,
        key: EnumsValues.RoutesKeys.Config2fa,
      },
      {
        path: '/app/administration',
        name: 'Administración',
        component: EmptyLayout,
        icon: <ControlOutlined />,
        authority: EnumsValues.Functions.AdministrationMenuRead,
        key: EnumsValues.RoutesKeys.Administration,
        routes: [
          {
            path: '/app/administration/event',
            component: EventPage,
            authority: EnumsValues.Functions.EventList,
            name: 'Eventos',
            key: EnumsValues.RoutesKeys.Event,
          },
          {
            path: '/app/administration/permission',
            component: PermissionPage,
            authority: EnumsValues.Functions.PermissionList,
            name: 'Permisos',
            key: EnumsValues.RoutesKeys.Permission,
          },
          {
            path: '/app/administration/role',
            component: RolePage,
            authority: EnumsValues.Functions.RoleList,
            name: 'Roles',
            key: EnumsValues.RoutesKeys.Role,
          },
          {
            path: '/app/administration/subscription',
            component: SubscriptionPage,
            authority: EnumsValues.Functions.SubscriptionList,
            name: 'Suscripciones',
            key: EnumsValues.RoutesKeys.Subscription,
          },
          {
            path: '/app/administration/tenant',
            component: TenantPage,
            authority: EnumsValues.Functions.TenantList,
            name: 'Tenants',
            key: EnumsValues.RoutesKeys.Tenant,
          },
          {
            path: '/app/administration/user',
            component: UserPage,
            authority: EnumsValues.Functions.UserList,
            name: 'Usuarios',
            key: EnumsValues.RoutesKeys.User,
          },
          {
            path: '/app/administration/pointofsale',
            component: AdminPointOfSalePage,
            authority: EnumsValues.Functions.ReadPointOfSale,
            name: 'Ventanillas',
            key: EnumsValues.RoutesKeys.AdminPointsOfSale,
          },
        ],
      },
      {
        path: '/app/setting',
        authority: EnumsValues.Functions.AppSettingRead,
        component: AppSettingPage,
        name: 'Configuración',
        icon: <SettingOutlined />,
        key: EnumsValues.RoutesKeys.Setting,
      },
      {
        path: '/app/about',
        component: AboutPage,
        name: 'About',
        icon: <BulbOutlined />,
        key: EnumsValues.RoutesKeys.About,
      },
      {
        path: '/app/profile',
        // authority: EnumsValues.Functions.AppSettingRead,
        component: ProfilePage,
        name: 'Mi perfil',
        hideInMenu: true,
        key: EnumsValues.RoutesKeys.Profile,
      },
      {
        path: '/app/pointofsale',
        authority: EnumsValues.Functions.CreateOrderInPointOfSale,
        component: PointOfSaleProviderPage,
        name: 'Ventanillas',
        icon: <ShopOutlined />,
        key: EnumsValues.RoutesKeys.PointsOfSale,
        hideSubRoutes: true,
        routes: [
          {
            path: '/app/pointofsale/list',
            authority: EnumsValues.Functions.CreateOrderInPointOfSale,
            component: PointOfSaleSelectionPage,
            name: 'Ventanillas',
            key: EnumsValues.RoutesKeys.PointsOfSale,
          },
          {
            path: '/app/pointofsale/eventSelection',
            authority: EnumsValues.Functions.CreateOrderInPointOfSale,
            component: SelectEventInPointOfSale,
            name: 'Ventanillas',
            key: EnumsValues.RoutesKeys.PointsOfSale,
          },
          {
            path: '/app/pointofsale/event',
            authority: EnumsValues.Functions.CreateOrderInPointOfSale,
            component: PointOfSaleEventPage,
            name: 'Ventanillas',
            key: EnumsValues.RoutesKeys.PointsOfSale,
          },
          {
            path: '/app/pointofsale/ticketGeneration',
            authority: EnumsValues.Functions.CreateOrderInPointOfSale,
            component: PointOfSaleTicketGeneration,
            name: 'Ventanillas',
            key: EnumsValues.RoutesKeys.PointsOfSale,
          },
        ],
      },
      {
        path: '/app/checkticket',
        authority: EnumsValues.Functions.CheckTicket,
        component: CheckTicket,
        name: 'Validar ticket',
        icon: <CheckCircleOutlined />,
        key: EnumsValues.RoutesKeys.CheckTicket,
      },
    ],
  },
  {
    path: '/signin',
    component: ManagementUsersLayout,
    key: EnumsValues.RoutesKeys.ManagementUsersLayout,
    routes: [
      {
        path: '/signin/resetPassword',
        exact: true,
        component: ResetPasswordContentPage,
        key: EnumsValues.RoutesKeys.ResetPassword,
      },
      {
        exact: true,
        path: '/signin/setPassword',
        component: SetPasswordContentPage,
        key: EnumsValues.RoutesKeys.SetPassword,
      },
      {
        path: '/signin/login',
        exact: true,
        component: LoginPage,
        key: EnumsValues.RoutesKeys.Login,
      },
      {
        path: '/signin/reminder',
        exact: true,
        component: Reminder2FA,
        key: EnumsValues.RoutesKeys.Reminder,
      },
      {
        path: '/signin/generate',
        exact: true,
        component: Generate2FA,
        key: EnumsValues.RoutesKeys.Generate,
      },
      {
        path: '/signin/validateTotp',
        exact: true,
        component: Validate2FA,
        key: EnumsValues.RoutesKeys.ValidateTotp,
      },
      {
        path: '/signin/createPerson',
        exact: true,
        component: CreatePersonPage,
        key: EnumsValues.RoutesKeys.CreatePersonPage,
      },
    ],
  },
  {
    path: '/',
    component: PublicLayout,
    key: EnumsValues.RoutesKeys.PublicLayout,
    routes: [
      {
        path: '/home',
        exact: true,
        component: () => (
          <>
            <LandingPage />
          </>
        ),
        key: EnumsValues.RoutesKeys.HomePublic,
        name: 'Home',
        hideInMenu: true,
      },
      {
        path: '/login',
        exact: true,
        component: () => (
          <>
            <Redirect to={'/signin/login'} />
          </>
        ),
        key: EnumsValues.RoutesKeys.LoginRedirect,
        name: 'Login',
        hideInMenu: false,
      },
      {
        path: '/event/:id',
        component: EventPublicPage,
        exact: true,
        key: EnumsValues.RoutesKeys.EventPublicPage,
        hideInMenu: true,
      },
      {
        path: '/subscription/:id',
        component: SubscriptionPublicPage,
        exact: true,
        key: EnumsValues.RoutesKeys.SubscriptionPublicPage,
        hideInMenu: true,
      },
      {
        path: '/purchaseConfirmation',
        component: PurchaseConfirmationPage,
        exact: true,
        key: EnumsValues.RoutesKeys.purchaseConfirmationPage,
        hideInMenu: true,
      },
      {
        path: '/successful-payment',
        component: PayApproved,
        exact: true,
        key: EnumsValues.RoutesKeys.Approved,
        hideInMenu: true,
      },
      {
        path: '/failed-payment',
        component: PayDisapproved,
        exact: true,
        key: EnumsValues.RoutesKeys.Disapproved,
        hideInMenu: true,
      },
      {
        path: '/:slug',
        component: ByTenantPage,
        exact: true,
        key: EnumsValues.RoutesKeys.ByTenantPage,
        hideInMenu: true,
      },
    ],
  },
];

export default routes;
