import GraphqlService from './graphql/GraphqlService';

const SubscriptionService = () => {
  const { Query, customRequest } = GraphqlService();

  const getEventSubscriptions = (eventId: number) => {
    return customRequest({
      query: Query.subscriptions,
      variables: {
        filter: {
          event_id: eventId,
        },
        orderBy: {
          field: 'start_date',
          direction: 'asc',
        },
      },
    });
  };

  return {
    getEventSubscriptions,
  };
};

export default SubscriptionService;
