import { useContext } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import { IonPage } from '@ionic/react';
import { version } from '../../../package.json';
import { useNetworkStatus } from '../../hooks';
import moment from 'moment';
import './style.less';

const AboutPage = () => {
  const { networkStatus, mapStatusName } = useNetworkStatus();
  const { user } = useContext(ContextApp);

  return (
    <IonPage className="AboutPage">
      <h3>{process.env.REACT_APP_NAME}</h3>

      <span>
        <p>Versión: {version}</p>
        <p>{user?.username && 'Usuario: ' + user?.username}</p>
        <p>Conexión: {mapStatusName(networkStatus?.connectionType)}</p>
      </span>
      <p>{moment().format('YYYY')} Bombieri &#9415;</p>
    </IonPage>
  );
};

export default AboutPage;
