import { EditOutlined } from '@ant-design/icons';
import ProTable, { ActionType } from '@ant-design/pro-table';
import { Button, Input, Select, Tooltip } from 'antd';
import { ExporterDropdown, SaveForm } from '../../components/common/ABM';
import { useCallback, useContext, useRef, useState, useEffect } from 'react';
import { ExportableColumn, ExportableTable } from '../../shared/Exporter';
import { SorterResult } from 'antd/lib/table/interface';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ABM, Authorization, MomentJS, Tools } from '../../shared';
import { IPermission } from '../../interfaces/permission';
import { isPlatform } from '@ionic/react';
import { showCollapseRender } from '../../shared/showCollapseRender';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = ['name', 'permission_id'];
const LIST_SORTER = ['name'];

/**
 * Se configura por cada ABM diferente
 */
const TITLE_PRO_TABLE = 'Formulario de consulta';
const TITLE_UPDATE_FORM = 'Editar permiso';
const INPUT_SEARCH_PLACEHOLDER = 'Buscar...';

export default function PermissionPage() {
  // props

  // states
  const [searchText, setSearchText] = useState('');
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] = useState<any>({});
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>('');
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [permissionsCombo, setPermissionsCombo] = useState<IPermission[]>([]);
  // services and hooks
  const { mobileOnSizeChangeProTable, changeView, showComponent } =
    useProTableForMobile({
      layout: 'horizontal',
    });
  const { Query, Mutation, customRequest } = GraphqlService();
  const {
    messageError,
    messageUpdating,
    messageUpdateSuccess,
    messageUpdateError,
  } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  // contexts
  const { functions } = useContext(ContextApp);

  // methods

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const getPermissions = async () => {
    try {
      const data: IPermission[] = await customRequest({
        query: Query.permissions,
      });
      setPermissionsCombo(() => data);
      return {
        current: 1,
        data: data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch {
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const request = async (params: any) => {
    delete variables.current.filter;
    delete variables.current.orderBy;
    variables.current = {};
    const search: any = ABM.valuesResult(params);

    if (searchText) {
      variables.current.searchText = searchText;
    } else {
      delete variables.current.searchText;
    }

    LIST_FILTER.forEach((element) => {
      try {
        if (search[element]) {
          if (!variables.current.filter) {
            variables.current.filter = {};
          }
          variables.current.filter[element] = search[element];
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    LIST_SORTER.forEach((element) => {
      try {
        if (search.sorter[element]) {
          if (!variables.current.orderBy) {
            variables.current.orderBy = {};
          }
          variables.current.orderBy.direction =
            Tools.getTypeOrderByTableSortParam(search.sorter[element]);
          variables.current.orderBy.field = element;
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    try {
      const data: IPermission[] = await customRequest({
        query: Query.permissions,
        variables: variables.current,
      });
      setDataTable(data);
      return {
        current: 1,
        data: data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const updatePermission = async (value: any) => {
    setFormLoading(() => true);
    messageUpdating({
      context: 'TablePermission.updatePermission.1',
      message: 'permiso',
    });
    try {
      await customRequest({
        mutation: Mutation.updatePermission,
        variables: { input: { ...value, id: parseInt(editForm.id) } },
      });
      messageUpdateSuccess({
        context: 'TablePermission.updatePermission.2',
      });
      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'TablePermission.updatePermission.3',
          message: error.message,
        });
      }
      messageUpdateError({ context: 'TablePermission.updatePermission.3' });
    }
    setFormLoading(() => false);
  };

  const columns = useCallback(
    (): ExportableColumn<IPermission>[] => [
      {
        export: false,
        dataIndex: 'id',
        title: 'id',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: 'name',
        title: 'Permiso',
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'El permiso es obligatorio',
            },
          ],
        },
        render: (_: any, record: { name: any }) => record.name || '-',
        renderFormItem: () => (
          <Input placeholder="Ingrese permiso" minLength={4} />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: 'permission_id',
        title: 'Permiso',
        valueType: 'select',
        renderFormItem: () => (
          <Select
            options={permissionsCombo.map((permiso) => ({
              label: permiso.name,
              value: permiso.id,
            }))}
            placeholder="Seleccione permiso..."
            mode="multiple"
            allowClear
            showSearch
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'description',
        title: 'Descripción',
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record: IPermission) => record.description || '-',
        renderFormItem: () => (
          <Input placeholder="Ingrese descripción" minLength={4} />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        title: 'Op.',
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 100,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.PermissionUpdate,
            ) && (
              <Tooltip
                key="edit_permiso_tooltip"
                trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                title="Modificar permiso"
              >
                <EditOutlined
                  className="pointer"
                  onClick={() => {
                    handleUpdateModalVisible(true);
                    setEditFormValues(record);
                  }}
                />
              </Tooltip>
            )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.PermissionUpdate,
            )}
          </>
        ),
      },
    ],
    [dataTable],
  );
  let LIST_FILTER_NAMES = columns()
    // eslint-disable-next-line array-callback-return
    .filter((value) => {
      if (
        LIST_FILTER.find(
          (element) =>
            element === value.dataIndex && value.hideInTable === false,
        )
      ) {
        return value.title;
      }
    })
    .map((element) => {
      return element.title;
    });

  const exportableTable: ExportableTable<IPermission> = {
    columns: columns(),
    items: dataTable,
    filename: `permiso_${MomentJS.momentDefaultFormat()}`,
  };

  return (
    <>
      <ProTable<IPermission>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        headerTitle={TITLE_PRO_TABLE}
        actionRef={actionRef}
        rowKey="id"
        search={{
          resetText: 'Limpiar',
          searchText: 'Buscar',
          collapseRender: showCollapseRender(columns()),
        }}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<IPermission>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <div className="content-search-table">
            <Tooltip
              key="searchtext"
              title={'Buscar por: ' + LIST_FILTER_NAMES.join(', ')}
            >
              <Input.Search
                size="middle"
                placeholder={INPUT_SEARCH_PLACEHOLDER}
                enterButton
                value={searchText}
                onSearch={handleSearch}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                allowClear={true}
              />
            </Tooltip>
          </div>,
          <>
            <ExporterDropdown exportable={exportableTable} />
          </>,
          <Button onClick={changeView} className="buttonChangeProTableView">
            Cambiar Vista
          </Button>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns()}
      />
      {editForm && (
        <SaveForm
          loading={formLoading}
          title={TITLE_UPDATE_FORM}
          modalVisible={updateModalVisible}
          values={editForm}
          columns={columns()}
          onOk={async (value) => updatePermission(value)}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues({});
          }}
        />
      )}
    </>
  );
}
