export const TENANTS = `
  query tenants(
    $filter: FilterTenantInput,
    $orderBy:  TenantOrderInput,
    $skip: Int,
    $take: Int,
    $searchText: String,
  ) {
    tenants (
      filter: $filter,
      orderBy: $orderBy
      skip: $skip
      take: $take
      searchText: $searchText
    ) {
      __typename
      ... on Tenant {
        id
        name
        slug
        active
        tenant_picture_id
        mercadopago_id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const USER_TENANT = `
  query findManyUserTenants(
      $filter: FilterUserTenantInput!,
      $orderBy:  UserTenantOrderInput,
      $skip: Int,
      $take: Int,
    ) {
    findManyUserTenants(
      filter: $filter,
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      __typename
      ...on UserTenant {
        id
        user_id
        tenant_id
        tenant {
          name
          id
          slug
          active
          tenant_picture_id
        }
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const TENANT = `
query tenant($input: GetTenantInput!) {
  tenant(input: $input) {
    __typename
    ... on Tenant {
      id
      name
      slug
      active
      tenant_picture_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const TENANT_COUNT = `
query tenantCount($filter: FilterTenantInput, $orderBy: TenantOrderInput) {
  tenantCount(filter: $filter, orderBy: $orderBy) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const GET_TENANT_PICTURE = `
query getTenantPicture($id: Int!){
  getTenantPicture(id:$id){
    __typename
    ... on StringResult{
      result
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;
