import { DensitySize } from '@ant-design/pro-table/lib/components/ToolBar/DensityIcon';
import { isPlatform } from '@ionic/react';
import { Collapse, Descriptions, Divider, Empty, Modal } from 'antd';
import { CustomizeComponent } from 'rc-table/lib/interface';
import { useLayoutEffect, useState } from 'react';

import './useProTableForMobile.less';

const { Panel } = Collapse;

interface Iprops {
  layout: 'vertical' | 'horizontal';
  expandable?: (record: any) => JSX.Element;
  expandableTitle?: string;
}

// Ejemplo de uso

// const { mobileOnSizeChangeProTable, changeView, showComponent } =
// useProTableForMobile({
//   layout: 'horizontal',
//   expandable: expandedRowRender,
//   expandableTitle: 'Expandible';
// });

// <ProTable
// onSizeChange={mobileOnSizeChangeProTable}
// components={{
//   table: showComponent(),
// }}
// />

// <Button onClick={changeView} className="buttonChangeProTableView">
//  Cambiar Vista
// </Button>,

//onSizeChange  ===> da funcionalidad de cambiar el tamaño de la tabla en mobile (opcional su uso)
//components ===> cambia el layout de la tabla en mobile para que se vea mejor

/**
 * useProTableForMobile
 *
 * @property layout : 'vertical' | 'horizontal' (por defecto 'horizontal') (opcional)
 * @property expandable : En caso de que se use expandible, enviar funcion que renderiza (opcional)
 * @property expandableTitle : titulo para expandible (opcional)
 *
 * @returns mobileOnSizeChangeProTable, changeView, showComponent
 */
const useProTableForMobile = (props: Iprops) => {
  const { layout = 'horizontal', expandable, expandableTitle } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSorter, setShowSorter] = useState(false);
  const [mobileTableSize, setMobileTableSize] =
    useState<'middle' | 'small' | 'default' | undefined>();
  const [selectedView, setSelectedView] =
    useState<'table' | 'card' | null>(null);

  const mobileOnSizeChangeProTable = (selectedSize: DensitySize) => {
    if (!isPlatform('desktop')) {
      switch (selectedSize) {
        case 'small':
          setMobileTableSize('small');
          break;
        case 'middle':
          setMobileTableSize('middle');
          break;
        case 'large':
          setMobileTableSize('default');
          break;
        default:
          setMobileTableSize('default');
      }
    }
  };

  const getTableView = () => {
    !isPlatform('desktop') ? setSelectedView('card') : setSelectedView('table');
  };

  useLayoutEffect(() => {
    getTableView();
  }, []);

  const changeView = () => {
    selectedView === 'table'
      ? setSelectedView('card')
      : setSelectedView('table');
  };

  const mobileComponentsProTable: CustomizeComponent = (protableProps: any) => {
    const tableValues = protableProps.children[2].props.data;
    const headers = protableProps.children[0].props.columns;
    const columnsForSorter = protableProps.children[1].props.columns;

    const headerLabel = (header: any) => {
      const labelForItemWithoutSorter = header?.title?.props?.label;

      if (header.RC_TABLE_INTERNAL_COL_DEFINE) {
        return expandableTitle;
      }
      if (header.dataIndex === 'option') {
        return 'Opciones';
      }
      return labelForItemWithoutSorter
        ? labelForItemWithoutSorter
        : header?.title?.props?.children?.props?.children[0]?.props?.children
            ?.props?.label;
    };

    const renderedValues = (header: any, value: any) => {
      const expandedKey = protableProps.children[2].props.expandedKeys;

      //return Modal para expandible
      if (header.RC_TABLE_INTERNAL_COL_DEFINE) {
        if (expandedKey.has(value.id)) {
          setIsModalOpen(true);
          return (
            <Modal
              visible={isModalOpen}
              maskClosable
              className="pro-table-for-mobile__expandable-modal"
              onCancel={() => {
                setIsModalOpen(false);
                expandedKey.delete(value.id);
              }}
              footer={null}
            >
              {expandable && expandable(value)}
            </Modal>
          );
        }
      }

      //return en caso de no tener render en columnas de la tabla
      if (header.render(undefined, value) === '-') {
        const key = header.dataIndex;
        return value[key] || '-';
      }

      //return en caso de tener render en columnas de la tabla
      return header.render(undefined, value);
    };

    const descriptionItems = (value: any) => {
      return headers.map((header: any, headerIndex: number) => (
        <Descriptions.Item
          label={headerLabel(header)}
          key={value?.id || headerIndex}
          className="pro-table-for-mobile__description-item"
        >
          {renderedValues(header, value)}
        </Descriptions.Item>
      ));
    };

    const sorter = () => {
      const removeHeaderIfNoSorter = (column: any) => {
        if (!column.sorter) {
          columnsForSorter[columnsForSorter.indexOf(column)] = {
            ...columnsForSorter[columnsForSorter.indexOf(column)],
            className: 'hide',
          };
        } else {
          setShowSorter(true);
        }
      };

      for (const column of columnsForSorter) {
        removeHeaderIfNoSorter(column);
      }

      return (
        showSorter && (
          <Collapse ghost className="pro-table-for-mobile__collapse">
            <Panel
              header="Ordenamiento"
              key="1"
              className="pro-table-for-mobile__sorter-panel"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {protableProps.children[1]}
            </Panel>
          </Collapse>
        )
      );
    };

    const thereIsNoColumnSelectedOnSettings =
      headers.length === 1 && !headers[0].index;

    if (thereIsNoColumnSelectedOnSettings || !tableValues.length) {
      let description =
        'Debe seleccionar al menos una columna para ver los datos';

      if (!tableValues.length) {
        description = 'No hay datos para mostrar';
      }

      return (
        <Empty
          className="pro-table-for-mobile__empty"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<span>{description}</span>}
        />
      );
    }

    return tableValues.map((value: any, valueIndex: number) => {
      return (
        <Descriptions
          title={valueIndex === 0 ? sorter() : <Divider />}
          bordered
          size={mobileTableSize}
          layout={layout}
          column={1}
          key={value?.id || valueIndex}
          className="pro-table-for-mobile"
        >
          {descriptionItems(value)}
        </Descriptions>
      );
    });
  };

  const showComponent = () => {
    return selectedView === 'card' ? mobileComponentsProTable : undefined;
  };

  return {
    mobileOnSizeChangeProTable,
    showComponent,
    changeView,
  };
};

export default useProTableForMobile;
