import { PageLoading } from '@ant-design/pro-layout';
import { Col, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { colSpans } from '../../constants/colSpans';
import { CustomMessage } from '../../hooks';
import { IEvent } from '../../interfaces/Event';
import { IPointOfSale } from '../../interfaces/PointOfSale';
import EventService from '../../services/Event.service';
import { PointOfSaleService } from '../../services/PointOfSale.service';
import { EventCard } from '../common/EventCard/EventCard';
import { NotFound404 } from '../NotFound404/NotFound404';

const PointOfSaleEventList = ({
  pointOfSale,
  onCardClick,
}: {
  pointOfSale: IPointOfSale;
  onCardClick: (event: IEvent) => void | Promise<void>;
}) => {
  const [loading, setLoading] = useState(true);
  const [eventList, setEventList] = useState<
    { event: IEvent; image: string | null }[]
  >([]);
  const { getPointOfSalePublishedEvents } = PointOfSaleService();
  const { getLinkEventPicture } = EventService();
  const { messageError, getErrorMessage } = CustomMessage();

  const getPointOfSaleEvents = async (_pointOfSale: IPointOfSale) => {
    setLoading(true);
    try {
      const result = await getPointOfSalePublishedEvents(_pointOfSale);
      setEventList(
        result.map((item) => ({
          event: item,
          image: null,
        })),
      );
      result.map(async (event) => {
        try {
          const currentPicture = await getLinkEventPicture(
            event.event_picture_id,
          );
          setEventList((currEvents) => {
            const currEvent = currEvents.find((ev) => ev.event.id === event.id);
            if (!currEvent) return currEvents;
            currEvent.image = currentPicture || null;
            return [...currEvents];
          });
        } catch (error: any) {
          console.error(error);
        }
      });
    } catch (error) {
      messageError({
        context: 'PointOfSaleEventList - getPointOfSaleEvents',
        message: getErrorMessage(error),
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getPointOfSaleEvents(pointOfSale);
  }, [pointOfSale]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className="events-cards">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {eventList.length ? (
          <>
            {eventList.map((item) => {
              let eventDate = undefined;
              if (item.event.subscription.length) {
                eventDate = item.event.subscription.reduce(
                  (prev, current) =>
                    moment(prev).isBefore(current.start_date)
                      ? prev
                      : current.start_date,
                  new Date(
                    new Date().getFullYear() + 1000,
                    0,
                    1,
                  ) /* Fecha imposible de pasar */,
                );
              }
              return (
                <Col
                  span={12}
                  className="gutter-row"
                  {...colSpans}
                  key={item.event.id}
                >
                  <div className="cards">
                    <EventCard
                      title={item.event.name}
                      description={
                        item.event.card_description
                          ? item.event.card_description
                          : ''
                      }
                      image={item.image}
                      date={eventDate}
                      ubication={item.event.ubication}
                      price={false}
                      onCardClick={() => {
                        onCardClick(item.event);
                      }}
                    />
                  </div>
                </Col>
              );
            })}
          </>
        ) : (
          <div style={{ width: '100%', margin: 'auto' }}>
            <NotFound404
              title="No se encontraron eventos"
              subtitle="Lo sentimos, no hay eventos disponibles actualmente."
              hideButton
            />
          </div>
        )}
      </Row>
    </div>
  );
};

export default PointOfSaleEventList;
