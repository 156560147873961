import { LeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

const BackButton = ({ onBack }: { onBack?: () => void }) => {
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Button
      style={{ marginBottom: '1rem' }}
      type="link"
      onClick={onBack || handleBack}
    >
      <LeftOutlined />
      Volver
    </Button>
  );
};

export default BackButton;
