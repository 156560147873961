export const CREATE_USER = `
  mutation createUser(
    $input:  CreateUserInput!
    $inputProfile: CreateProfileInput
    $inputPerson: CreatePersonInput
  ) {
    createUser(
      input: $input,
      inputProfile: $inputProfile,
      inputPerson: $inputPerson
    ) {
      __typename
      ... on User {
        id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const UPDATE_USER = `
mutation updateUser(
	$input: UpdateUserInput!
  $inputProfile: CreateProfileInput
  $inputPerson: CreatePersonInput
  ) {
    updateUser(
      input: $input,
      inputProfile: $inputProfile,
      inputPerson: $inputPerson
    ) {
      __typename
      ... on User {
        id
        username
        profile_id
        person{
          id
          phone
          email
          firstname
          lastname
        }
        profile{
          timezone
        }
        profile_picture_id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const DELETE_USER = `
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id) {
      __typename
      ... on User {
        id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const RESET_PASSWORD = `
  mutation resetPassword(
    $email: String,
  ){
    resetPassword(email:$email){
      __typename
      ...on RecoveryResult{
        result
      }
      ...on ResultError{
        status_code
        message
}
    }
  }
`;

export const SET_PASSWORD = `
  mutation setPassword(
    $token: String!,
    $password: String!,
  )
  {
    setPassword(
        token:$token, 
        password:$password
    ){
      __typename
      ...on RecoveryResult{
        result
      }
      ...on ResultError{
        status_code
        message
}
    }
  }
`;

export const UPSERT_USER_ROLE = `
  mutation upsertUserRole($input: UpsertUserRoleInput!){
  upsertUserRole(input:$input)
  {
    __typename
    ...on UserRole{
      id
      role {
        id
        name
      }
    }
    ...on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPSERT_USER_FIREBASE = `
mutation upsertUserFirebase(
  $input: UpsertUserFirebaseInput!
){
  upsertUserFirebase(
    input:$input    
  ){
    __typename
    ...on User{
            uid
    }
    ...on ResultError{
      status_code
      message
    }    
  }
}
`;

export const CREATE_PROFILE_PICTURE = `
mutation createProfilePicture(
  $file:  Upload!
){
  createProfilePicture(
    file: $file,
  ){
    __typename
    ...on ProfilePicture{
      id
      filename
      mimetype
      encoding
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const UPDATE_PROFILE_PICTURE = `
mutation updateProfilePicture($file: Upload!){
  updateProfilePicture(
    file: $file,
  ){
    __typename
    ...on ProfilePicture{
      id
      filename
      mimetype
      encoding
      file
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
export const DELETE_PROFILE_PICTURE = `
mutation deleteProfilePicture($id: Int!){
  deleteProfilePicture(
    id: $id,
  ){
    __typename
    ...on ProfilePicture{
      id
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const UPDATE_MY_PASSWORD = `
mutation updateMyPassword($input: UpdatePasswordInput!) {
  updateMyPassword(
    input: $input
  ) {
    __typename
    ... on User {
      id
      username
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
export const CREATE_ASOCIATED_PERSON = `
  mutation   createAsociatedPerson(
    $input:  CreatePersonInput!
  ) {
    createAsociatedPerson(
      input: $input,
    ) {
      __typename
      ... on Person {
        id
        firstname
        lastname
        
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;
