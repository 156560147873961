export const CREATE_POINT_OF_SALE = `
mutation createPointOfSale($input: CreatePointOfSaleInput!) {
  createPointOfSale(input: $input) {
    __typename
    ... on PointOfSale {
      id
      name
      tenant_id
      active
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const UPDATE_POINT_OF_SALE = `
mutation updatePointOfSale(
  $input: UpdatePointOfSaleInput!
  $id: Int!
) {
  updatePointOfSale(input: $input, id: $id) {
    __typename
    ... on PointOfSale {
      id
      name
      tenant_id
      active
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const DELETE_POINT_OF_SALE = `
mutation deletePointOfSale($id: Int!) {
  deletePointOfSale(id: $id) {
    __typename
    ... on PointOfSale {
      id
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const CREATE_ORDER_IN_POINT_OF_SALE = `
mutation createOrderInPointOfSale($input: CreateOrderInPointOfSaleInput!) {
  createOrderInPointOfSale(input: $input) {
    __typename
    ... on Order {
      id
      ticket {
        id
        firstname
        lastname
        email
        dni
      }
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
