import { useContext } from 'react';
import { Button, Card } from 'antd';
import { NavContext } from '@ionic/react';
import { ContextApp } from '../../../contexts/ContextApp';
import moment from 'moment';
import { EnumsValues } from '../../../enums/EnumsValues';
import './reminder2FA.less';

const Reminder2FA = () => {
  const { user } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);

  const onOmitClick = () => {
    const tomorrow = moment().add(1, 'days').toString();
    localStorage.setItem(EnumsValues.LocalStorageKeys.TwoFAOmitted, tomorrow);
    user ? navigate('/app/home') : navigate('/');
  };

  return (
    <>
      <Card
        title="Realice la autenticación en dos pasos"
        className="main-card-reminder2FA"
        type="inner"
      >
        <p>
          Genere desde aqui la autenticación en dos pasos (2FA). Puede omitir
          este paso y realizar la autenticación en otra ocasión.
        </p>
        <Button type="text" onClick={onOmitClick}>
          {user ? 'Omitir' : 'Volver'}
        </Button>
        <Button
          type="text"
          onClick={() => {
            navigate('/signin/generate');
          }}
        >
          Generar autenticación
        </Button>
      </Card>
    </>
  );
};

export default Reminder2FA;
