import { renderRoutes } from 'react-router-config';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Layout } from 'antd';
import './index.less';
import { MainMenu } from './MainMenu';
import { HeaderComponent } from './HeaderComponent';
import { Redirect } from 'react-router';

import { MenuTrigger } from './MenuTrigger';
import ExceptionManager from '../../components/common/ExceptionManager';
import ExpiredSession from '../../components/auth/ExpiredSession/ExpiredSession';
import { UserService } from '../../services/UserService';
import GraphqlService from '../../services/graphql/GraphqlService';
import { IAppSetting } from '../../interfaces/AppSetting';
import ProfilePage from '../../pages/Profile/ProfilePage';
import moment from 'moment-timezone';
import { NavContext } from '@ionic/react';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import { IRouteConfig } from '../../interfaces/routeConfig';

const RegisteredLayout: React.FC = ({ route }: any) => {
  const {
    user,
    mainMenuCollapsed,
    setMainMenuCollapsed,
    menuBreakpointCollapsed,
    setMenuBreakpointCollapsed,
    sessionExpired,
    show2FAReminder,
    setShow2FAReminder,
  } = useContext(ContextApp);

  const { navigate } = useContext(NavContext);
  const { expireSession } = UserService();
  const { Query, customRequest } = GraphqlService();
  const { messageError, getErrorMessage } = CustomMessage();
  const [profileRequired, setProfileRequired] = useState<boolean>(false);

  useEffect(() => {
    const accessToken = localStorage.getItem(
      EnumsValues.LocalStorageKeys.Token,
    );
    if (
      accessToken &&
      user &&
      !user?.two_factor_auth_active &&
      !show2FAReminder
    ) {
      const data = localStorage.getItem(
        EnumsValues.LocalStorageKeys.TwoFAOmitted,
      );
      const dateStored = data && moment(new Date(data));
      const now = moment();

      if (!data || (dateStored && dateStored.isBefore(now))) {
        setShow2FAReminder(true);
        navigate('/signin/reminder');
      }
    }
    if (user?.two_factor_auth_active) {
      let token2FA = localStorage.getItem(
        EnumsValues.LocalStorageKeys.TwoFactorValidated,
      );
      if (token2FA) {
        if (token2FA === 'true') {
          navigate('/app/home');
        } else {
          navigate('/signin/validateTotp');
        }
      } else {
        navigate('/signin/validateTotp');
      }
    }
  }, [user]);

  const getBreakpointExpandedStyles = (): React.CSSProperties | undefined => {
    if (menuBreakpointCollapsed) {
      return {
        marginLeft: 0,
      };
    }
    return {
      marginLeft: 200,
    };
  };

  const getProfileRequiredSetting = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.ProfileRequired },
        },
      });
      setProfileRequired(data.setting_value === 'true');
    } catch (error) {
      messageError({
        context: 'RegisteredLayout.getProfileRequiredSetting.1',
        message: getErrorMessage(error),
      });
    }
  };

  const { Sider, Content } = Layout;
  useLayoutEffect(() => {
    if (sessionExpired) {
      expireSession();
    }
  }, [sessionExpired]);

  useEffect(() => {
    getProfileRequiredSetting();
  }, []);

  useEffect(() => {
    if (!user?.profile_id && profileRequired) {
      setMainMenuCollapsed(true);
    }
  }, [user, profileRequired]);

  useEffect(() => {
    moment.tz.setDefault(
      user?.profile?.timezone
        ? user?.profile?.timezone
        : process.env.REACT_APP_TIMEZONE
        ? process.env.REACT_APP_TIMEZONE
        : '',
    );
  }, [user]);

  if (!user) {
    return <Redirect to="/home" />;
  }

  const getBackdropExpandedStyles = (): React.CSSProperties | undefined => {
    if (menuBreakpointCollapsed && !mainMenuCollapsed) {
      return {
        display: 'block',
      };
    }
    return {
      display: 'none',
    };
  };

  return (
    <>
      {user?.profile_id || !profileRequired ? <MenuTrigger /> : null}
      {!sessionExpired ? (
        <Layout className="RegisteredLayout">
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            collapsible
            collapsed={mainMenuCollapsed}
            trigger={null}
            onBreakpoint={(broken) => {
              setMainMenuCollapsed(() => broken);
              setMenuBreakpointCollapsed(() => broken);
            }}
          >
            <MainMenu routes={route.routes as IRouteConfig[]} />
          </Sider>
          <Layout
            className="RegisteredLayout__inner-layout"
            style={
              mainMenuCollapsed
                ? {
                    marginLeft: 0,
                  }
                : getBreakpointExpandedStyles()
            }
          >
            <div
              className="backdrop-content"
              onClick={() => setMainMenuCollapsed(!mainMenuCollapsed)}
              style={getBackdropExpandedStyles()}
            ></div>
            <HeaderComponent />
            <Content className="site-layout">
              {user?.profile_id || !profileRequired ? (
                <ExceptionManager>
                  {renderRoutes(route.routes)}
                </ExceptionManager>
              ) : (
                <ProfilePage />
              )}
            </Content>
          </Layout>
        </Layout>
      ) : (
        <>
          <MenuTrigger />
          <Layout className="RegisteredLayout">
            <Sider
              breakpoint="lg"
              collapsedWidth="0"
              collapsible
              collapsed={mainMenuCollapsed}
              trigger={null}
              onBreakpoint={(broken) => {
                setMainMenuCollapsed(() => broken);
                setMenuBreakpointCollapsed(() => broken);
              }}
            >
              <MainMenu routes={[]} />
            </Sider>
            <Layout
              style={
                mainMenuCollapsed
                  ? {
                      marginLeft: 0,
                    }
                  : getBreakpointExpandedStyles()
              }
            >
              <div
                className="backdrop-content"
                onClick={() => setMainMenuCollapsed(!mainMenuCollapsed)}
              ></div>
              <HeaderComponent />
              <Content className="site-layout">
                <ExpiredSession />
              </Content>
            </Layout>
          </Layout>
        </>
      )}
    </>
  );
};

export default RegisteredLayout;
