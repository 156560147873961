import { useEffect, useState } from 'react';
import './CheckTicket.less';
import { Html5Qrcode } from 'html5-qrcode';
import { CustomMessage } from '../../hooks';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ITicket } from '../../interfaces/Ticket';
import imageCameraFocus from '../../assets/camerafocus.png';
import { Button, Result } from 'antd';
import { Mutation } from '../../services/graphql/mutation';
import MomentTimezoneService from '../../services/moment-timezone/MomentTimezoneService';
import { CustomErrorStatusCode, ScanLogAction } from '../../enums/EnumsValues';

const CheckTicket = () => {
  const [ticketData, setTicketData] = useState<ITicket>();
  const [errorMessage, setErrorMessage] =
    useState<{
      message: string;
      level: 'error' | 'warning';
    }>();
  const [scanning, setScanning] = useState<boolean>(true);
  const { getDateWithTime } = MomentTimezoneService();

  const { messageError, messageUpdating, messageSuccess } = CustomMessage();

  const { Query, customRequest } = GraphqlService();

  const createScanLog = (input: {
    action: ScanLogAction;
    ticket_id?: number;
  }) => {
    const { action, ticket_id } = input;
    return customRequest({
      mutation: Mutation.createScanLog,
      variables: {
        input: {
          scan_log_action_id: action,
          ticket_id,
        },
      },
    }).catch(console.error); // intentional
  };

  const requestTicketData = async (ticketId: number, callback: () => void) => {
    try {
      const ticketRecord = await customRequest({
        query: Query.ticket,
        variables: { id: ticketId },
      });
      if (ticketRecord) {
        createScanLog({
          action: ScanLogAction.ValidTicket,
          ticket_id: ticketId,
        });
        setTicketData(ticketRecord);
        setScanning(false);
        callback();
      }
    } catch (error: any) {
      let level: 'error' | 'warning' = 'error'; //intentional
      switch (error.status_code) {
        case CustomErrorStatusCode.invalidTicket:
          createScanLog({
            action: ScanLogAction.InvalidTicket,
            ticket_id: ticketId,
          });
          break;
        case CustomErrorStatusCode.quantityTicketExhaustedCode:
          createScanLog({
            action: ScanLogAction.TicketExhausted,
            ticket_id: ticketId,
          });
          break;
        case CustomErrorStatusCode.ticketExpiredCode:
          createScanLog({
            action: ScanLogAction.TicketExpired,
            ticket_id: ticketId,
          });
          break;
        default:
          break;
      }
      errorMessageStop(
        {
          context: 'CheckTicket.requestTicketData.1',
          message: error?.message as string,
        },
        level,
      );
    }
  };

  const errorMessageStop = (
    op: { context: string; message: string },
    level: 'error' | 'warning' = 'error',
  ) => {
    setErrorMessage({
      message: op.message,
      level,
    });
    setScanning(false);
  };

  useEffect(() => {
    if (!scanning) return;
    const myNavigator = navigator as any;

    let theStream: any;
    let stop: boolean;

    const cleanStream = () => {
      theStream?.getTracks().forEach((track: any) => {
        track.stop();
      });
    };

    function getStream() {
      if (!myNavigator.mediaDevices.getUserMedia) {
        messageError({
          context: 'CheckTicket.getStream.1',
          message: 'User Media API no soportado.',
        });
        return;
      }

      const constraints = {
        video: { facingMode: 'environment' },
      };
      myNavigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream: any) => {
          const mediaControl = document.querySelector('#video') as any;
          if ('srcObject' in mediaControl) {
            mediaControl.srcObject = stream;
          } else if (myNavigator.mozGetUserMedia) {
            mediaControl.mozSrcObject = stream;
          } else {
            mediaControl.src = (window.URL || window.webkitURL).createObjectURL(
              stream,
            );
          }
          if (theStream) cleanStream();
          theStream = stream;
          takePhoto();
        })
        .catch((err: string) => {
          messageError({ context: 'CheckTicket.getStream.2', message: err });
        });
    }

    function getBlobFromMediaStream() {
      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d')!;

      video.srcObject = theStream;

      return new Promise((resolve, reject) => {
        video.addEventListener('loadeddata', async () => {
          const { videoWidth, videoHeight } = video;
          canvas.width = videoWidth;
          canvas.height = videoHeight;

          try {
            await video.play();
            context.drawImage(video, 0, 0, videoWidth, videoHeight);
            canvas.toBlob(resolve, 'image/png');
          } catch (error) {
            reject(error);
          }
        });
      });
    }

    function takePhoto() {
      if (stop) return;

      if (!theStream) {
        messageError({
          context: 'CheckTicket.takePhoto.1',
          message: 'Video stream Error',
        });
        return;
      }

      getBlobFromMediaStream()
        .catch((_err: any) => {
          setTimeout(getStream, 1000);
        })
        .then((blob: any) => {
          if (!blob) return;
          const file = new File([blob], 'name', { type: 'image/png' });
          const html5QrCode = new Html5Qrcode('_imageTag');
          return html5QrCode!.scanFile(file).catch((_err) => {
            setTimeout(takePhoto, 1000);
          });
        })
        .then((decoded?: string | void) => {
          if (!decoded) return;
          const ticketId = decoded && JSON.parse(decoded).ticketId;
          if (ticketId) {
            requestTicketData(ticketId, cleanStream);
          }
        })
        .catch((_err: string) => {
          createScanLog({
            action: ScanLogAction.UnknownQR,
          });
          errorMessageStop({
            context: 'CheckTicket.takePhoto.1',
            message: 'El código qr es inválido',
          });
        });
    }
    getStream();

    // unmount callback
    return () => {
      stop = true;
      cleanStream();
    };
  }, [scanning]);

  const checkThisTicket = async (id: number) => {
    messageUpdating({
      context: 'CheckTicket.checkThisTicket.1',
      message: 'ticket',
    });
    try {
      await customRequest({
        mutation: Mutation.checkTicket,
        variables: {
          id,
        },
      });
      setTicketData(undefined);
      setScanning(true);
      messageSuccess({
        context: 'CheckTicket.checkThisTicket.2',
        message: 'El ticket ha sido utilizado correctamente',
      });
    } catch (error: any) {
      messageError({
        context: 'CheckTicket.checkThisTicket.3',
        message: error.message,
      });
    }
  };

  useEffect(() => {
    const elements = document.getElementsByClassName(
      'RegisteredLayout__inner-layout',
    );
    const layoutElement = elements[0];
    const classnamesAdded: string[] = [];
    if (ticketData) {
      classnamesAdded.push('background-success');
      if (layoutElement) {
        layoutElement.classList.add('background-success');
      }
    }
    if (errorMessage) {
      const classnameToAdd =
        errorMessage.level === 'error'
          ? 'background-error'
          : 'background-warning';
      classnamesAdded.push(classnameToAdd);
      if (layoutElement) {
        layoutElement.classList.add(classnameToAdd);
      }
    }

    return () => {
      if (layoutElement) {
        for (const classname of classnamesAdded) {
          layoutElement.classList.remove(classname);
        }
      }
    };
  }, [ticketData, errorMessage]);

  let mainContent = (
    <div className="checkticket">
      <video id="video" autoPlay></video>
      <div className="qr-guide-container">
        <img src={imageCameraFocus} alt="camera focus" className="qr-guide" />
      </div>
    </div>
  );

  if (errorMessage) {
    mainContent = (
      <div className="ckeck-ticket-error-content">
        <div className="background-layout-body check-ticket-error-inner-content">
          <Result
            status={errorMessage.level}
            title={errorMessage.message}
            extra={[
              <Button
                onClick={() => {
                  setErrorMessage(undefined);
                  setScanning(true);
                }}
                type="primary"
                key="back"
              >
                Volver
              </Button>,
            ]}
          ></Result>
        </div>
      </div>
    );
  }

  if (ticketData) {
    mainContent = (
      <div className="cut-ticket">
        <div className="ticket-container">
          <p className="section" style={{ fontSize: 20 }}>
            <b>Ticket Válido</b>
          </p>
          <>
            <hr style={{ borderTop: 'dashed 2px' }} />
            {ticketData.subscription?.event?.name && (
              <div className="section">
                <p>Evento:</p>
                <p>{ticketData.subscription.event.name}</p>
              </div>
            )}
            {ticketData.subscription?.name && (
              <div className="section">
                <p>Suscripción:</p>
                <p>{ticketData.subscription.name}</p>
                <p>Fecha de inicio:</p>
                <p>
                  {getDateWithTime({
                    element: ticketData.subscription.start_date,
                  })}
                </p>
                <p>Fecha de fin:</p>
                {getDateWithTime({
                  element: ticketData.subscription.start_date,
                })}
              </div>
            )}
            {ticketData.use_quantity && ticketData.use_quantity > 1 && (
              <p className="section">
                Usos disponibles: <b>{ticketData.use_quantity}</b>
              </p>
            )}
          </>

          <hr style={{ borderTop: 'solid 1px' }} />
          {ticketData.firstname && ticketData.lastname && (
            <div className="section">
              <p>Nombre y apellido:</p>
              <p className="personal-info-section">
                {ticketData.firstname} {ticketData.lastname}
              </p>
            </div>
          )}
          {ticketData.firstname && !ticketData.lastname && (
            <div className="section">
              <p>Nombre:</p>
              <p className="personal-info-section">{ticketData.firstname}</p>
            </div>
          )}
          {ticketData.lastname && !ticketData.firstname && (
            <div className="section">
              <p>Apellido:</p>
              <p className="personal-info-section">{ticketData.lastname}</p>
            </div>
          )}
          {ticketData.dni && (
            <div className="section">
              <p>DNI:</p>
              <p className="personal-info-section">{ticketData.dni}</p>
            </div>
          )}
        </div>
        <Button
          onClick={() => checkThisTicket(ticketData.id)}
          className="cut-ticket-button"
        >
          Cortar ticket
        </Button>
      </div>
    );
  }

  return (
    <>
      {mainContent}

      {/* Not visible, needed for QR reader */}
      <div
        style={{
          opacity: 0,
          height: 0,
          overflow: 'hidden',
          position: 'absolute',
        }}
        id="_imageTag"
      ></div>
    </>
  );
};

export default CheckTicket;
