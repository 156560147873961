import { Button, Result } from 'antd';
import './ExpiredSession.less';
import { UserService } from '../../../services/UserService';

const ExpiredSession = () => {
  const { logout } = UserService();
  return (
    <Result
      status="403"
      title="401"
      subTitle="Se te ha vencido la sesión. Debes volver a loguearte."
      extra={
        <Button
          type="primary"
          onClick={() => {
            logout();
          }}
          key="console"
        >
          Volver al login
        </Button>
      }
    />
  );
};

export default ExpiredSession;
