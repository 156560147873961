import {
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Modal, Form, Row, Col, Select, List, Button, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { CustomMessage } from '../../hooks';
import GraphqlService from '../../services/graphql/GraphqlService';
import { IUser } from '../../interfaces';
import { IUserPointOfSale } from '../../interfaces/UserPointOfSale';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface IProps {
  point_of_sale_id: number;
  onClose?: () => void;
}

export interface IAssignUsersToPointOfSaleModalRef {
  toggleVisibility: () => void;
}

const AssignUsersToPointOfSaleModal = forwardRef(
  ({ point_of_sale_id, onClose }: IProps, ref) => {
    const [form] = Form.useForm();

    const { messageError, messageUpdateSuccess, getErrorMessage } =
      CustomMessage();
    const { Query, Mutation, customRequest } = GraphqlService();

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [candidateUsers, setCandidateUsers] = useState<IUser[]>([]);
    const [userIdsAssigned, setUserIdsAssigned] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [, i18n] = useTranslation();

    const usersAssigned = candidateUsers.filter((item) =>
      userIdsAssigned.includes(item.id),
    );

    const toggleVisibility = (): void => setIsVisible(!isVisible);

    useImperativeHandle(ref, () => {
      return {
        toggleVisibility,
      };
    });

    const upsertUsersInPointOfSale = async (
      addUsers: number[],
      removeUsers: number[],
    ) => {
      if ((!addUsers.length && !removeUsers.length) || !point_of_sale_id)
        return;
      setIsLoading(true);

      try {
        await customRequest({
          mutation: Mutation.upsertUserPointOfSale,
          variables: {
            input: {
              point_of_sale_id: point_of_sale_id,
              deleteUserIds: removeUsers,
              addUserIds: addUsers,
            },
          },
        });
        messageUpdateSuccess({
          context: 'upsertTenant',
        });
        await getUsersFromPointOfSale();
      } catch (error: any) {
        messageError({
          context: 'upsertTenant',
          message: getErrorMessage(error),
        });
      } finally {
        setIsLoading(false);
      }
    };

    const getUsersFromPointOfSale = async () => {
      if (!point_of_sale_id) return;
      try {
        const data = await customRequest({
          query: Query.userPointsOfSale,
          variables: {
            filter: {
              point_of_sale_id,
            },
          },
        });

        setUserIdsAssigned(data.map((item: IUserPointOfSale) => item.user_id));
      } catch (error) {
        messageError({
          context: 'getUserTenants',
          message: 'Error al recuperar los usuarios de la ventanilla',
        });
      }
    };

    const getUserCandidates = async () => {
      if (!point_of_sale_id) return;
      try {
        const response: IUser[] = await customRequest({
          query: Query.getUsersCandidatesForPointOfSale,
          variables: {
            point_of_sale_id,
          },
        });
        setCandidateUsers(() => response);
      } catch (error) {
        messageError({
          context: 'upsertTenant',
          message: 'Error al recuperar usuarios',
        });
      }
    };

    const getUserLabel = (user: IUser) => {
      if (!user.person) {
        return user.username;
      }
      return `${user.person.lastname.toUpperCase()}, ${user.person.firstname}`;
    };

    const usersToShow = useMemo(() => {
      return candidateUsers
        .filter((item) => {
          return !userIdsAssigned.find((item2) => item2 === item.id);
        })
        .sort((a, b) => {
          const firstUserLabel = getUserLabel(a);
          const secondUserLabel = getUserLabel(b);

          return firstUserLabel.localeCompare(secondUserLabel, i18n.language);
        });
    }, [candidateUsers, userIdsAssigned]);

    useEffect(() => {
      if (point_of_sale_id) {
        getUsersFromPointOfSale();
        getUserCandidates();
      }
    }, [point_of_sale_id]);

    const onModalClose = () => {
      toggleVisibility();
      if (onClose) onClose();
    };

    return (
      <>
        <Modal
          title="Asignación de usuarios"
          visible={isVisible}
          style={{ minWidth: '60%' }}
          footer={null}
          onCancel={onModalClose}
        >
          <Row gutter={40}>
            <Col lg={10} xs={24}>
              <Form
                layout="vertical"
                form={form}
                onFinish={(value) => {
                  upsertUsersInPointOfSale([value.tenant], []);
                  form.resetFields();
                }}
              >
                <Form.Item
                  label={'Buscar tenant'}
                  name="tenant"
                  rules={[
                    { required: true, message: `Por favor, ingrese valor` },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    dropdownMatchSelectWidth={false}
                    placeholder="Seleccione"
                    filterOption={(inputValue: string, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    }
                  >
                    {usersToShow &&
                      usersToShow.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {getUserLabel(item)}
                          </Option>
                        );
                      })}{' '}
                  </Select>
                </Form.Item>
                <div className="buttons">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={isLoading}
                    disabled={isLoading}
                    style={{ marginBottom: '15px' }}
                  >
                    Asignar
                  </Button>
                </div>
              </Form>
            </Col>
            <Col lg={14} xs={24}>
              <List
                size="small"
                header={<b>Tenants asignados</b>}
                bordered
                style={{ marginBottom: '10px', height: '300px' }}
                dataSource={usersAssigned}
                renderItem={(item, index) => (
                  <List.Item
                    key={index}
                    actions={[
                      <Button
                        key={index}
                        onClick={() => upsertUsersInPointOfSale([], [item.id])}
                        loading={isLoading}
                      >
                        <Space>
                          <DeleteOutlined />
                          Quitar
                        </Space>
                      </Button>,
                    ]}
                  >
                    {getUserLabel(item)}
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  },
);

export default AssignUsersToPointOfSaleModal;
