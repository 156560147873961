import { PageLoading } from '@ant-design/pro-layout';
import { useEffect, useState } from 'react';
import { generateQrCode } from '../../../shared/QrGenerator';
import { Modal, Tooltip } from 'antd';
import './style.less';

interface IProps {
  value: string;
  width?: number | undefined;
  margin?: number | undefined;
  className?: string | undefined;
  tooltip?: string | undefined;
}
const QrCode = ({ value, width, margin, className, tooltip }: IProps) => {
  const [img, setImg] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const imgString = async () => {
    let string = '';

    setIsLoading(true);
    try {
      string = await generateQrCode({ value, margin });
      setImg(string);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    imgString();
  }, [img]);

  return (
    <>
      {isLoading ? (
        <PageLoading />
      ) : (
        <div className={className}>
          <div style={{ width }}>
            <Tooltip title={tooltip} placement="right">
              <img
                onClick={() => setIsOpen(true)}
                src={img}
                alt="qr-code"
                className="qr-img"
              />
            </Tooltip>
          </div>
          {!!tooltip && (
            <Modal
              centered={true}
              visible={isOpen}
              onCancel={() => setIsOpen(false)}
              footer={null}
              style={{ textAlign: 'center' }}
            >
              <img src={img} alt="qr-code" className="qr-img" />
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

export default QrCode;
