import { useContext, useEffect, useMemo, useState } from 'react';
import { CustomMessage } from '../../hooks';
import GraphqlService from '../../services/graphql/GraphqlService';
import './PurchaseConfirmationPage.less';
import { NotFound404 } from '../../components/NotFound404/NotFound404';
import { PageLoading } from '@ant-design/pro-layout';
import { ISubscription } from '../../interfaces/Subscription';
import { Typography, Input, Form, Button, InputNumber } from 'antd';
import { EventCardSummary } from '../../components/common/EventCardSummary/EventCardSummary';
import { PublicContext } from '../../contexts/PublicContext';
import { ICreateTicketsInOrder } from '../../interfaces/Ticket';
import { Mercadopago } from '../../components/Mercadopago/Mercadopago';
import { NavContext } from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import { OrderStatus } from '../../enums/EnumsValues';

// TODO: refactorizar para usar componente PurchaseConfirmationForm

const PurchaseConfirmationPage = () => {
  const [subscription, setSubscription] = useState<ISubscription>();
  const [loadingSubscription, setLoadingSubscription] = useState(true);
  const { showMessageError, messageModalError, getErrorMessage } =
    CustomMessage();
  const [loadingEventPicture, setLoadingEventPicture] =
    useState<boolean>(false);
  const [creatingOrder, setCreatingOrder] = useState<boolean>(false);
  const [urlFile, setUrlFile] = useState<string>();
  const [preferenceId, setPreferenceId] = useState<string>();
  const [form] = Form.useForm();
  // services and hooks
  const { Query, customRequest, Mutation } = GraphqlService();
  const { subscriptionId, quantity, setSubscriptionId, setQuantity } =
    useContext(PublicContext);
  const [amountToPay, setAmountToPay] = useState<number>(0);
  const [instanceMercadoPago, setInstanceMercadoPago] = useState<any>();
  const { navigate } = useContext(NavContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.state) {
      setSubscriptionId(location.state.subscriptionId);
      setQuantity(location.state.quantity);
    } else {
      navigate('/home');
    }
  }, []);

  const getSubscription = async (subscription_id: number) => {
    setLoadingSubscription(true);
    let currentSubscription;
    try {
      currentSubscription = await customRequest({
        query: Query.publicSubscription,
        variables: {
          id: subscription_id,
        },
      });
      setAmountToPay(quantity * currentSubscription?.price);
      setSubscription(currentSubscription);
    } catch (error: any) {
      showMessageError({
        context: 'getPublicSubscription.1',
        error: error,
      });
    }
    setLoadingSubscription(false);
  };

  const getEventPicture = async (event_picture_id?: number) => {
    if (!event_picture_id) return;
    setLoadingEventPicture(true);
    try {
      const data = await customRequest({
        query: Query.eventPictureLink,
        variables: { id: event_picture_id },
      });
      setUrlFile(data.result);
    } catch (error: any) {
      showMessageError({
        context: 'PurchaseConfirmation.getEventPicture.1',
        error: error,
      });
    } finally {
      setLoadingEventPicture(false);
    }
  };

  const createOrder = async (input: any) => {
    if (!input) return;
    setCreatingOrder(true);
    let tickets: ICreateTicketsInOrder[] = [];
    persons.forEach((_element, index) => {
      if (subscription) {
        tickets.push({
          paid_amount: subscription.price,
          subscription_id: subscription.id,
          use_quantity: 1,
          due_date: subscription.end_date,
          firstname: input['firstname' + (index + 1)],
          lastname: input['lastname' + (index + 1)],
        });
      }
    });
    try {
      const response = await customRequest({
        query: Mutation.createOrder,
        variables: {
          input: {
            person: {
              firstname: input.firstname,
              lastname: input.lastname,
              email: input.email,
            },
            ticket: tickets,
          },
        },
      });
      if (response.order_status_id === OrderStatus.approved) {
        history.push('/successful-payment');
      }
      setPreferenceId(response.preference_id);
    } catch (error: any) {
      messageModalError({
        context: 'PurchaseConfirmation.createOrder.1',
        message: getErrorMessage(error),
      });
    } finally {
      setCreatingOrder(false);
    }
  };

  useEffect(() => {
    if (subscriptionId) {
      getSubscription(subscriptionId);
    }
  }, [subscriptionId]);

  useEffect(() => {
    getEventPicture(subscription?.event?.event_picture_id);
  }, [subscription]);

  useEffect(() => {
    if (preferenceId && instanceMercadoPago) {
      instanceMercadoPago.open();
    }
  }, [preferenceId, instanceMercadoPago]);

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
  };
  const persons = useMemo(() => {
    const result: JSX.Element[] = [];
    for (let index = 0; index < quantity; index++) {
      result.push(
        <div key={index}>
          <Typography.Title level={5}>
            Datos de Persona {index + 1}
          </Typography.Title>
          <Form.Item
            name={'firstname' + (index + 1)}
            label="Nombre"
            rules={[
              {
                required: true,
                message: 'El nombre es obligatorio',
              },
            ]}
          >
            <Input className="input-data" placeholder="Nombre" />
          </Form.Item>
          <Form.Item
            name={'lastname' + (index + 1)}
            label="Apellido"
            rules={[
              {
                required: true,
                message: 'El apellido es obligatorio',
              },
            ]}
          >
            <Input className="input-data" placeholder="Apellido" />
          </Form.Item>
        </div>,
      );
    }
    return result;
  }, [quantity]);

  return (
    <>
      {loadingSubscription || loadingEventPicture ? (
        <PageLoading />
      ) : (
        <>
          {subscription ? (
            <>
              <div className="container-purchase">
                <div className="purchase-section">
                  <div className="input-data-section">
                    <Form
                      {...formItemLayout}
                      form={form}
                      onFinish={(values) => createOrder(values)}
                    >
                      <div className="purchase-title">
                        <Typography.Title level={3}>
                          Confirmación de compra
                        </Typography.Title>
                      </div>
                      <div>
                        <Typography.Title level={5}>
                          Datos de facturación
                        </Typography.Title>
                        <Form.Item
                          name="firstname"
                          label="Nombre"
                          rules={[
                            {
                              required: true,
                              message: 'El nombre es obligatorio',
                            },
                          ]}
                        >
                          <Input className="input-data" placeholder="Nombre" />
                        </Form.Item>
                        <Form.Item
                          name="lastname"
                          label="Apellido"
                          rules={[
                            {
                              required: true,
                              message: 'El apellido es obligatorio',
                            },
                          ]}
                        >
                          <Input
                            className="input-data"
                            placeholder="Apellido"
                          />
                        </Form.Item>
                        <Form.Item
                          name="email"
                          label="Email"
                          rules={[
                            {
                              required: true,
                              message: 'El e-mail es obligatorio',
                            },
                            {
                              type: 'email',
                              message: 'El mail ingresado es incorrecto',
                            },
                          ]}
                        >
                          <Input
                            type="email"
                            className="input-data"
                            placeholder="Email"
                          />
                        </Form.Item>
                        <Form.Item name="phone" label="Teléfono">
                          <InputNumber
                            className="input-data"
                            placeholder="Teléfono"
                            controls={false}
                          />
                        </Form.Item>
                      </div>
                      <div>{persons}</div>
                    </Form>
                  </div>
                  <div className="card-event-section ">
                    <div className="card-event-mobile-text ">
                      <Typography.Title level={5}>Resumen</Typography.Title>
                      Verificá los datos antes de confirmar la compra
                    </div>
                    <div className="card-event-summary">
                      <EventCardSummary
                        price={amountToPay}
                        quantity={quantity}
                        image={urlFile || ''}
                        date={subscription.end_date}
                        ubication={
                          subscription.ubication
                            ? subscription.ubication
                            : subscription.event?.ubication
                        }
                      />
                      {preferenceId ? (
                        <>
                          <Mercadopago
                            instanceCheckoutMercadoPago={(instance) => {
                              setInstanceMercadoPago(instance);
                            }}
                            preferenceId={preferenceId}
                          />
                        </>
                      ) : null}
                      <>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="purchase-button"
                          size="large"
                          onClick={() => {
                            if (!preferenceId) {
                              form.submit();
                            } else {
                              instanceMercadoPago.open();
                            }
                          }}
                          loading={creatingOrder}
                        >
                          Comprar
                        </Button>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <NotFound404 subtitle="Lo sentimos, hubo un error en la página. Vuelva a intentarlo" />
            </>
          )}
        </>
      )}
    </>
  );
};
export default PurchaseConfirmationPage;
