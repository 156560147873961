import { createContext, Dispatch, SetStateAction } from 'react';
import { IEvent } from '../interfaces/Event';
import { IPointOfSale } from '../interfaces/PointOfSale';
import { ISubscription } from '../interfaces/Subscription';

interface IPointOfSaleContext {
  pointOfSale: IPointOfSale | undefined;
  setPointOfSale: Dispatch<SetStateAction<IPointOfSale | undefined>>;
  eventSelected: IEvent | undefined;
  setEventSelected: Dispatch<SetStateAction<IEvent | undefined>>;
  subscription: ISubscription | undefined;
  setSubscription: Dispatch<SetStateAction<ISubscription | undefined>>;
  ticketQuantity: number | undefined;
  setTicketQuantity: Dispatch<SetStateAction<number | undefined>>;
}

const context: IPointOfSaleContext = {
  pointOfSale: undefined,
  setPointOfSale: () => undefined,
  eventSelected: undefined,
  setEventSelected: () => undefined,
  subscription: undefined,
  setSubscription: () => undefined,
  ticketQuantity: undefined,
  setTicketQuantity: () => undefined,
};

export const PointOfSaleContext = createContext(context);
