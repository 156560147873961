import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import ProTable, { ActionType } from '@ant-design/pro-table';
import { Button, Divider, Input, Modal, Select, Tooltip } from 'antd';
import { ExporterDropdown, SaveForm } from '../../components/common/ABM';
import { useCallback, useContext, useRef, useState, useEffect } from 'react';
import { ExportableColumn, ExportableTable } from '../../shared/Exporter';
import { SorterResult } from 'antd/lib/table/interface';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ABM, Authorization, MomentJS, Tools } from '../../shared';
import ModalConfigureMultiple from '../../components/common/ModalConfigureMultiple/Index';
import { IRole } from '../../interfaces/role';
import { IPermission } from '../../interfaces/permission';
import { isPlatform } from '@ionic/react';
import { showCollapseRender } from '../../shared/showCollapseRender';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = ['name', 'role_id'];
const LIST_SORTER = ['name'];

/**
 * Se configura por cada ABM diferente
 */
const TITLE_PRO_TABLE = 'Formulario de consulta';
const TITLE_CREATE_FORM = 'Crear rol';
const TITLE_UPDATE_FORM = 'Editar rol';
const INPUT_SEARCH_PLACEHOLDER = 'Buscar...';

export default function RolePage() {
  // props

  // states
  const [searchText, setSearchText] = useState('');
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editForm, setEditFormValues] = useState<any>({});
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>('');
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [managePermissionsModalVisible, setManagePermissionsModalVisible] =
    useState(false);
  const [dataManagePermissions, setDataManagePermissions] = useState<IRole>({
    id: 0,
    name: '',
    is_system_role: false,
    editable: false,
  });
  const [dataRolePermission, setDataRolePermission] = useState<IPermission[]>(
    [],
  );
  const [rolesCombo, setRolesCombo] = useState<IRole[]>([]);
  const [modalConfigureMultipleLoading, setModalConfigureMultipleLoading] =
    useState<boolean>(false);
  const [dataPermissions, setDataPermissions] = useState<IPermission[]>([]);
  // services and hooks
  const { mobileOnSizeChangeProTable, changeView, showComponent } =
    useProTableForMobile({
      layout: 'horizontal',
    });
  const { Query, Mutation, customRequest } = GraphqlService();
  const {
    messageError,
    messageDeleteSuccess,
    messageDeleting,
    messageCreating,
    messageCreateSuccess,
    messageCreateError,
    messageUpdating,
    messageUpdateSuccess,
    messageUpdateError,
  } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});
  const reloadRoleCombo = useRef<boolean>(true);

  // contexts
  const { functions } = useContext(ContextApp);

  // methods

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const getPermissions = async () => {
    try {
      const data: IPermission[] = await customRequest({
        query: Query.permissions,
      });
      setDataPermissions(data);
      return {
        current: 1,
        data: data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const getRoles = async () => {
    try {
      const data: IRole[] = await customRequest({
        query: Query.roles,
      });
      setRolesCombo(() => data);
    } catch (error) {}
  };

  useEffect(() => {
    getPermissions();
    getRoles();
  }, []);

  const getRolePermission = async (id_role: number) => {
    setModalConfigureMultipleLoading(() => true);
    try {
      const data: IPermission[] = await customRequest({
        query: Query.permissions,
        variables: { filter: { role_id: id_role } },
      });
      setDataRolePermission(() => data);
      setModalConfigureMultipleLoading(() => false);
      return {
        current: 1,
        data: data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      setModalConfigureMultipleLoading(() => false);
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const request = async (params: any) => {
    delete variables.current.filter;
    delete variables.current.orderBy;
    variables.current = {};
    const search: any = ABM.valuesResult(params);

    if (searchText) {
      variables.current.searchText = searchText;
    } else {
      delete variables.current.searchText;
    }

    LIST_FILTER.forEach((element) => {
      try {
        if (search[element]) {
          if (!variables.current.filter) {
            variables.current.filter = {};
          }
          variables.current.filter[element] = search[element];
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    LIST_SORTER.forEach((element) => {
      try {
        if (search.sorter[element]) {
          if (!variables.current.orderBy) {
            variables.current.orderBy = {};
          }
          variables.current.orderBy.direction =
            Tools.getTypeOrderByTableSortParam(search.sorter[element]);
          variables.current.orderBy.field = element;
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });
    try {
      const data: IRole[] = await customRequest({
        query: Query.roles,
        variables: variables.current,
      });
      setDataTable(data);
      return {
        current: 1,
        data: data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const createRole = async (value: any) => {
    setFormLoading(() => true);
    messageCreating({
      context: 'TableRole.createRole.1',
      message: 'Rol',
    });
    try {
      await customRequest({
        mutation: Mutation.createRole,
        variables: {
          input: value,
        },
      });
      setCreateModalVisible(false);
      messageCreateSuccess({
        context: 'TableRole.createRole.2',
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'TableRole.createRole.3',
          message: error.message,
        });
      }
      messageCreateError({ context: 'TableRole.createRole.3' });
    }
    setFormLoading(() => false);
  };

  const updateRolePermissions = async (
    id: number,
    addPermissions: number[],
    deletePermissions: number[],
  ) => {
    if (!addPermissions.length && !deletePermissions.length) {
      return;
    }

    messageUpdating({
      context: 'TableRole.updateRole.1',
      message: 'rol',
    });
    try {
      await customRequest({
        mutation: Mutation.upsertRolePermission,
        variables: {
          input: {
            role_id: id,
            addPermissionIds: addPermissions,
            deletePermissionIds: deletePermissions,
          },
        },
      });
      messageUpdateSuccess({
        context: 'TableRole.updateRolePermissions.2',
      });
      setManagePermissionsModalVisible(false);
      if (actionRef.current?.reset) {
        reloadRoleCombo.current = true;
        actionRef.current.reset();
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'TableRole.updateRolePermissions.3',
          message: error.message,
        });
      }
      messageUpdateError({ context: 'TableRole.updateRolePermissions.3' });
    }
    setFormLoading(() => false);
  };

  const updateRole = async (value: any) => {
    setFormLoading(() => true);
    messageUpdating({
      context: 'TableRole.updateRole.1',
      message: 'rol',
    });
    try {
      await customRequest({
        mutation: Mutation.updateRole,
        variables: { input: { ...value, id: parseInt(editForm.id) } },
      });
      messageUpdateSuccess({
        context: 'TableRole.updateRole.2',
      });
      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'TableRole.updateRole.3',
          message: error.message,
        });
      }
      messageUpdateError({ context: 'TableRole.updateRole.3' });
    }
    setFormLoading(() => false);
  };

  const removeConfirmedRole = async (value: any) => {
    messageDeleting({
      context: 'Role.removeConfirmedRole.1',
      message: 'rol',
    });
    try {
      await customRequest({
        mutation: Mutation.deleteRole,
        variables: {
          id: parseInt(value.id),
        },
      });
      messageDeleteSuccess({
        context: 'Role.removeConfirmedRole.2',
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      messageError({
        context: 'Role.removeConfirmedRole.3',
        message: error.message,
      });
    }
  };

  const removeRole = (value: any) => {
    Modal.confirm({
      content: (
        <>
          <div>¿Seguro que desea eliminar el rol {value.name}?</div>
        </>
      ),
      cancelText: 'Cancelar',
      okText: 'Aceptar',
      onOk: () => {
        removeConfirmedRole(value);
      },
    });
  };

  const columns = useCallback(
    (): ExportableColumn<IRole>[] => [
      {
        export: false,
        dataIndex: 'id',
        title: 'id',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: 'name',
        title: 'Rol',
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'El rol es obligatorio',
            },
          ],
        },
        render: (_: any, record: { name: any }) => record.name || '-',
        renderFormItem: () => <Input placeholder="Ingrese rol" minLength={4} />,
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: 'role_id',
        title: 'Role',
        valueType: 'select',
        renderFormItem: () => (
          <Select
            options={rolesCombo.map((rol) => ({
              label: rol.name,
              value: rol.id,
            }))}
            placeholder="Seleccione rol..."
            mode="multiple"
            allowClear
            showSearch
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        title: 'Op.',
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 100,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.RoleUpdate,
            ) &&
              !record.is_system_role && (
                <Tooltip
                  key="edit_rol_tooltip"
                  trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                  title="Modificar rol"
                >
                  <EditOutlined
                    className="pointer"
                    onClick={() => {
                      handleUpdateModalVisible(true);
                      setEditFormValues(record);
                    }}
                  />
                </Tooltip>
              )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.RoleUpdate,
            ) &&
              !record.is_system_role && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="manage_permissions_tooltip"
                    trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                    title="Administrar permisos"
                  >
                    <ToolOutlined
                      className="pointer"
                      onClick={() => {
                        setManagePermissionsModalVisible(true);
                        setDataManagePermissions(() => record);
                        getRolePermission(record.id);
                      }}
                    />
                  </Tooltip>
                </>
              )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.RoleDelete,
            ) &&
              !record.is_system_role && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="remove_rol_tooltip"
                    trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                    title="Eliminar rol"
                  >
                    <DeleteOutlined
                      className="pointer"
                      onClick={() => {
                        removeRole(record);
                      }}
                    />
                  </Tooltip>
                </>
              )}
          </>
        ),
      },
    ],
    [dataTable],
  );
  let LIST_FILTER_NAMES = columns()
    // eslint-disable-next-line array-callback-return
    .filter((value) => {
      if (
        LIST_FILTER.find((element) => element === value.dataIndex) &&
        value.hideInTable === false
      ) {
        return value.title;
      }
    })
    .map((element) => {
      return element.title;
    });

  const exportableTable: ExportableTable<IRole> = {
    columns: columns(),
    items: dataTable,
    filename: `rol_${MomentJS.momentDefaultFormat()}`,
  };

  return (
    <>
      <ProTable<IRole>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        headerTitle={TITLE_PRO_TABLE}
        actionRef={actionRef}
        rowKey="id"
        search={{
          resetText: 'Limpiar',
          searchText: 'Buscar',
          collapseRender: showCollapseRender(columns()),
        }}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<IRole>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <div className="content-search-table">
            <Tooltip
              key="searchtext"
              title={'Buscar por: ' + LIST_FILTER_NAMES.join(', ')}
            >
              <Input.Search
                size="middle"
                placeholder={INPUT_SEARCH_PLACEHOLDER}
                enterButton
                value={searchText}
                onSearch={handleSearch}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                allowClear={true}
              />
            </Tooltip>
          </div>,
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.RoleCreate,
            ) && (
              <Button
                type="primary"
                onClick={() => {
                  setCreateModalVisible(true);
                }}
              >
                <PlusOutlined /> Nuevo
              </Button>
            )}
          </>,
          <>
            <ExporterDropdown exportable={exportableTable} />
          </>,
          <Button onClick={changeView} className="buttonChangeProTableView">
            Cambiar Vista
          </Button>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns()}
      />
      <SaveForm
        loading={formLoading}
        title={TITLE_CREATE_FORM}
        onCancel={() => {
          setCreateModalVisible(false);
        }}
        modalVisible={createModalVisible}
        onOk={async (value) => createRole(value)}
        columns={columns()}
      />
      {editForm && (
        <SaveForm
          loading={formLoading}
          title={TITLE_UPDATE_FORM}
          modalVisible={updateModalVisible}
          values={editForm}
          columns={columns()}
          onOk={async (value) => updateRole(value)}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues({});
          }}
        />
      )}
      {Authorization.security(
        functions,
        EnumsValues.Functions.RolePermissionUpdate,
      ) && (
        <ModalConfigureMultiple<IRole>
          renderLabel={(entity) => entity.name}
          checks={dataPermissions}
          data={[
            dataManagePermissions.id,
            dataRolePermission?.map((dataRole) => dataRole.id) || [],
          ]}
          onCancel={() => {
            setManagePermissionsModalVisible(false);
          }}
          onOk={(id_role, add_permissions, delete_permissions) => {
            return updateRolePermissions(
              id_role,
              add_permissions,
              delete_permissions,
            );
          }}
          modalVisible={managePermissionsModalVisible}
          titleModal="Administrar permisos"
          loading={modalConfigureMultipleLoading}
        />
      )}
    </>
  );
}
