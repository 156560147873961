export const SUBSCRIPTION = `
query subscription($id: Int!) {
  subscription(id: $id) {
    __typename
    ... on Subscription {
      id
      event {
        id
        name
        card_description
        description
        ubication
        published
        tenant_id
        event_picture_id
        visible_from
        visible_to
        its
        uts
        dts
      }
      name
      quantity
      description
      ubication
      card_description
      visible_to
      visible_from
      published
      start_date
      end_date
      price
      change_reason
      its
      uts
      dts
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const PUBLIC_SUBSCRIPTION = `
query publicSubscription($id: Int!) {
  publicSubscription(id: $id) {
    __typename
    ... on Subscription {
      id
      event {
        id
        name
        card_description
        description
        ubication
        published
        tenant_id
        event_picture_id
      }
      name
      quantity
      description
      ubication
      card_description
      published
      start_date
      end_date
      price
      change_reason
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
export const SUBSCRIPTIONS = `
query subscriptions(
  $orderBy: SubscriptionOrderInput
  $filter: FilterSubscriptionInput
  $searchText: String
  $skip: Int
  $take: Int
) {
  subscriptions(
    orderBy: $orderBy
    filter: $filter
    searchText: $searchText
    skip: $skip
    take: $take
  ) {
    __typename
    ... on Subscription {
      id
      event {
        id
        name
        card_description
        description
        ubication
        published
        tenant_id
        event_picture_id
        visible_from
        visible_to
        its
        uts
        dts
      }
      name
      quantity
      description
      ubication
      card_description
      visible_to
      visible_from
      published
      start_date
      end_date
      price
      change_reason
      its
      uts
      dts
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const SUBSCRIPTION_COUNT = `
query subscriptionCount($filter: FilterSubscriptionInput, $orderBy: SubscriptionOrderInput) {
  subscriptionCount(filter: $filter, orderBy: $orderBy) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const PUBLISHED_SUBSCRIPTIONS = `
query publishedSubscriptions(
  $orderBy: SubscriptionOrderInput
  $filter: FilterSubscriptionInput
  $searchText: String
  $skip: Int
  $take: Int
) {
  publishedSubscriptions(
    orderBy: $orderBy
    filter: $filter
    searchText: $searchText
    skip: $skip
    take: $take
  ) {
    __typename
    ... on Subscription {
      id
      event {
        id
        name
        card_description
        description
        ubication
        published
        tenant_id
        event_picture_id
        visible_from
        visible_to
        its
        uts
        dts
      }
      change_reason
      name
      quantity
      description
      ubication
      card_description
      visible_to
      visible_from
      published
      start_date
      end_date
      price
      its
      uts
      dts
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const TICKET_COUNT = `
query ticketCount($subscriptionId: Int!) {
  ticketCount(subscriptionId: $subscriptionId) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
