export const CREATE_SCAN_LOG = `
mutation createScanLog($input: CreateScanLogInput!) {
  createScanLog(input: $input) {
    __typename
    ... on ScanLog {
      id
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`