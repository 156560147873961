import GraphqlService from './graphql/GraphqlService';

export const TicketService = () => {
  const { customRequest, Query } = GraphqlService();

  const getTicketPDF = (
    ticketId: number,
  ): Promise<{
    result: string;
  }> => {
    return customRequest({
      query: Query.ticketPDF,
      variables: {
        id: ticketId,
      },
    });
  };

  return {
    getTicketPDF,
  };
};
