import { PageLoading } from '@ant-design/pro-layout';
import { isPlatform } from '@ionic/react';
import { Tooltip } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Modal from 'antd/lib/modal/Modal';
import { useEffect, useRef, useState } from 'react';
import './style.less';
/**
 * Las opciones se renderizan con una función en el componenete renderLabel.
 * Ejemplo:
 * renderLabel={renderModalConfigureLabel}
 * 
 * dentro del componente padre (PumpOrder en este caso). Defino la función de renderizado
 * const renderModalConfigureLabel: IModalConfigureMultipleProps<IOperator>["renderLabel"] =
    (entity: IOperator) => {
      return `${entity.lastname}, ${entity.firstname} (${entity.employee_code})`;
    };
 */
export interface IModalConfigureMultipleProps<T> {
  data: any;
  modalVisible: boolean;
  checks: (any & {
    id: number;
  })[];
  onCancel: () => void;
  onOk: (id: number, add: number[], remove: number[]) => Promise<void>;
  titleModal: string;
  renderLabel: (entity: T) => React.ReactNode;
  loading?: boolean;
}

export default function ModalConfigureMultiple<T>(
  props: IModalConfigureMultipleProps<T>,
) {
  const [originalIds, setOriginalIds] = useState<number[]>([]);
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [unCheckedIds, setUncheckedIds] = useState<number[]>([]);
  const {
    modalVisible,
    onCancel,
    checks,
    titleModal,
    data,
    onOk,
    renderLabel,
    loading,
  } = {
    ...props,
  };
  const preventDataChange = useRef<boolean>(false);
  const cleanUpState = () => {
    setCheckedIds(() => []);
    setUncheckedIds(() => []);
    setOriginalIds(() => []);
  };

  useEffect(() => {
    if (!preventDataChange.current) {
      cleanUpState();
      if (modalVisible) {
        data[1].map((checkId: number) =>
          setCheckedIds((oldCheckedIds) => [...oldCheckedIds, checkId]),
        );
        data[1].map((checkId: number) =>
          setOriginalIds((oldCheckedIds) => [...oldCheckedIds, checkId]),
        );
      }
    }
  }, [data, modalVisible]);

  const handleCheck = (id: number) => {
    if (checkedIds.some((checkedId) => checkedId === id)) {
      setCheckedIds(() => checkedIds.filter((checkedId) => checkedId !== id));
      setUncheckedIds(() => [...unCheckedIds, id]);
    } else {
      setUncheckedIds(() =>
        unCheckedIds.filter((unCheckedId) => unCheckedId !== id),
      );
      setCheckedIds(() => [...checkedIds, id]);
    }
  };

  const remove = (originalIds: number[], unchecked: number[]) => {
    return unchecked.filter((value) => originalIds.includes(value));
  };

  const add = (originalIds: number[], checked: number[]) => {
    return checked.filter((item) => originalIds.indexOf(item) < 0);
  };

  return (
    <>
      <Modal
        title={titleModal}
        destroyOnClose
        visible={modalVisible}
        onCancel={() => onCancel()}
        onOk={() => {
          preventDataChange.current = true;
          onOk(
            data[0],
            add(originalIds, checkedIds),
            remove(originalIds, unCheckedIds),
          ).finally(() => {
            preventDataChange.current = false;
          });
        }}
      >
        {loading ? (
          <PageLoading />
        ) : (
          <div className="containerChecks">
            {checks.map((check) => (
              <Checkbox
                key={check.id}
                onChange={() => handleCheck(check.id)}
                checked={checkedIds.some((checkedId) => checkedId === check.id)}
              >
                <Tooltip
                  key="permissions_description_tooltip"
                  trigger={isPlatform('desktop') ? 'hover' : 'focus'}
                  title={check.description}
                >
                  {renderLabel(check)}
                </Tooltip>
              </Checkbox>
            ))}
          </div>
        )}
      </Modal>
    </>
  );
}
