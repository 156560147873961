import { Card, Typography } from 'antd';
import { IPointOfSale } from '../../interfaces/PointOfSale';
import './PointOfSaleCard.less';

function PointOfSaleCard({ pointOfSale }: { pointOfSale: IPointOfSale }) {
  return (
    <Card size="small" className="pointOfSaleCard">
      <div className="pointOfSaleCard__content">
        <Typography style={{ fontWeight: 600 }}>{pointOfSale.name}</Typography>
        <Typography>{pointOfSale.tenant.name}</Typography>
      </div>
    </Card>
  );
}

export default PointOfSaleCard;
