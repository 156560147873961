import { useCallback, useContext, useEffect, useState } from 'react';
import {
  DeleteOutlined,
  DownloadOutlined,
  ClearOutlined,
  SaveOutlined,
  UploadOutlined,
  UserOutlined,
  KeyOutlined,
} from '@ant-design/icons';
import {
  Input,
  Form,
  Button,
  Row,
  Upload,
  Select,
  Alert,
  Badge,
  Spin,
  Modal,
} from 'antd';
import { Tools, Authorization } from '../../shared';
import './Profile.less';
import GraphqlService, {
  IFileData,
} from '../../services/graphql/GraphqlService';
import { RcFile } from 'antd/lib/upload';
import { useForm } from 'antd/lib/form/Form';
import Avatar from 'antd/lib/avatar/avatar';

import moment from 'moment-timezone';
import { IAppSetting } from '../../interfaces/AppSetting';
import { IUser } from '../../interfaces/user';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import QrCode from '../../components/common/QrCode';
import { vCardGenerator } from '../../shared/vCardGenerator';
import { ExportableColumn } from '../../shared/Exporter';
import { IProfile } from '../../interfaces';
import { SaveForm } from '../../components/common/ABM';
import { IBaseFile } from '../../interfaces/defaultSchema';

type PasswordError = '' | 'error';

const ProfilePage = (props: any) => {
  const { functions, user, setUser, setPictureProfileUrl, pictureProfileUrl } =
    useContext(ContextApp);
  const [formLoading, setFormLoading] = useState(false);

  const [loadingProfilePicture, setLoadingProfilePicture] =
    useState<boolean>(false);
  const [updatePasswordModal, setUpdatePasswordModal] =
    useState<boolean>(false);
  const [passwordRegex, setPasswordRegex] = useState<IAppSetting>();

  const [passwordError, setPasswordError] = useState<PasswordError>('');
  const [toDeleteProfilePicture, setToDeleteProfilePicture] =
    useState<boolean>(false);
  const [profilePictureId, setProfilePictureId] = useState<number>();
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
  const [form] = useForm();

  const TITLE_UPDATE_PROFILE_FORM = 'Cambiar contraseña';

  const {
    messageError,
    messageLoading,
    messageUpdateSuccess,
    messageUpdateError,
    showMessageError,
    messageModalSuccess,
  } = CustomMessage();

  const uploadProps = {
    ...props,
    progress: {
      type: 'line',
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 30,
      format: (percent: number) => {
        return `${parseFloat(percent.toFixed(2))}%`;
      },
    },
  };
  // services and hooks
  const [errorFile, setErrorFile] = useState<string>();
  const { Query, Mutation, customRequest, customFileRequest } =
    GraphqlService();
  const [maxSizeFile, setMaxSizeFile] = useState<number>(
    EnumsValues.SystemLimits.MaxSizeOfFiles,
  );
  const [file, setFile] = useState<RcFile>();
  const [urlFile, setUrlFile] = useState<string>();
  const [vcard, setVcard] = useState<string>();

  const getRegex = async () => {
    try {
      const data = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.PasswordRegex },
        },
      });

      setPasswordRegex(data);
    } catch (error: any) {
      messageError({
        context: 'User.getRegex.1',
        message: error?.message,
      });
    }
  };

  const getLimitMaxSizeFileSetting = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.LimitMaxSizeFile },
        },
      });
      setMaxSizeFile(Number(data.setting_value));
    } catch (error) {}
  };

  const createProfilePicture = async () => {
    if (
      !Authorization.security(
        functions,
        EnumsValues.Functions.ProfilePictureCreate,
      )
    ) {
      return;
    }

    if (file) {
      let fileArray: IFileData[] = [];

      let variables: any = {
        file: {
          filename: file.name,
          mimetype: file.type,
          encoding: 'base64',
        },
      };
      fileArray = [
        {
          file,
          path: 'variables.file',
        },
      ];

      try {
        const data = await customFileRequest(
          {
            mutation: Mutation.createProfilePicture,
            variables: variables,
          },
          fileArray,
        );
        Tools.getBase64WithCallback(file, (fileUrl: string) => {
          setPictureProfileUrl(fileUrl);
        });
        setProfilePictureId(data.id);
      } catch (error: any) {
        showMessageError({
          context: 'CompleteProfile.createProfilePicture.3',
          error: error,
        });
      }
    }
  };

  const descargarPDF = async (userId?: number) => {
    setLoadingPDF(true);
    if (userId)
      try {
        const response: IBaseFile = await customRequest({
          query: Query.getProfileFile,
          variables: { id: userId },
        });
        Tools.downloadFilePDF(response);

        messageModalSuccess({
          context: 'CompleteProfile.descargarPDF.1',
          message: 'El archivo PDF se generó correctamente',
        });
      } catch (error: any) {
        showMessageError({
          context: 'CompleteProfile.descargarPDF.2',
          error: error,
        });
      }
    else
      messageError({
        context: 'CompleteProfile.descargarPDF.3',
        message: 'No se indicó número de usuario',
      });
    setLoadingPDF(false);
  };

  useEffect(() => {
    if (user?.profile_picture_id) {
      setProfilePictureId(user.profile_picture_id);
      getProfilePicture(user.profile_picture_id);
      getLimitMaxSizeFileSetting();
    }
    getRegex();
  }, []);

  useEffect(() => {
    if (pictureProfileUrl) {
      setUrlFile(pictureProfileUrl);
    }
  }, [pictureProfileUrl]);

  const getProfilePicture = async (profile_picture_id: number) => {
    if (!profile_picture_id) return;
    setLoadingProfilePicture(true);
    try {
      const data = await customRequest({
        query: Query.getProfilePicture,
        variables: { id: profile_picture_id },
      });
      setUrlFile(data.result);
    } catch (error: any) {
      showMessageError({
        context: 'CompleteProfile.getProfilePicture.3',
        error: error,
      });
    } finally {
      setLoadingProfilePicture(false);
    }
  };

  const deleteProfilePicture = async (profile_picture_id: number) => {
    try {
      await customRequest({
        query: Mutation.deleteProfilePicture,
        variables: { id: profile_picture_id },
      });
      setUrlFile(undefined);
      setProfilePictureId(undefined);
    } catch (error: any) {
      showMessageError({
        context: 'CompleteProfile.deleteProfilePicture.1',
        error: error,
      });
    }
  };

  const updateProfilePicture = async () => {
    if (
      !Authorization.security(
        functions,
        EnumsValues.Functions.ProfilePictureUpdate,
      )
    ) {
      return;
    }

    if (file) {
      let fileArray: IFileData[] = [];

      let variables: any = {
        file: {
          filename: file.name,
          mimetype: file.type,
          encoding: 'base64',
        },
      };
      fileArray = [
        {
          file,
          path: 'variables.file',
        },
      ];

      try {
        const data = await customFileRequest(
          {
            mutation: Mutation.updateProfilePicture,
            variables: variables,
          },
          fileArray,
        );
        Tools.getBase64WithCallback(file, (fileUrl: string) => {
          setPictureProfileUrl(fileUrl);
        });
        setProfilePictureId(data.id);
      } catch (error: any) {
        showMessageError({
          context: 'CompleteProfile.updateProfilePicture.3',
          error: error,
        });
      }
    }
  };

  const updateUser = async (value: any) => {
    if (user) {
      setFormLoading(() => true);
      messageLoading({
        context: 'TableUser.updateUser.2',
        message: 'Actualizando perfil',
      });
      try {
        const { timezone, phone } = value;
        const data = await customRequest({
          mutation: Mutation.updateUser,
          variables: {
            input: { id: user.id, profile_id: user.profile_id },
            inputProfile: { ...user?.profile, id: undefined, timezone },
            inputPerson: { ...user?.person, id: undefined, phone },
          },
        });
        setUser((oldState: IUser) => {
          const newState = { ...oldState };
          newState.person = data.person;
          newState.person_id = data.person_id;
          newState.profile = data.profile;
          newState.profile_id = data.profile_id;
          if (toDeleteProfilePicture && profilePictureId) {
            deleteProfilePicture(profilePictureId);
          } else if (profilePictureId) {
            updateProfilePicture();
          } else {
            createProfilePicture();
          }
          return newState;
        });
        messageUpdateSuccess({
          context: 'TableUser.updateUser.2',
        });
      } catch (error: any) {
        if (error.status_code && error.message) {
          return messageError({
            context: 'TableUser.createUser.3',
            message: error.message,
          });
        }
        messageUpdateError({ context: 'TableUser.updateUser.3' });
      } finally {
        setFormLoading(() => false);
      }
    }
  };

  useEffect(() => {
    const cardData = {
      firstname: user?.person?.firstname || '',
      lastname: user?.person?.lastname || '',
      email: user?.person?.email || '',
      phone: user?.person?.phone || '',
    };
    const card = vCardGenerator(cardData);
    setVcard(card);
  }, [user]);

  const updatePassword = async (value: any) => {
    const { oldPassword, newPassword } = value;
    try {
      await customRequest({
        mutation: Mutation.updateMyPassword,
        variables: {
          input: { oldPassword, newPassword },
        },
      });
      setUpdatePasswordModal(() => false);
      messageModalSuccess({
        context: 'CompleteProfile.updatePassword.1',
        message: 'La contraseña se actualizó correctamente',
      });
    } catch (error: any) {
      messageError({
        context: 'CompleteProfile.updatePassword.1',
        message: error.message,
      });

      if (error?.status_code === 51) {
        setPasswordError('error');
      }
    }
  };

  const columnsProfile = useCallback(
    (): ExportableColumn<IProfile>[] => [
      {
        export: true,
        dataIndex: 'oldPassword',
        title: 'Contraseña anterior',
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'Debe ingresar una contraseña',
            },
          ],
        },
        renderFormItem: () => (
          <Input.Password
            autoComplete="old-password"
            placeholder="Ingrese su contraseña..."
            maxLength={100}
            status={passwordError}
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'newPassword',
        title: 'Contraseña',
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'Debe ingresar una contraseña',
            },
            {
              validator(_, value) {
                let regex = new RegExp(String(passwordRegex?.setting_value));
                if (regex.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('La contraseña no cumple con los requisitos'),
                );
              },
            },
          ],
        },
        renderFormItem: () => (
          <Input.Password
            autoComplete="newPassword"
            placeholder="Ingrese una contraseña..."
            maxLength={100}
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        renderFormItem: () => (
          <div className="messageRegExp">
            <Badge color="#606366" status="default" />
            <p className="textRegExp">{passwordRegex?.description}</p>
          </div>
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'repeatPassword',
        title: 'Repetir contraseña',
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'Debe repetir la contraseña',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Las contraseñas no coinciden!'),
                );
              },
            }),
          ],
        },
        renderFormItem: () => (
          <Input.Password
            placeholder="Repita la contraseña..."
            maxLength={200}
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
        dependencies: ['newPassword'],
      },
    ],
    [passwordRegex, passwordError],
  );

  const removeProfileImage = () => {
    Modal.confirm({
      content: <div>¿Seguro que desea eliminar la imagen de perfil?</div>,
      cancelText: 'Cancelar',
      okText: 'Aceptar',
      onOk: () => {
        setUrlFile(undefined);
        setToDeleteProfilePicture(true);
      },
    });
  };

  return (
    <div className="container-form-profile">
      <div className="content-container-form-profile">
        <div className="container-avatar" style={{ justifyContent: 'center' }}>
          <div className="avatar-child">
            <Avatar
              className="avatar"
              size={130}
              icon={
                loadingProfilePicture ? (
                  <Spin size="default" />
                ) : (
                  <UserOutlined />
                )
              }
              src={urlFile ? urlFile : undefined}
            />
          </div>
          <div className="avatar-child">
            <Button
              className={`button ${urlFile && 'show-button'}`}
              size="large"
              type={'link'}
              onClick={() => {
                if (urlFile) {
                  removeProfileImage();
                }
              }}
            >
              <DeleteOutlined />
              Eliminar
            </Button>
          </div>
        </div>
        <Form
          onFinish={updateUser}
          layout="vertical"
          initialValues={{
            phone: user?.person?.phone,
            timezone: user?.profile?.timezone,
          }}
          form={form}
        >
          <div className="content-container-center">
            <Form.Item>
              <Upload
                {...uploadProps}
                multiple={false}
                maxCount={1}
                showUploadList={false}
                customRequest={(uploadRequestOptions) => {
                  const { onSuccess, file } = uploadRequestOptions;
                  const fileRc = file as RcFile;
                  if (fileRc.size > maxSizeFile) {
                    setErrorFile(
                      'El tamaño de la imagen excede el límite permitido.',
                    );
                  } else {
                    setErrorFile(undefined);
                  }
                  setFile(fileRc);
                  Tools.getBase64WithCallback(file, (fileUrl: string) => {
                    setUrlFile(fileUrl);
                  });
                  if (onSuccess) {
                    setToDeleteProfilePicture(false);
                    onSuccess('');
                  }
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  {errorFile ? (
                    <Alert
                      style={{ marginTop: '14px' }}
                      message={errorFile}
                      type="error"
                      showIcon
                    />
                  ) : null}

                  <Button
                    style={{ width: '100%' }}
                    type="link"
                    icon={<UploadOutlined />}
                  >
                    {urlFile !== undefined
                      ? 'Cambiar imagen de perfil'
                      : 'Subir imagen de perfil'}
                  </Button>
                </div>
              </Upload>
            </Form.Item>
            <Button
              onClick={() => {
                setUpdatePasswordModal(true);
              }}
            >
              <KeyOutlined />
              Cambiar contraseña
            </Button>
          </div>
          {vcard && (
            <QrCode
              className="qr-code"
              value={vcard}
              width={150}
              tooltip="Click para agrandar"
            />
          )}
          <Button
            style={{ width: '100%' }}
            type="primary"
            icon={<DownloadOutlined />}
            onClick={() => {
              descargarPDF(user?.id);
            }}
            loading={loadingPDF}
          >
            Descargar tarjeta de perfil
          </Button>
          <Form.Item label="Teléfono" name="phone">
            <Input placeholder="Ingrese teléfono..." />
          </Form.Item>
          <Form.Item label="Zona horaria" name="timezone">
            <Select
              options={moment.tz
                .names()
                .map((option) => ({
                  value: option,
                  label: `(GMT ${moment
                    .tz(option)
                    .format('Z')}) ${option.replace('_', ' ')}`,
                }))
                .sort(Tools.sortByZone)}
              placeholder="Seleccione zona horaria..."
              showArrow
              showSearch
              allowClear
              filterOption={(inputValue, option: any) =>
                option?.label
                  ?.toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              }
            />
          </Form.Item>
          <Row className="buttons">
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                Guardar
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  form.resetFields();
                }}
                icon={<ClearOutlined />}
              >
                Limpiar
              </Button>
            </Form.Item>
          </Row>
        </Form>
        <SaveForm
          loading={formLoading}
          title={TITLE_UPDATE_PROFILE_FORM}
          modalVisible={updatePasswordModal}
          columns={columnsProfile()}
          onOk={(value) => updatePassword(value)}
          onCancel={() => {
            setPasswordError('');
            setUpdatePasswordModal(false);
          }}
        />
      </div>
    </div>
  );
};

export default ProfilePage;
