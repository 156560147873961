import { Menu, MenuItemProps, MenuProps, SubMenuProps } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IRouteConfig } from '../../../interfaces/routeConfig';
import { Authorization } from '../../../shared';
import { useCurrentRoute } from '../../../hooks';
import { LogoComponent } from './LogoComponent';
import { ContextApp } from '../../../contexts/ContextApp';
import { version } from '../../../../package.json';
import Text from 'antd/lib/typography/Text';

export const MainMenu = ({ routes }: { routes: IRouteConfig[] }) => {
  const { functions, mainMenuCollapsed } = useContext(ContextApp);
  const { leafRoute, matchedRoutes } = useCurrentRoute();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  useEffect(() => {
    if (leafRoute) {
      setSelectedKeys([leafRoute.key]);
    } else {
      setSelectedKeys([]);
    }

    const matchedNotLeafRoutes = matchedRoutes.filter(
      (item) => !!item.routes?.length,
    );
    if (matchedNotLeafRoutes.length && !mainMenuCollapsed) {
      const routesKeys = matchedNotLeafRoutes.map((item) => item.key);
      setOpenKeys((oldOpenKeys) => {
        const keys = new Set(oldOpenKeys);
        for (const routesKey of routesKeys) {
          keys.add(routesKey);
        }
        return Array.from(keys);
      });
    }
  }, [leafRoute?.key, mainMenuCollapsed]);

  useEffect(() => {
    if (mainMenuCollapsed) {
      setOpenKeys([]);
    }
  }, [mainMenuCollapsed]);

  const menuProps: MenuProps = {
    theme: 'dark',
    mode: 'inline',
  };

  const toggleSubMenuOpen = (e: { key: string }) => {
    setOpenKeys((oldOpenKeys) => {
      if (oldOpenKeys.includes(e.key)) {
        return oldOpenKeys.filter((item) => item !== e.key);
      }
      return [...oldOpenKeys, e.key];
    });
  };

  const renderMenuRoutes = useCallback(
    (paramRoutes: IRouteConfig[]) => {
      return paramRoutes.map((item) => {
        if (
          (item.authority &&
            !Authorization.security(functions, item.authority)) ||
          item.hideInMenu
        ) {
          return null;
        }
        if (item.routes && !item.hideSubRoutes) {
          const subMenuProps: SubMenuProps = {};
          if (item.icon) {
            subMenuProps.icon = item.icon;
          }
          subMenuProps.title = item.name;
          return (
            <SubMenu
              onTitleClick={toggleSubMenuOpen}
              key={item.key}
              {...subMenuProps}
            >
              {renderMenuRoutes(item.routes)}
            </SubMenu>
          );
        } else {
          const menuItemProps: MenuItemProps = {};
          if (item.icon) {
            menuItemProps.icon = item.icon;
          }
          return (
            <Menu.Item key={item.key} {...menuItemProps}>
              <Link to={item.path}>{item.name}</Link>
            </Menu.Item>
          );
        }
      });
    },
    [functions],
  );

  return (
    <>
      <LogoComponent />
      <Menu
        className="MainMenu"
        {...menuProps}
        selectedKeys={selectedKeys}
        openKeys={openKeys}
      >
        {renderMenuRoutes(routes)}
      </Menu>
      <div
        style={{
          position: 'sticky',
          top: '95%',
          textAlign: 'center',
          lineHeight: '40px',
        }}
      >
        <Text
          style={{
            color: 'gray',
          }}
          type="secondary"
        >
          Versión: {version}
        </Text>
      </div>
    </>
  );
};
