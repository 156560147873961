import { useCurrentRoute } from '../../../../hooks';

const CurrentRouteLabel = ({ className }: { className?: string }) => {
  const { leafRoute } = useCurrentRoute();

  if (leafRoute) {
    const iconComponent = leafRoute.icon || null;
    return (
      <div className={className}>
        {iconComponent}
        <span className="currentRouteLabelName">{leafRoute.name || ''}</span>
      </div>
    );
  }

  return null;
};

export default CurrentRouteLabel;
