import { Skeleton, Tag } from 'antd';
import moment from 'moment';
import { IEvent } from '../../interfaces/Event';
import { ISubscription } from '../../interfaces/Subscription';
import { formatMoney } from '../../shared/formatMoney';
import { DEFAULT_FORMAT_DATE_2 } from '../../shared/MomentJS';
import './CardOnlyImage.less';

declare type CardOnlyImageDataInterface = ISubscription | IEvent;
export interface ICardOnlyImageProps {
  img: string | null;
  onClick?: () => void;
  data: CardOnlyImageDataInterface;
  disabled?: boolean;
}
const CardOnlyImage = ({
  img,
  onClick,
  data,
  disabled,
}: ICardOnlyImageProps) => {
  const dataIsSubscription = (
    _data: CardOnlyImageDataInterface,
  ): _data is ISubscription => {
    const quantity = (_data as ISubscription).quantity;
    return quantity !== null && typeof quantity !== 'undefined' ? true : false;
  };

  const isHoverableDevice: MediaQueryList = window.matchMedia(
    '(hover: hover) and (pointer: fine)',
  );

  return (
    <div
      className={`card-only-image ${
        disabled ? 'card-only-image-disabled' : ''
      }`}
      onClick={() => {
        if (onClick && !disabled) onClick();
      }}
      style={{
        backgroundImage: img
          ? `url('${img}')`
          : `url('${process.env.PUBLIC_URL}/assets/image-isnt-available.jpg')`,
      }}
    >
      {img === null && <Skeleton.Image className="ant-skeleton-active" />}
      <div
        className={`card-only-image__content ${
          isHoverableDevice.matches && 'card-only-image__content-hoverable'
        }`}
      >
        <div className="card-only-image__content__info">
          {dataIsSubscription(data) ? (
            <>
              <span className="card-only-image__content__info__title">
                {data.event?.name}
              </span>
              <span className="card-only-image__content__info__date-start">
                Fecha inicio:{' '}
                {moment(data.start_date).format(DEFAULT_FORMAT_DATE_2)}
              </span>
              <span className="card-only-image__content__info__date-end">
                Fecha fin: {moment(data.end_date).format(DEFAULT_FORMAT_DATE_2)}
              </span>
              <span className="card-only-image__content__info__price">
                {data.price ? formatMoney(data.price) : 'Gratis'}{' '}
                {!data.quantity ? (
                  <Tag
                    color="red"
                    className="card-only-image__content__info__price__tag"
                  >
                    Agotado
                  </Tag>
                ) : null}
              </span>
            </>
          ) : (
            <>
              <span className="card-only-image__content__info__title">
                {data.name}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default CardOnlyImage;
