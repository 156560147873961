import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { PointOfSaleContext } from '../../contexts/PointOfSaleContext';
import { IEvent } from '../../interfaces/Event';
import { IPointOfSale } from '../../interfaces/PointOfSale';
import { ISubscription } from '../../interfaces/Subscription';

const PointOfSaleProvider = PointOfSaleContext.Provider;

const PointOfSaleProviderPage = ({ route }: any) => {
  const [pointOfSale, setPointOfSale] = useState<IPointOfSale>();
  const [eventSelected, setEventSelected] = useState<IEvent>();
  const [subscription, setSubscription] = useState<ISubscription>();
  const [ticketQuantity, setTicketQuantity] = useState<number>();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/app/pointofsale')
      history.replace('/app/pointofsale/list');
  }, [location]);

  return (
    <PointOfSaleProvider
      value={{
        pointOfSale,
        setPointOfSale,
        eventSelected,
        setEventSelected,
        subscription,
        setSubscription,
        ticketQuantity,
        setTicketQuantity,
      }}
    >
      {renderRoutes(route.routes)}
    </PointOfSaleProvider>
  );
};

export default PointOfSaleProviderPage;
