import { Button, FormInstance, List, Modal, Tag } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import BackButton from '../../components/common/BackButton/BackButton';
import PurchaseConfirmationForm from '../../components/Order/PurchaseConfirmationForm';
import PurchasedTicketItem from '../../components/Ticket/PurchasedTicketItem';
import { PointOfSaleContext } from '../../contexts/PointOfSaleContext';
import { CustomMessage } from '../../hooks';
import { IPurchaseConfirmationFormValues } from '../../interfaces/IPurchaseConfirmationFormValues';
import { IPurchasedOrderDTO } from '../../interfaces/Order';
import { PointOfSaleService } from '../../services/PointOfSale.service';
import './PointOfSaleTicketGeneration.less';

const PointOfSaleTicketGeneration = () => {
  const { ticketQuantity, subscription, pointOfSale } =
    useContext(PointOfSaleContext);
  const history = useHistory();
  const { generateOrderInPointOfSale, sendOrderInPointOfSaleByMail } =
    PointOfSaleService();
  const { getErrorMessage, messageError } = CustomMessage();
  const [formLoading, setFormLoading] = useState(false);
  const [generatedOrder, setGeneratedOrder] = useState<IPurchasedOrderDTO>();
  const [mailStatus, setMailStatus] =
    useState<'not-sent' | 'sent' | 'loading'>('not-sent');

  if (!ticketQuantity || !subscription || !pointOfSale) {
    return history.replace('/app/pointofsale/event');
  }

  const onSubmit = async (
    values: IPurchaseConfirmationFormValues,
    form: FormInstance,
  ) => {
    setFormLoading(true);
    try {
      const result = await generateOrderInPointOfSale(pointOfSale.id, values);
      setGeneratedOrder(result);
      form.resetFields();
    } catch (error) {
      messageError({
        context: 'PointOfSaleTicketGeneration - submit',
        message: getErrorMessage(error),
      });
    }
    setFormLoading(false);
  };

  const onModalClose = () => {
    setGeneratedOrder(undefined);
    setMailStatus('not-sent');
  };

  const sendOrderByMail = async (orderId: number) => {
    setMailStatus('loading');
    try {
      await sendOrderInPointOfSaleByMail(orderId);
      setMailStatus('sent');
    } catch (error) {
      messageError({
        context: 'PointOfSaleTicketGeneration - sendOrderByMail',
        message: getErrorMessage(error),
      });
      setMailStatus('not-sent');
    }
  };

  let mailButton: JSX.Element | null = null;

  if (generatedOrder?.ticket.length) {
    if (mailStatus === 'not-sent') {
      mailButton = (
        <Button
          onClick={() => sendOrderByMail(generatedOrder.id)}
          type="primary"
          block
        >
          Enviar por mail
        </Button>
      );
    } else if (mailStatus === 'loading') {
      mailButton = (
        <Button loading type="primary" block>
          Enviando...
        </Button>
      );
    } else {
      mailButton = (
        <Tag style={{ display: 'block', padding: 4 }} color="success">
          Enviado correctamente
        </Tag>
      );
    }
  }

  return (
    <>
      <BackButton />
      <PurchaseConfirmationForm
        classname="pointOfSalePurchaseConfirmation"
        subscription={subscription}
        quantity={ticketQuantity}
        onSubmit={onSubmit}
        loading={formLoading}
        textProps={{
          submitText: 'Emitir',
        }}
      />
      <Modal
        onCancel={onModalClose}
        footer={null}
        title="Tickets emitidos"
        visible={!!generatedOrder}
      >
        {generatedOrder ? (
          <>
            <List>
              {generatedOrder.ticket.map((ticket) => (
                <PurchasedTicketItem key={ticket.id} ticket={ticket} />
              ))}
            </List>
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              {mailButton}
            </div>
          </>
        ) : null}
      </Modal>
    </>
  );
};

export default PointOfSaleTicketGeneration;
