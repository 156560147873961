import { CREATE_ORDER, SEND_ORDER_IN_POINT_OF_SALE_BY_MAIL } from './Order';
import { LOGIN, LOGIN_FIREBASE } from '../query/User';
import {
  DELETE_USER,
  RESET_PASSWORD,
  SET_PASSWORD,
  UPDATE_USER,
  CREATE_USER,
  UPSERT_USER_ROLE,
  UPSERT_USER_FIREBASE,
  CREATE_PROFILE_PICTURE,
  UPDATE_PROFILE_PICTURE,
  DELETE_PROFILE_PICTURE,
  UPDATE_MY_PASSWORD,
  CREATE_ASOCIATED_PERSON,
} from './User';
import { UPDATE_SETTING } from './AppSetting';
import { CREATE_ROLE, DELETE_ROLE, UPDATE_ROLE } from './Role';
import { UPSERT_ROLE_PERMISSION } from './RolePermission';
import { REFRESH_TOKEN } from './RefreshToken';
import { UPDATE_PERMISSION } from './Permission';
import {
  DISABLE_TWO_FACTOR_AUTH,
  GENERATE_TOTP_URL,
  TWO_FACTOR_AUTH,
} from './Totp2FA';
import {
  UPSERT_USERTENANT,
  CREATE_TENANT,
  DELETE_TENANT,
  UPDATE_TENANT,
} from './Tenant';
import { CREATE_EVENT, DELETE_EVENT, UPDATE_EVENT } from './Event';
import {
  CREATE_SUBSCRIPTION,
  DELETE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} from './Subscription';
import { CHECK_TICKET } from './Ticket';
import {
  CREATE_ORDER_IN_POINT_OF_SALE,
  CREATE_POINT_OF_SALE,
  DELETE_POINT_OF_SALE,
  UPDATE_POINT_OF_SALE,
} from './PointOfSale';
import { UPSERT_USER_POINT_OF_SALE } from './UserPointOfSale';
import { CREATE_SCAN_LOG } from './ScanLog';

const Mutation = {
  createRole: { name: 'createRole', gql: CREATE_ROLE },
  createUser: { name: 'createUser', gql: CREATE_USER },
  deleteRole: { name: 'deleteRole', gql: DELETE_ROLE },
  deleteUser: { name: 'deleteUser', gql: DELETE_USER },
  createAsociatedPerson: {
    name: 'createAsociatedPerson',
    gql: CREATE_ASOCIATED_PERSON,
  },
  login: { name: 'login', gql: LOGIN },
  loginFirebase: { name: 'loginFirebase', gql: LOGIN_FIREBASE },
  resetPassword: { name: 'resetPassword', gql: RESET_PASSWORD },
  setPassword: { name: 'setPassword', gql: SET_PASSWORD },
  updateAppSetting: { name: 'updateAppSetting', gql: UPDATE_SETTING },
  updatePermission: { name: 'updatePermission', gql: UPDATE_PERMISSION },
  updateRole: { name: 'updateRole', gql: UPDATE_ROLE },
  updateUser: { name: 'updateUser', gql: UPDATE_USER },
  upsertUserRole: { name: 'upsertUserRole', gql: UPSERT_USER_ROLE },
  upsertRolePermission: {
    name: 'upsertRolePermission',
    gql: UPSERT_ROLE_PERMISSION,
  },
  refreshToken: { name: 'refreshToken', gql: REFRESH_TOKEN },
  upsertUserFirebase: { name: 'upsertUserFirebase', gql: UPSERT_USER_FIREBASE },
  createProfilePicture: {
    name: 'createProfilePicture',
    gql: CREATE_PROFILE_PICTURE,
  },
  updateProfilePicture: {
    name: 'updateProfilePicture',
    gql: UPDATE_PROFILE_PICTURE,
  },
  deleteProfilePicture: {
    name: 'deleteProfilePicture',
    gql: DELETE_PROFILE_PICTURE,
  },
  updateMyPassword: {
    name: 'updateMyPassword',
    gql: UPDATE_MY_PASSWORD,
  },
  disableTwoFactorAuth: {
    name: 'disableTwoFactorAuth',
    gql: DISABLE_TWO_FACTOR_AUTH,
  },
  generateTotpUrl: {
    name: 'generateTotpUrl',
    gql: GENERATE_TOTP_URL,
  },
  twoFactorAuth: {
    name: 'twoFactorAuth',
    gql: TWO_FACTOR_AUTH,
  },
  upsertUserTenant: {
    name: 'upsertUserTenant',
    gql: UPSERT_USERTENANT,
  },
  createTenant: {
    name: 'createTenant',
    gql: CREATE_TENANT,
  },
  deleteTenants: {
    name: 'deleteTenants',
    gql: DELETE_TENANT,
  },
  updateTenant: {
    name: 'updateTenant',
    gql: UPDATE_TENANT,
  },
  createEvent: {
    name: 'createEvent',
    gql: CREATE_EVENT,
  },
  deleteEvent: {
    name: 'deleteEvent',
    gql: DELETE_EVENT,
  },
  updateEvent: {
    name: 'updateEvent',
    gql: UPDATE_EVENT,
  },
  createSubscription: {
    name: 'createSubscription',
    gql: CREATE_SUBSCRIPTION,
  },
  deleteSubscription: {
    name: 'deleteSubscription',
    gql: DELETE_SUBSCRIPTION,
  },
  updateSubscription: {
    name: 'updateSubscription',
    gql: UPDATE_SUBSCRIPTION,
  },
  checkTicket: {
    name: 'checkTicket',
    gql: CHECK_TICKET,
  },
  createOrder: {
    name: 'createOrder',
    gql: CREATE_ORDER,
  },
  createPointOfSale: {
    name: 'createPointOfSale',
    gql: CREATE_POINT_OF_SALE,
  },
  updatePointOfSale: {
    name: 'updatePointOfSale',
    gql: UPDATE_POINT_OF_SALE,
  },
  deletePointOfSale: {
    name: 'deletePointOfSale',
    gql: DELETE_POINT_OF_SALE,
  },
  createOrderInPointOfSale: {
    name: 'createOrderInPointOfSale',
    gql: CREATE_ORDER_IN_POINT_OF_SALE,
  },
  sendOrderInPointOfSaleByMail: {
    name: 'sendOrderInPointOfSaleByMail',
    gql: SEND_ORDER_IN_POINT_OF_SALE_BY_MAIL,
  },
  upsertUserPointOfSale: {
    name: 'upsertUserPointOfSale',
    gql: UPSERT_USER_POINT_OF_SALE,
  },
  createScanLog: {
    name: 'createScanLog',
    gql: CREATE_SCAN_LOG,
  },
};

export { Mutation };
