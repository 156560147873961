export const TICKET = `
query ticket($id: Int!) {
  ticket(id: $id) {
    __typename
    ... on Ticket{
      id
      paid_amount
      due_date
      use_quantity
      its
      subscription{
        id
        name
        start_date
        end_date
        event{
          name
        }
      }
      firstname
      lastname
      email
      dni
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const TICKET_PDF = `
query ticketPDF($id: Int!) {
  ticketPDF(id: $id) {
    __typename
    ... on StringResult {
      result
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
