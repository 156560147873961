import { useEffect, useState, useCallback } from 'react';

import lodash from 'lodash';

export interface IRecommendedOption {
  id: number;
  name: string;
  title: string;
}
export interface IDebouncerInputProps {
  id?: string;
  onChange: (value: any) => void;
  placeholder: string;
  minLength: number;
  initialValue?: string;
}

export default function DebouncerInput(props: IDebouncerInputProps) {
  const [value, setValue] = useState<string>('');
  const { onChange, placeholder, minLength, initialValue, id } = props;

  const onSlugChange = useCallback(
    lodash.debounce((val) => {
      onChange(val);
    }, 500),
    [],
  );

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <>
      <input
        id={id ? id : undefined}
        className="ant-input"
        placeholder={placeholder}
        onChange={async (e) => {
          setValue(e.target.value);
          await onSlugChange(e.target.value);
        }}
        minLength={minLength}
        value={value}
      ></input>
    </>
  );
}
