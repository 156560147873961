import { useContext } from 'react';
import { Button, Card, Modal } from 'antd';
import GraphqlService from '../../../services/graphql/GraphqlService';
import { NavContext } from '@ionic/react';
import { IUser } from '../../../interfaces';
import { ContextApp } from '../../../contexts/ContextApp';
import { CustomMessage } from '../../../hooks';
import { EnumsValues } from '../../../enums/EnumsValues';

const Disable2FA = () => {
  // services and hooks
  const { Mutation, customRequest } = GraphqlService();
  const { showMessageError, messageSuccess, messageError } = CustomMessage();

  // contexts
  const { setUser } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);

  const disableConfirmedUser = async () => {
    try {
      const data = await customRequest({
        mutation: Mutation.disableTwoFactorAuth,
      });
      if (data.status) {
        setUser((oldState: IUser) => {
          let newState = JSON.parse(JSON.stringify(oldState));
          newState['two_factor_auth_active'] = false;
          return newState;
        });
        localStorage.setItem(
          EnumsValues.LocalStorageKeys.TwoFactorValidated,
          EnumsValues.TwoFactorValidatedValues.False,
        );
        navigate('/app/home');
        messageSuccess({
          message:
            'Se ha deshabilitado la autenticación en dos pasos exitosamente',
          context: 'Disable2FA.disableConfirmedUser.1',
        });
      } else {
        messageError({
          context: 'Disable2FA.disableConfirmedUser.2',
          message: 'Ocurrió un error al deshabilitar la autenticación',
        });
      }
    } catch (error: any) {
      showMessageError({
        context: 'Disable2FA.disableConfirmedUser.3',
        error,
      });
    }
  };

  const disableAuth = () => {
    Modal.confirm({
      content: (
        <>
          <div>
            ¿Seguro que desea deshabilitar la autenticación en dos pasos?
          </div>
        </>
      ),
      cancelText: 'Cancelar',
      okText: 'Deshabilitar',
      maskClosable: true,
      onOk: () => {
        disableConfirmedUser();
      },
    });
  };

  return (
    <>
      <Card
        title="¿Desea deshabilitar la autenticación en dos pasos?"
        style={{ width: '100%' }}
      >
        <p style={{ marginBottom: '30px' }}>
          La autenticación en dos pasos se encuentra habilitada. Para registrar
          un nuevo dispositivo, primero debe deshabilitar la autenticación
          actual. Si pierde la forma de utilizar sus tokens de acceso puede
          generar otros nuevos, pero se deshabilitarán todos los códigos
          anteriores.
        </p>
        <Button type="primary" danger onClick={disableAuth}>
          Deshabilitar autenticación en dos pasos
        </Button>
      </Card>
    </>
  );
};

export default Disable2FA;
