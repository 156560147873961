import { IEvent } from '../interfaces/Event';
import { IPurchaseConfirmationFormValues } from '../interfaces/IPurchaseConfirmationFormValues';
import { IPurchasedOrderDTO } from '../interfaces/Order';
import { IPointOfSale } from '../interfaces/PointOfSale';
import GraphqlService from './graphql/GraphqlService';

export const PointOfSaleService = () => {
  const { Query, customRequest, Mutation } = GraphqlService();

  const getPointOfSalesForCurrentUser = (): Promise<IPointOfSale[]> => {
    return customRequest({
      query: Query.pointsOfSale,
      variables: {
        filter: {
          only_assigned_to_me: true,
        },
      },
    });
  };

  const fetchPointOfSaleDataById = (id: number): Promise<IPointOfSale> => {
    return customRequest({
      query: Query.pointOfSale,
      variables: {
        id,
      },
    });
  };

  const getPointOfSalePublishedEvents = (
    pointOfSale: IPointOfSale,
  ): Promise<IEvent[]> => {
    return customRequest({
      query: Query.publishedEvents,
      variables: {
        filter: {
          tenant_id: pointOfSale.tenant_id,
          visible_range: [new Date().getTime(), new Date().getTime()],
        },
      },
    });
  };

  const generateOrderInPointOfSale = (
    pointOfSaleId: number,
    input: IPurchaseConfirmationFormValues,
  ): Promise<IPurchasedOrderDTO> => {
    return customRequest({
      mutation: Mutation.createOrderInPointOfSale,
      variables: {
        input: {
          ...input,
          point_of_sale_id: pointOfSaleId,
        },
      },
    });
  };

  const sendOrderInPointOfSaleByMail = async (orderId: number) => {
    return customRequest({
      mutation: Mutation.sendOrderInPointOfSaleByMail,
      variables: {
        id: orderId,
      },
    });
  };

  return {
    getPointOfSalesForCurrentUser,
    fetchPointOfSaleDataById,
    getPointOfSalePublishedEvents,
    generateOrderInPointOfSale,
    sendOrderInPointOfSaleByMail,
  };
};
