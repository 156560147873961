export const CREATE_ORDER = `
mutation   createOrder(    
  $input:  CreateOrderInput!
) {
  createOrder(
    input: $input,
  ) {
    __typename
    ... on OrderWithPreferenceId {
      id
      person_id
      order_status_id
      person{
        email
      }
      ticket{
        id
      }
      preference_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const SEND_ORDER_IN_POINT_OF_SALE_BY_MAIL = `
mutation sendOrderInPointOfSaleByMail($id: Int!) {
  sendOrderInPointOfSaleByMail(id: $id) {
    __typename
    ... on BooleanResult {
      result
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
