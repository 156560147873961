import printJs from 'print-js';

export const downloadFile = (
  base64: string,
  contentType: string,
  filename: string,
) => {
  const a = document.createElement('a'); //Create <a>
  a.href = `data:${contentType};base64,` + base64;
  a.download = filename;
  a.click();
  a.remove();
};

export const printBase64 = (base64: string) => {
  printJs({
    printable: base64,
    base64: true,
    type: 'pdf',
  });
};
