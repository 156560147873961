import { createContext } from 'react';

interface IPublicContext {
  navbarText: string;
  setNavbarText: Function;
  navbarColor: string;
  setNavbarColor: Function;
  subscriptionId: number;
  setSubscriptionId: Function;
  quantity: number;
  setQuantity: Function;
}

const context: IPublicContext = {
  navbarText: '',
  setNavbarText: () => {},
  navbarColor: '',
  setNavbarColor: () => {},
  subscriptionId: 0,
  setSubscriptionId: () => {},
  quantity: 0,
  setQuantity: () => {},
};

export const PublicContext = createContext(context);
