export const GET_AUTHENTICATION_LINK = `
query getAuthenticationLink {
  getAuthenticationLink {
    __typename
    ... on AuthenticationLinkResponse {
      authenticationLink
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
