import { PageLoading } from '@ant-design/pro-layout';
import { Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import BackButton from '../../components/common/BackButton/BackButton';
import PointOfSaleEventList from '../../components/PointOfSale/PointOfSaleEventList';
import { PointOfSaleContext } from '../../contexts/PointOfSaleContext';
import { IEvent } from '../../interfaces/Event';
import { IPointOfSale } from '../../interfaces/PointOfSale';
import { PointOfSaleService } from '../../services/PointOfSale.service';

function SelectEventInPointOfSale() {
  const { pointOfSale } = useContext(PointOfSaleContext);
  const [pointOfSaleData, setPointOfSaleData] = useState<IPointOfSale>();
  const [loadingPointOfSaleData, setLoadingPointOfSaleData] = useState(true);
  const { setEventSelected } = useContext(PointOfSaleContext);
  const history = useHistory();

  const { fetchPointOfSaleDataById } = PointOfSaleService();

  const getPointOfSaleData = async (id: number) => {
    setLoadingPointOfSaleData(true);
    try {
      const result = await fetchPointOfSaleDataById(id);
      setPointOfSaleData(result);
    } catch (error) {
      /* Intencional, lo manejan los estados de la pagina */
      console.error(error);
    }
    setLoadingPointOfSaleData(false);
  };

  useEffect(() => {
    if (pointOfSale) getPointOfSaleData(pointOfSale.id);
  }, []);

  if (!pointOfSale) {
    history.replace('/app/pointofsale/list');
  }

  if (loadingPointOfSaleData) {
    return <PageLoading />;
  }

  if (!pointOfSaleData) {
    return null;
  }

  const onEventClick = (event: IEvent) => {
    setEventSelected(event);
    history.push('/app/pointofsale/event');
  };

  return (
    <>
      <BackButton />
      <Typography.Title level={4}>Seleccione un evento</Typography.Title>
      <PointOfSaleEventList
        pointOfSale={pointOfSaleData}
        onCardClick={onEventClick}
      />
    </>
  );
}

export default SelectEventInPointOfSale;
