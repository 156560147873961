import { Card, List, Select, Button } from 'antd';
import moment from 'moment';
import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import BackButton from '../../components/common/BackButton/BackButton';
import { PointOfSaleContext } from '../../contexts/PointOfSaleContext';
import { IEvent } from '../../interfaces/Event';
import { ISubscription } from '../../interfaces/Subscription';
import SubscriptionService from '../../services/Subscription.service';
import { formatMoney } from '../../shared/formatMoney';
import { FORMAT_DATE_TIME_1 } from '../../shared/MomentJS';

const SubscriptionCard = ({
  sub,
  event,
  onButtonClick,
}: {
  sub: ISubscription;
  event: IEvent;
  onButtonClick: (params: {
    quantity: number;
    subscription: ISubscription;
  }) => void;
}) => {
  const [quantity, setQuantity] = useState<number | undefined>(
    sub.quantity === 0 ? undefined : 1,
  );
  return (
    <Card title={sub.name} style={{ minWidth: '100%', borderRadius: 8 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="">
          <div className="">
            Ubicación: {sub.ubication || event.ubication || 'No hay ubicación'}
          </div>
          <div className="">Tickets disponibles: {sub.quantity}</div>
          <div className="">Precio: {formatMoney(sub.price)}</div>
          <div className="">
            Fecha y hora de inicio:{' '}
            {moment(sub.start_date).format(FORMAT_DATE_TIME_1)}
          </div>
          <div className="">
            Fecha y hora de fin:{' '}
            {moment(sub.end_date).format(FORMAT_DATE_TIME_1)}
          </div>
        </div>
        <div style={{ marginLeft: 8, textAlign: 'right' }}>
          <div style={{ marginBottom: 6 }}>
            <Select
              style={{ minWidth: '100%', textAlign: 'left' }}
              placeholder="Seleccione cantidad"
              options={new Array(Math.min(10, sub.quantity))
                .fill(0)
                .map((_v, i) => ({
                  value: i + 1,
                  label: i + 1 === 1 ? '1 ticket' : `${i + 1} tickets`,
                }))}
              value={quantity}
              disabled={sub.quantity === 0}
              onChange={(value) => setQuantity(value)}
            />
          </div>
          <Button
            onClick={() => {
              onButtonClick({
                quantity: quantity || 0,
                subscription: sub,
              });
            }}
            disabled={!quantity}
            type="primary"
          >
            Generar tickets
          </Button>
        </div>
      </div>
    </Card>
  );
};

const PointOfSaleEventPage = () => {
  const { eventSelected, setTicketQuantity, setSubscription } =
    useContext(PointOfSaleContext);
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const { getEventSubscriptions } = SubscriptionService();
  const history = useHistory();

  useEffect(() => {
    if (eventSelected) {
      getEventSubscriptions(eventSelected.id)
        .then(setSubscriptions)
        .catch(console.error);
    }
  }, [eventSelected]);

  if (!eventSelected) {
    return history.replace('/app/pointofsale/eventSelection');
  }

  const onSubSelected = ({
    quantity,
    subscription,
  }: {
    quantity: number;
    subscription: ISubscription;
  }) => {
    setSubscription(subscription);
    setTicketQuantity(quantity);
    history.push('/app/pointofsale/ticketGeneration');
  };

  return (
    <>
      <BackButton />
      <List size="small">
        {subscriptions.map((sub) => {
          return (
            <List.Item>
              <SubscriptionCard
                sub={sub}
                event={eventSelected}
                onButtonClick={onSubSelected}
              />
            </List.Item>
          );
        })}
      </List>
    </>
  );
};

export default PointOfSaleEventPage;
