import { print } from 'graphql';
import {
  USERS,
  AUTH,
  LOGOUT,
  RESET_PASSWORD,
  VERIFY_RECOVERY_PASSWORD_TOKEN,
  USERS_COUNT_BY_ROLE,
  USERS_COUNT,
  GET_PROFILE_PICTURE,
  GET_PROFILE_FILE,
} from './User';
import { SETTINGS, SETTING_KEY } from './AppSetting';
import { LANGUAGES } from './Language';
import { ROLE, ROLES } from './Role';
import { PERMISSIONS } from './Permission';
import { ROLE_PERMISSION, ROLE_PERMISSIONS } from './RolePermission';
import {
  TENANT,
  TENANTS,
  TENANT_COUNT,
  USER_TENANT,
  GET_TENANT_PICTURE,
} from './Tenant';
import {
  PUBLIC_EVENT,
  EVENT,
  EVENTS,
  EVENT_COUNT,
  PUBLISHED_EVENTS,
} from './Event';
import {
  PUBLIC_SUBSCRIPTION,
  PUBLISHED_SUBSCRIPTIONS,
  SUBSCRIPTION,
  SUBSCRIPTIONS,
  SUBSCRIPTION_COUNT,
  TICKET_COUNT,
} from './Subscription';
import { EVENT_PICTURE, EVENT_PICTURE_LINK } from './EventPicture';
import { GET_AUTHENTICATION_LINK } from './MercadoPago';
import { TICKET, TICKET_PDF } from './Ticket';
import {
  POINTS_OF_SALE,
  POINT_OF_SALE,
  POINT_OF_SALE_COUNT,
} from './PointOfSale';
import {
  GET_USERS_CANDIDATES_FOR_POINT_OF_SALE,
  USER_POINTS_OF_SALE,
} from './UserPointOfSale';

const Query = {
  authenticate: { name: 'authenticate', gql: AUTH },
  getAppSettingByKey: { name: 'getAppSettingByKey', gql: SETTING_KEY },
  getAppSettings: { name: 'getAppSettings', gql: SETTINGS },
  languages: { name: 'languages', gql: print(LANGUAGES) },
  logout: { name: 'logout', gql: LOGOUT },
  permissions: { name: 'permissions', gql: PERMISSIONS },
  resetPassword: { name: 'resetPassword', gql: RESET_PASSWORD },
  role: { name: 'role', gql: ROLE },
  roles: { name: 'roles', gql: ROLES },
  rolePermission: { name: 'rolePermission', gql: ROLE_PERMISSION },
  rolePermissions: { name: 'rolePermissions', gql: ROLE_PERMISSIONS },
  users: { name: 'users', gql: USERS },
  userCount: { name: 'userCount', gql: USERS_COUNT },
  userCountByRole: { name: 'userCountByRole', gql: USERS_COUNT_BY_ROLE },
  verifyRecoveryPasswordToken: {
    name: 'verifyRecoveryPasswordToken',
    gql: VERIFY_RECOVERY_PASSWORD_TOKEN,
  },
  getProfilePicture: { name: 'getProfilePicture', gql: GET_PROFILE_PICTURE },
  getProfileFile: { name: 'getProfileFile', gql: GET_PROFILE_FILE },
  tenants: { name: 'tenants', gql: TENANTS },
  findManyUserTenants: { name: 'findManyUserTenants', gql: USER_TENANT },
  tenant: { name: 'tenant', gql: TENANT },
  tenantCount: { name: 'tenantCount', gql: TENANT_COUNT },
  events: { name: 'events', gql: EVENTS },
  publishedEvents: { name: 'publishedEvents', gql: PUBLISHED_EVENTS },
  event: { name: 'event', gql: EVENT },
  publicEvent: { name: 'publicEvent', gql: PUBLIC_EVENT },
  eventCount: { name: 'eventCount', gql: EVENT_COUNT },
  subscription: { name: 'subscription', gql: SUBSCRIPTION },
  publicSubscription: { name: 'publicSubscription', gql: PUBLIC_SUBSCRIPTION },
  subscriptions: { name: 'subscriptions', gql: SUBSCRIPTIONS },
  subscriptionCount: { name: 'subscriptionCount', gql: SUBSCRIPTION_COUNT },
  ticketCount: { name: 'ticketCount', gql: TICKET_COUNT },
  publishedSubscriptions: {
    name: 'publishedSubscriptions',
    gql: PUBLISHED_SUBSCRIPTIONS,
  },
  eventPicture: {
    name: 'eventPicture',
    gql: EVENT_PICTURE,
  },
  eventPictureLink: {
    name: 'eventPictureLink',
    gql: EVENT_PICTURE_LINK,
  },
  getTenantPicture: {
    name: 'getTenantPicture',
    gql: GET_TENANT_PICTURE,
  },
  getAuthenticationLink: {
    name: 'getAuthenticationLink',
    gql: GET_AUTHENTICATION_LINK,
  },
  ticket: { name: 'ticket', gql: TICKET },
  pointsOfSale: {
    name: 'pointsOfSale',
    gql: POINTS_OF_SALE,
  },
  pointOfSaleCount: {
    name: 'pointOfSaleCount',
    gql: POINT_OF_SALE_COUNT,
  },
  pointOfSale: {
    name: 'pointOfSale',
    gql: POINT_OF_SALE,
  },
  ticketPDF: {
    name: 'ticketPDF',
    gql: TICKET_PDF,
  },
  userPointsOfSale: {
    name: 'userPointsOfSale',
    gql: USER_POINTS_OF_SALE,
  },
  getUsersCandidatesForPointOfSale: {
    name: 'getUsersCandidatesForPointOfSale',
    gql: GET_USERS_CANDIDATES_FOR_POINT_OF_SALE,
  },
};

export { Query };
