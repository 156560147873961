import GraphqlService from './graphql/GraphqlService';

const EventService = () => {
  const { Query, customRequest } = GraphqlService();

  const getLinkEventPicture = async (
    event_picture_id: number,
  ): Promise<string | undefined> => {
    if (!event_picture_id) return;
    try {
      const data = await customRequest({
        query: Query.eventPictureLink,
        variables: { id: event_picture_id },
      });
      return data.result;
    } catch (error: any) {
      console.error(error);
    }
  };

  return {
    getLinkEventPicture,
  };
};

export default EventService;
