import { Card } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import './Status2FA.less';

interface IStatus2FA {
  status: boolean | undefined;
}

const Status2FA = ({ status }: IStatus2FA) => {
  return (
    <>
      <Card size="small" title="Autenticación 2FA" className="status2fa__card">
        {status ? (
          <>
            <p className="status2fa-texts">Activada</p>
            <CheckCircleOutlined style={{ color: 'green' }} />
          </>
        ) : (
          <>
            <p className="status2fa-texts">Desactivada</p>
            <CloseCircleOutlined style={{ color: 'red' }} />
          </>
        )}
      </Card>
    </>
  );
};

export default Status2FA;
