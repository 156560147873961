export const EVENT_PICTURE = `
query eventPicture($id: Int!){
  eventPicture(id: $id) {
    __typename
    ... on EventPicture{
      id
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;

export const EVENT_PICTURE_LINK = `
query eventPictureLink($id: Int!){
  eventPictureLink(id: $id) {
    __typename
    ... on StringResult{
      result
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;
