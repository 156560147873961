export const CHECK_TICKET = `
  mutation checkTicket($id: Int!) {
  checkTicket(id: $id) {
    __typename
    ... on Ticket{
      id
      paid_amount
      due_date
      use_quantity
      its
      firstname
      lastname
      email
      dni
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
