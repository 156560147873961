import { NavContext } from '@ionic/react';
import { Button, Result } from 'antd';
import { useContext } from 'react';

interface NotFound404Props {
  title?: string;
  subtitle: string;
  hideButton?: boolean;
  messageButton?: string;
  onClickButton?: () => void;
}

export const NotFound404 = (props: NotFound404Props) => {
  const { navigate } = useContext(NavContext);
  const { title, subtitle, onClickButton, messageButton, hideButton } = props;
  return (
    <>
      <Result
        status="404"
        title={title || '404'}
        subTitle={subtitle}
        extra={
          !hideButton ? (
            <Button
              onClick={() => {
                if (onClickButton) {
                  onClickButton();
                } else {
                  navigate('/');
                }
              }}
              type="primary"
            >
              {messageButton || 'Ir al inicio'}
            </Button>
          ) : undefined
        }
      />
    </>
  );
};
