import { Card, Image } from 'antd';
import Meta from 'antd/lib/card/Meta';
import moment from 'moment';
import 'moment/locale/es';
import { ReactNode } from 'react';
import imageNotFound from '../../../assets/imageNotFound.jpg';
import code from '../../../assets/code.png';
import './eventCardSummary.less';

interface IEventCardSummaryProps {
  quantity: number;
  image: string;
  date?: Date;
  ubication?: string;
  onCardClick?: () => void;
  price?: number | false;
  color?: string;
  label?: string | ReactNode;
}

/**
 * @param quantity (number)
 * @param image (string - ideal size: 300x150)
 * @param date (Date) --OPTIONAL
 * @param ubication (string) --OPTIONAL
 * @param onCardClick (function) --OPTIONAL
 * @param price (default: true) --OPTIONAL -- 0 or undefined --> shows "GRATIS" - false shows nothing
 * @param color (string - default:#6981d1db) --OPTIONAL
 * @param label (string | ReactNode) --OPTIONAL
 */
export const EventCardSummary = (props: IEventCardSummaryProps) => {
  const { price, image, date, quantity, ubication, onCardClick } = props;
  return (
    <>
      <Card
        hoverable
        className={`event-card-summary ${
          !onCardClick && 'event-card-summary--cursor-default'
        }`}
        onClick={onCardClick}
        cover={
          <>
            <Image
              alt="example"
              src={image}
              preview={false}
              className="event-card-summary__image"
              fallback={imageNotFound}
            />
          </>
        }
      >
        <Meta
          title={
            <>
              <div className="event-card-summary__meta-container-title">
                Cantidad:
                <br />
                <span className="event-card-summary__meta-container-title__label">
                  {quantity}
                </span>
              </div>
              {ubication && (
                <div className="event-card-summary__meta-container-title">
                  Ubicación:
                  <br />
                  <span className="event-card-summary__meta-container-title__label">
                    {ubication}
                  </span>
                </div>
              )}
              {date && (
                <div className="event-card-summary__meta-container-title">
                  {moment(date).format('dddd').toUpperCase()}
                  <br />
                  <span className="event-card-summary__meta-container-title__label">
                    {moment(date).format('DD')} de {moment(date).format('MMMM')}{' '}
                    {moment(date).format('HH:mm')} hs
                  </span>
                </div>
              )}
              <div className="event-card-summary__meta-container-title">
                Total:
                <br />
                <span className="event-card-summary__meta-container-title__label">
                  {price !== false && (
                    <div className="event-card-summary__price">
                      {price ? `$${price}` : 'Gratis'}
                    </div>
                  )}
                </span>
              </div>
            </>
          }
          className="event-card-summary__body"
        />
        <span>
          <hr style={{ borderTop: 'dashed 3px' }} />
        </span>
        <div>
          <Image
            alt="barcode"
            src={code}
            preview={false}
            className="event-card-summary__code"
          />
        </div>
      </Card>
    </>
  );
};
