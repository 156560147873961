import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import ProTable, { ActionType } from '@ant-design/pro-table';
import moment from 'moment';
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Switch,
  Tooltip,
} from 'antd';
import {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { SorterResult } from 'antd/lib/table/interface';
import './SubscriptionPage.less';
import { ExporterDropdown, SaveForm } from '../../components/common/ABM';
import { ExportableColumn, ExportableTable } from '../../shared/Exporter';
import GraphqlService from '../../services/graphql/GraphqlService';
import {
  ABM,
  Authorization,
  MomentJS,
  Tools,
  tooltipTrigger,
} from '../../shared';
import { showCollapseRender } from '../../shared/showCollapseRender';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import { ParamsType } from '@ant-design/pro-provider';
import { getPaginationArgs } from '../../shared/getPaginationArgs';
import MomentTimezoneService from '../../services/moment-timezone/MomentTimezoneService';
import { IEvent } from '../../interfaces/Event';
import { ITenant } from '../../interfaces/Tenant';
import {
  ISubscription,
  ISubscriptionCreateInput,
  IUpdateFormValues,
  ISubscriptionUpdate,
} from '../../interfaces/Subscription';
import { formatMoney } from '../../shared/formatMoney';
import { formItemPropsMandatoryMessage } from '../../shared/formItemPropsMandatoryMessage';
import { genericDatePicker } from '../../shared/genericDatePicker';
import { EventCard } from '../../components/common/EventCard/EventCard';
import { FORMAT_DATE_TIME_1, FORMAT_DATE_TIME_4 } from '../../shared/MomentJS';
import { ICount } from '../../interfaces';

const { TextArea } = Input;

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = [
  'name',
  'event_id',
  'published',
  'description',
  'card_description',
  'price',
  'max_price',
  'min_price',
  'quantity',
  'visible_range',
  'dates_range',
];
const LIST_SORTER = [
  'name',
  'its',
  'uts',
  'visible_from',
  'visible_to',
  'price',
  'quantity',
  'start_date',
  'end_date',
];

/**
 * Se configura por cada ABM diferente
 */
const TITLE_PRO_TABLE = 'Formulario de consulta';
const TITLE_CREATE_FORM = 'Formulario de alta';
const TITLE_UPDATE_FORM = 'Editar Suscripción';
const TITLE_CHANGE_REASON_FORM =
  'Indicar motivo de modificación en suscripción';
const INPUT_SEARCH_PLACEHOLDER = 'Buscar...';

export default function SubscriptionPage() {
  const [searchText, setSearchText] = useState('');
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [changeReasonModalVisible, setChangeReasonModalVisible] =
    useState<boolean>(false);
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] =
    useState<ISubscription | undefined>(undefined);
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>('');
  const [changeReason, setChangeReason] = useState<string>('');
  const [dataTable, setDataTable] = useState<ISubscription[]>([]);
  const [eventsCombo, setEventsCombo] = useState<IEvent[]>([]);
  const [tenantCombo, setTenantCombo] = useState<ITenant[]>([]);
  const [published, setPublished] = useState(false);
  const [previsualizedSubscriptionId, setPrevisualizedSubscriptionId] =
    useState<number>();
  // services and hooks
  const { getDateWithTime } = MomentTimezoneService();
  const { mobileOnSizeChangeProTable, changeView, showComponent } =
    useProTableForMobile({
      layout: 'horizontal',
    });
  const { Query, Mutation, customRequest } = GraphqlService();

  const {
    messageError,
    messageDeleteSuccess,
    messageDeleting,
    messageUpdating,
    messageUpdateSuccess,
    messageUpdateError,
    messageCreating,
    messageCreateSuccess,
    messageCreateError,
  } = CustomMessage();
  const askForReason = useRef<boolean>(false);
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  // contexts
  const { functions, user } = useContext(ContextApp);

  // methods

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const getEventsForCombo = async () => {
    try {
      const data: IEvent[] = await customRequest({
        query: Query.events,
      });
      setEventsCombo(data);
    } catch {
      // Intentional
    }
  };

  const getTenantsForCombo = async () => {
    try {
      const data: ITenant[] = await customRequest({
        query: Query.tenants,
      });
      setTenantCombo(data);
    } catch {
      // Intentional
    }
  };
  const haveTickets = async (subscriptionId: number) => {
    try {
      const count: ICount = await customRequest({
        query: Query.ticketCount,
        variables: { subscriptionId },
      });
      askForReason.current = count.count !== 0;
    } catch {
      // Intentional
    }
  };

  useEffect(() => {
    if (user) {
      getEventsForCombo();
      getTenantsForCombo();
    }
  }, [user]);

  const cleanupState = () => {
    setPublished(false);
  };

  useEffect(() => {
    if (!createModalVisible && !updateModalVisible) {
      cleanupState();
    }
  }, [createModalVisible, updateModalVisible]);

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    },
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search = ABM.valuesResult(params);
      if (searchText) {
        variables.current.searchText = searchText;
      } else {
        delete variables.current.searchText;
      }

      LIST_FILTER.forEach((element) => {
        try {
          if (!variables.current.filter) {
            variables.current.filter = {};
          }
          if (search[element]) {
            variables.current.filter[element] = search[element];

            if (['visible_range', 'dates_range'].includes(element)) {
              const newValues = Tools.fullDaysInRange({
                minDate: search[element][0],
                maxDate: search[element][1],
              });
              variables.current.filter[element] = [
                newValues.minDate,
                newValues.maxDate,
              ];
            }

            if (variables.current.filter[element] === 'true') {
              variables.current.filter[element] = true;
            } else if (variables.current.filter[element] === 'false') {
              variables.current.filter[element] = false;
            }
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      if (
        variables.current.filter?.min_price !== undefined &&
        variables.current.filter?.max_price !== undefined
      ) {
        variables.current.filter['price'] = [
          variables.current.filter.min_price,
          variables.current.filter.max_price,
        ];
        delete variables.current.filter['min_price'];
        delete variables.current.filter['max_price'];
      }

      LIST_SORTER.forEach((element) => {
        try {
          if (search.sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search.sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      const countPromise = customRequest({
        query: Query.subscriptionCount,
        variables: variables.current,
      }).then((response: { count: number }) => response.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current,
      );
      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = customRequest({
        query: Query.subscriptions,
        variables: variables.current,
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);
      setDataTable(data);
      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const createSubscription = async (value: ISubscriptionCreateInput) => {
    if (value.ubication_inherited) value.ubication = undefined;
    delete value.ubication_inherited;

    if (!value.price) value.price = 0;

    setFormLoading(true);
    messageCreating({
      context: 'SubscriptionPage.createSubscription.1',
      message: 'Suscripción',
    });
    try {
      await customRequest({
        mutation: Mutation.createSubscription,
        variables: {
          input: { ...value, published: published },
        },
      });
      setCreateModalVisible(false);
      messageCreateSuccess({
        context: 'SubscriptionPage.createSubscription.2',
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        return messageError({
          context: 'SubscriptionPage.createSubscription.3',
          message: error.message,
        });
      }
      messageCreateError({ context: 'SubscriptionPage.createSubscription.3' });
    }
    setFormLoading(false);
  };

  const updateSubscription = async (value: IUpdateFormValues) => {
    if (!editForm) return;

    if (value.ubication_inherited) value.ubication = null;
    delete value.ubication_inherited;

    if (!value.price) value.price = 0;

    setFormLoading(true);
    messageUpdating({
      context: 'SubscriptionPage.updateSubscription.1',
      message: 'Suscripción',
    });
    try {
      const updateSubscriptionVariables: ISubscriptionUpdate = {
        id: editForm.id,
        input: {
          ...value,
          change_reason: changeReason,
          published: published,
        },
      };

      await customRequest({
        mutation: Mutation.updateSubscription,
        variables: updateSubscriptionVariables,
      });
      messageUpdateSuccess({
        context: 'SubscriptionPage.updateSubscription.2',
      });
      askForReason.current = false;
      setChangeReason('');
      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        setFormLoading(false);
        return messageError({
          context: 'SubscriptionPage.updateSubscription.3',
          message: error.message,
        });
      }
      messageUpdateError({ context: 'SubscriptionPage.updateSubscription.3' });
    }
    setFormLoading(false);
  };

  const removeConfirmedSubscription = async (value: {
    id: number;
    name: string;
  }) => {
    messageDeleting({
      context: 'SubscriptionPage.removeConfirmedSubscription.1',
      message: 'Suscripción',
    });
    try {
      await customRequest({
        mutation: Mutation.deleteSubscription,
        variables: {
          id: value.id,
          change_reason: askForReason.current ? changeReason : undefined,
        },
      });
      messageDeleteSuccess({
        context: 'SubscriptionPage.removeConfirmedSubscription.2',
      });
      askForReason.current = false;
      setChangeReason('');
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      messageError({
        context: 'SubscriptionPage.removeConfirmedSubscription.3',
        message: error.message,
      });
    }
  };

  const removeSubscription = (value: { id: number; name: string }) => {
    Modal.confirm({
      content: (
        <>
          <div>¿Seguro que desea eliminar la suscripción {value.name}?</div>
        </>
      ),
      cancelText: 'Cancelar',
      okText: 'Aceptar',
      onOk: async () => {
        await haveTickets(value.id);
        if (askForReason.current) {
          setChangeReasonModalVisible(true);
        } else removeConfirmedSubscription(value);
      },
    });
  };

  const columns = useCallback(
    (
      editMode: boolean,
      createMode: boolean = false,
    ): ExportableColumn<ISubscription>[] => [
      {
        export: false,
        dataIndex: 'id',
        title: 'Id',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: 'name',
        title: 'Nombre',
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: formItemPropsMandatoryMessage(
          'El nombre es obligatorio',
        ),
        render: (_, { name }) => name || '-',
        renderFormItem: () => (
          <Input placeholder="Ingrese nombre" minLength={4} />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: 'event_id',
        title: 'Evento',
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
        formItemProps: formItemPropsMandatoryMessage(
          'El evento es obligatorio',
        ),
        render: (_, record) => record.event?.name || '-',
        renderFormItem: (_, __, formInstance) => (
          <Select
            options={tenantCombo.map((tenant) => ({
              label: tenant.name,
              options: eventsCombo
                .filter((event) => event.tenant_id === tenant.id)
                .map((event) => ({
                  label: event.name,
                  value:
                    editMode || createMode ? event.id.toString() : event.id,
                })),
            }))}
            placeholder="Seleccione evento..."
            allowClear
            showSearch
            optionFilterProp="label"
            onClear={() => {
              formInstance.setFieldsValue({
                ubication: editMode ? editForm?.ubication : null,
                ubication_inherited: false,
              });
            }}
            onSelect={(value: string) => {
              const event = eventsCombo.find(
                (event) => event.id === parseInt(value),
              );
              const values = formInstance.getFieldsValue();
              if (editMode || createMode)
                formInstance.setFieldsValue({
                  ubication: event?.ubication,
                  ubication_inherited: true,
                  card_description:
                    values.card_description || event?.card_description,
                  description: values.description || event?.description,
                  visible_from:
                    values.visible_from ||
                    (event?.visible_from
                      ? moment(new Date(event.visible_from))
                      : undefined),
                  visible_to:
                    values.visible_to ||
                    (event?.visible_to
                      ? moment(new Date(event.visible_to))
                      : undefined),
                });
            }}
          ></Select>
        ),
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: 'card_description',
        title: 'Descripción corta',
        type: ABM.TYPE_COLUMN.STRING,
        render: (_, { card_description }) => card_description || '-',
        renderFormItem: () => {
          if (editMode || createMode) {
            return (
              <TextArea
                placeholder="Ingrese descripción"
                showCount
                maxLength={120}
                autoSize
              />
            );
          } else {
            return <Input placeholder="Ingrese descripción" />;
          }
        },
        align: 'left',
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'description',
        title: 'Descripción',
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: formItemPropsMandatoryMessage(
          'La descripción es obligatoria',
        ),
        render: (_, { description }) => description || '-',
        renderFormItem: () => {
          if (editMode || createMode) {
            return <TextArea placeholder="Ingrese descripción" autoSize />;
          } else {
            return <Input placeholder="Ingrese descripción" />;
          }
        },
        align: 'left',
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'ubication',
        title: 'Ubicación',
        type: ABM.TYPE_COLUMN.STRING,
        render: (_, record) =>
          record.ubication || record.event?.ubication || '-',
        formItemProps: {
          rules: [
            ({ getFieldValue }) => ({
              validator() {
                if (
                  !getFieldValue('ubication') &&
                  !getFieldValue('ubication_inherited')
                ) {
                  return Promise.reject(new Error('La ubicación es requerida'));
                }
                return Promise.resolve();
              },
            }),
          ],
        },
        renderFormItem: (_, __, formInstance) => {
          const selectedEventId = parseInt(
            formInstance.getFieldValue('event_id'),
          );
          const selectedEventUbication = selectedEventId
            ? eventsCombo.find((event) => event.id === selectedEventId)
                ?.ubication
            : undefined;
          return (
            <>
              <Form.Item name="ubication" className="inner_form_item">
                <Input
                  name="ubication"
                  placeholder="Ingrese ubicación"
                  minLength={4}
                  disabled={!!formInstance.getFieldValue('ubication_inherited')}
                />
              </Form.Item>
              <Form.Item
                name="ubication_inherited"
                valuePropName="checked"
                label="misma ubicación que el evento"
                className="inner_form_item form_item_checkbox"
              >
                <Switch
                  onChange={(checked) => {
                    if (createMode) {
                      formInstance.setFieldsValue({
                        ubication: checked ? selectedEventUbication : null,
                      });
                    }
                    if (editMode) {
                      formInstance.setFieldsValue({
                        ubication: checked
                          ? selectedEventUbication
                          : editForm?.ubication,
                      });
                    }
                  }}
                />
              </Form.Item>
            </>
          );
        },
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'published',
        title: 'Publicado',
        type: ABM.TYPE_COLUMN.BOOLEAN,
        render: (_, { published: isPublished }) =>
          isPublished ? 'Si' : 'No' || '-',
        renderFormItem: () => {
          if (editMode || createMode) {
            return <Switch onChange={setPublished} checked={published} />;
          } else {
            return (
              <Select
                options={[
                  {
                    label: 'Si',
                    value: 'true',
                  },
                  {
                    label: 'No',
                    value: 'false',
                  },
                ]}
                placeholder="Seleccione si o no..."
                allowClear
                showSearch
              />
            );
          }
        },
        align: 'left',
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'price',
        title: 'Precio',
        type: ABM.TYPE_COLUMN.NUMBER,
        formItemProps: formItemPropsMandatoryMessage(
          'El precio es obligatorio',
        ),
        render: (_, { price }) => formatMoney(price) || '-',
        renderFormItem: () => {
          return (
            <InputNumber
              placeholder="Ingrese precio"
              controls={false}
              decimalSeparator={','}
              precision={2}
              formatter={(value) => `$ ${value}`}
              parser={(value) => Number(value!.replace(/\$\s?/g, ''))}
            />
          );
        },
        align: 'right',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: 'min_price',
        title: 'Precio minimo',
        type: ABM.TYPE_COLUMN.NUMBER,
        renderFormItem: () => {
          return (
            <InputNumber
              prefix="$"
              placeholder="Ingrese precio"
              decimalSeparator={','}
              precision={2}
            />
          );
        },
        align: 'right',
        sorter: true,
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: 'max_price',
        title: 'Precio máximo',
        type: ABM.TYPE_COLUMN.NUMBER,
        renderFormItem: () => {
          return (
            <InputNumber
              prefix="$"
              placeholder="Ingrese precio"
              decimalSeparator={','}
              precision={2}
            />
          );
        },
        align: 'right',
        sorter: true,
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'quantity',
        title: 'Cantidad',
        type: ABM.TYPE_COLUMN.NUMBER,
        formItemProps: formItemPropsMandatoryMessage(
          'La cantidad es obligatoria',
        ),
        render: (_, { quantity }) => quantity || '-',
        renderFormItem: () => (
          <InputNumber placeholder="Ingrese cantidad" controls={false} />
        ),
        align: 'right',
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'start_date',
        title: 'Fecha de inicio',
        type: ABM.TYPE_COLUMN.DATE,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'La fecha es obligatoria',
            },
            {
              validator: (_rule, value) => {
                const minDate = moment(editMode ? editForm?.its : undefined);
                const startDate = moment(value);
                if (startDate.isBefore(minDate)) {
                  return Promise.reject(
                    `La fecha debe ser posterior a la hora ${
                      editMode ? 'de creación' : 'actual'
                    }`,
                  );
                }
                return Promise.resolve();
              },
            },
          ],
        },
        render: (_, { start_date }) =>
          start_date ? getDateWithTime({ element: start_date }) : '-',
        renderFormItem: (_, __, formInstance) => (
          <DatePicker
            format={[FORMAT_DATE_TIME_1]}
            placeholder="Seleccione fecha"
            showTime={{
              showHour: true,
              showMinute: true,
            }}
            allowClear={false}
            onChange={(value: any) => {
              formInstance.setFieldsValue({ start_date: value });
              formInstance.validateFields([
                'start_date',
                'end_date',
                'visible_from',
              ]);
            }}
            inputReadOnly
          />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'end_date',
        title: 'Fecha de fin',
        type: ABM.TYPE_COLUMN.DATE,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'La fecha es obligatoria',
            },
            ({ getFieldValue }) => {
              return {
                validator: (_rule, value) => {
                  const minDate = moment(editMode ? editForm?.its : new Date());
                  const startDateValue = moment(getFieldValue('start_date'));
                  const startDate = moment(startDateValue);
                  const endDate = moment(new Date(value));
                  if (startDateValue && endDate.isBefore(startDate)) {
                    return Promise.reject(
                      'La fecha de fin debe ser posterior a la fecha de inicio',
                    );
                  }
                  if (endDate.isBefore(minDate)) {
                    return Promise.reject(
                      `La fecha debe ser posterior a la hora ${
                        editMode ? 'de creación' : 'actual'
                      }`,
                    );
                  }
                  return Promise.resolve();
                },
              };
            },
          ],
        },
        render: (_, { end_date }) =>
          end_date ? getDateWithTime({ element: end_date }) : '-',
        renderFormItem: (_, __, formInstance) => (
          <DatePicker
            format={[FORMAT_DATE_TIME_1]}
            placeholder="Seleccione fecha"
            showTime={{
              showHour: true,
              showMinute: true,
            }}
            allowClear={false}
            onChange={(value: any) => {
              formInstance.setFieldsValue({ end_date: value });
              formInstance.validateFields([
                'end_date',
                'visible_from',
                'visible_to',
              ]);
            }}
            inputReadOnly
          />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: 'dates_range',
        title: 'Fecha',
        initialValue: [
          moment(new Date()),
          moment(new Date().setMonth(new Date().getMonth() + 1)),
        ],
        renderFormItem: () => (
          <DatePicker.RangePicker
            style={{ height: 32 }}
            name="dates_range"
            format={FORMAT_DATE_TIME_4}
            placeholder={['Fecha desde', 'Fecha hasta']}
            allowEmpty={[true, true]}
            className="renderFormItem"
            inputReadOnly
            allowClear
          />
        ),
        align: 'left',
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'visible_from',
        title: 'Visible desde',
        type: ABM.TYPE_COLUMN.DATE,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'La fecha es obligatoria',
            },
            ({ getFieldValue }) => {
              return {
                validator: (_rule, value) => {
                  const minDate = moment(editMode ? editForm?.its : undefined);
                  const visibleFrom = moment(value);
                  const startDateValue = getFieldValue('start_date');
                  const startDate = moment(startDateValue);
                  const endDateValue = getFieldValue('end_date');
                  const endDate = moment(endDateValue);
                  if (startDateValue && visibleFrom.isAfter(startDate)) {
                    return Promise.reject(
                      'La fecha debe ser inferior a la hora de inicio',
                    );
                  }
                  if (endDateValue && visibleFrom.isAfter(endDate)) {
                    return Promise.reject(
                      'La fecha debe ser inferior a la hora de fin',
                    );
                  }
                  if (visibleFrom.isBefore(minDate)) {
                    return Promise.reject(
                      `La fecha debe ser posterior a la hora ${
                        editMode ? 'de creación' : 'actual'
                      }`,
                    );
                  }
                  return Promise.resolve();
                },
              };
            },
          ],
        },
        render: (_, { visible_from }) =>
          visible_from ? getDateWithTime({ element: visible_from }) : '-',
        renderFormItem: (_, __, formInstance) => (
          <DatePicker
            format={[FORMAT_DATE_TIME_1]}
            placeholder="Seleccione fecha"
            showTime={{
              showHour: true,
              showMinute: true,
            }}
            allowClear={false}
            onChange={(value: any) => {
              formInstance.setFieldsValue({ visible_from: value });
              formInstance.validateFields(['visible_from', 'visible_to']);
            }}
            inputReadOnly
          />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'visible_to',
        title: 'Visible hasta',
        type: ABM.TYPE_COLUMN.DATE,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'La fecha es obligatoria',
            },
            ({ getFieldValue }) => {
              return {
                validator: (_rule, value) => {
                  const minDate = moment(editMode ? editForm?.its : undefined);
                  const visibleFromValue = moment(
                    getFieldValue('visible_from'),
                  );
                  const visibleFrom = moment(visibleFromValue);
                  const visibleTo = moment(value);
                  const endDateValue = getFieldValue('end_date');
                  const endDate = moment(endDateValue);
                  if (endDateValue && visibleTo.isAfter(endDate)) {
                    return Promise.reject(
                      'La fecha debe ser inferior a la hora de finalización',
                    );
                  }
                  if (visibleFromValue && visibleTo.isBefore(visibleFrom)) {
                    return Promise.reject(
                      'La fecha de fin debe ser posterior a la fecha de inicio',
                    );
                  }
                  if (visibleTo.isBefore(minDate)) {
                    return Promise.reject(
                      `La fecha debe ser posterior a la hora ${
                        editMode ? 'de creación' : 'actual'
                      }`,
                    );
                  }
                  return Promise.resolve();
                },
              };
            },
          ],
        },
        render: (_, { visible_to }) =>
          visible_to ? getDateWithTime({ element: visible_to }) : '-',
        renderFormItem: () => genericDatePicker(),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: 'visible_range',
        title: 'Visible',
        renderFormItem: () => (
          <DatePicker.RangePicker
            style={{ height: 32 }}
            name="visible_range"
            format={FORMAT_DATE_TIME_4}
            placeholder={['Fecha desde', 'Fecha hasta']}
            allowEmpty={[true, true]}
            className="renderFormItem"
            inputReadOnly
            allowClear
          />
        ),
        align: 'left',
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        title: 'Motivo de modificación',
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: askForReason.current,
              message:
                'Debe indicar el motivo de la actualización de la suscripción',
            },
          ],
        },
        render: (_, { change_reason }) => change_reason || '-',
        renderFormItem: () => {
          if (editMode && askForReason.current) {
            return (
              <Input
                placeholder="Ingrese el motivo de la modificación de la subscripción"
                showCount
                maxLength={500}
                onChange={saveChangeReason}
              />
            );
          } else {
            return <Input placeholder="Ingrese descripción" />;
          }
        },

        align: 'left',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: !askForReason.current,
      },
      {
        export: true,
        dataIndex: 'its',
        title: 'Creado',
        type: ABM.TYPE_COLUMN.DATE,
        render: (_, { its }) => (its ? getDateWithTime({ element: its }) : '-'),
        align: 'left',
        defaultSortOrder: 'descend',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'uts',
        title: 'Actualizado',
        type: ABM.TYPE_COLUMN.DATE,
        render: (_, { uts }) => (uts ? getDateWithTime({ element: uts }) : '-'),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        title: 'Op.',
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 100,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            <Tooltip
              key="preview_subscription"
              trigger={tooltipTrigger}
              title="Previsualizar card de suscripción"
            >
              <EyeOutlined
                className="pointer"
                onClick={() => {
                  setPrevisualizedSubscriptionId(record.id);
                }}
              />
            </Tooltip>
            <Divider type="vertical" />
            {Authorization.security(
              functions,
              EnumsValues.Functions.SubscriptionUpdate,
            ) && (
              <Tooltip
                key="edit_subscription_tooltip"
                trigger={tooltipTrigger}
                title="Modificar suscripción"
              >
                <EditOutlined
                  className="pointer"
                  onClick={async () => {
                    await haveTickets(record.id);
                    setEditFormValues(record);
                    handleUpdateModalVisible(true);
                    setPublished(record.published);
                  }}
                />
              </Tooltip>
            )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.SubscriptionDelete,
            ) && (
              <>
                <Divider type="vertical" />
                <Tooltip
                  key="remove_subscription_tooltip"
                  trigger={tooltipTrigger}
                  title="Eliminar suscripción"
                >
                  <DeleteOutlined
                    className="pointer"
                    onClick={() => {
                      setEditFormValues(record);
                      removeSubscription(record);
                    }}
                  />
                </Tooltip>
              </>
            )}
          </>
        ),
      },
    ],
    [dataTable, published, eventsCombo, editForm],
  );
  let LIST_FILTER_NAMES = columns(false)
    // eslint-disable-next-line array-callback-return
    .filter((value) => {
      if (
        LIST_FILTER.find(
          (element) =>
            element === value.dataIndex && value.hideInTable === false,
        )
      ) {
        return value.title;
      }
    })
    .map((element) => {
      return element.title;
    });

  const exportableTable: ExportableTable<ISubscription> = {
    columns: columns(false),
    items: dataTable,
    filename: `subscription_${MomentJS.momentDefaultFormat()}`,
  };

  const previsualizedSubscriptionModal = useMemo(() => {
    const previsualizedSubscription = dataTable.find(
      (subscription) => subscription.id === previsualizedSubscriptionId,
    );
    if (!previsualizedSubscription) {
      return null;
    }

    const { name, id, price, event, start_date, card_description } =
      previsualizedSubscription;
    if (!event) {
      return null;
    }
    const { ubication } = event;

    return (
      <Modal
        visible
        footer={null}
        width="max-content"
        bodyStyle={{ paddingBottom: 48 }}
        onCancel={() => setPrevisualizedSubscriptionId(undefined)}
        title="Previsualización"
        destroyOnClose
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <EventCard
            price={price}
            description={card_description || ''}
            title={name}
            image="https://picsum.photos/300/150"
            key={id}
            ubication={ubication}
            date={start_date}
            onButtonClick={() => {}}
          />
        </div>
      </Modal>
    );
  }, [previsualizedSubscriptionId, dataTable]);

  const saveChangeReason = (e: any) => {
    setChangeReason(e.target.value);
  };

  return (
    <>
      <ProTable<ISubscription>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        headerTitle={TITLE_PRO_TABLE}
        actionRef={actionRef}
        rowKey="id"
        scroll={{
          x: 'max-content',
        }}
        search={{
          resetText: 'Limpiar',
          searchText: 'Buscar',
          collapseRender: showCollapseRender(columns(false)),
        }}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<ISubscription>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <div className="content-search-table">
            <Tooltip
              key="searchtext"
              title={'Buscar por: ' + LIST_FILTER_NAMES.join(', ')}
            >
              <Input.Search
                size="middle"
                placeholder={INPUT_SEARCH_PLACEHOLDER}
                enterButton
                value={searchText}
                onSearch={handleSearch}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                allowClear={true}
              />
            </Tooltip>
          </div>,
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.SubscriptionCreate,
            ) && (
              <Button
                type="primary"
                onClick={() => {
                  setCreateModalVisible(true);
                }}
                icon={<PlusOutlined />}
              >
                Nuevo
              </Button>
            )}
          </>,
          <>
            <ExporterDropdown exportable={exportableTable} />
          </>,
          <Button onClick={changeView} className="buttonChangeProTableView">
            Cambiar Vista
          </Button>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns(false)}
      />
      <SaveForm
        loading={formLoading}
        title={TITLE_CREATE_FORM}
        onCancel={() => {
          setCreateModalVisible(false);
        }}
        modalVisible={createModalVisible}
        values={{
          event_id: eventsCombo.length === 1 ? eventsCombo[0].id : undefined,
        }}
        onOk={createSubscription}
        columns={columns(false, true)}
      />
      <SaveForm
        loading={formLoading}
        title={TITLE_UPDATE_FORM}
        modalVisible={updateModalVisible}
        values={{
          ...editForm,
          visible_from: editForm && moment(new Date(editForm.visible_from)),
          visible_to: editForm && moment(new Date(editForm.visible_to)),
          start_date: editForm && moment(new Date(editForm.start_date)),
          end_date: editForm && moment(new Date(editForm.end_date)),
          event_id: eventsCombo.find(
            (event) => event.id === editForm?.event?.id,
          )?.id,
          ubication: editForm?.ubication || editForm?.event?.ubication,
          ubication_inherited: editForm?.ubication ? null : true,
        }}
        columns={columns(true)}
        onOk={updateSubscription}
        onCancel={() => {
          handleUpdateModalVisible(false);
          setEditFormValues(undefined);
          askForReason.current = false;
          setChangeReason('');
        }}
      />
      <Modal
        zIndex={2000}
        title={TITLE_CHANGE_REASON_FORM}
        visible={changeReasonModalVisible}
        onOk={() => {
          setChangeReasonModalVisible(false);
          if (editForm) removeConfirmedSubscription(editForm);
        }}
        onCancel={() => {
          setChangeReason('');
          askForReason.current = false;
          setChangeReasonModalVisible(false);
        }}
      >
        <Input
          name="change_reason_delete"
          placeholder="Ingrese el motivo para eliminar la suscripción"
          showCount
          maxLength={500}
          onChange={saveChangeReason}
        />
      </Modal>
      {previsualizedSubscriptionModal}
    </>
  );
}
