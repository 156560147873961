import { PageLoading } from '@ant-design/pro-layout';
import { Tag } from 'antd';
import { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import ExceptionManager from '../../components/common/ExceptionManager';
import './index.less';

const ENVTagColor: Record<string, string> = {
  dev: 'orange',
  test: 'green',
  stage: '#87d068',
};

const ManagementUsersLayout = ({ route }: any) => {
  return (
    <div className="ManagementUsersLayout">
      {process.env?.REACT_APP_ENV && ENVTagColor[process.env.REACT_APP_ENV] && (
        <Tag className="env-tag" color={ENVTagColor[process.env.REACT_APP_ENV]}>
          {process.env.REACT_APP_ENV}
        </Tag>
      )}
      {/* Aca se puede crear elementos para todas las paginas*/}
      <div className="ManagementUsersLayoutTitle">Eventos App</div>
      {/* child routes won't render without this */}
      <div className="ManagementUsersLayoutContent">
        <Suspense fallback={<PageLoading />}>
          <ExceptionManager>{renderRoutes(route.routes)}</ExceptionManager>{' '}
        </Suspense>
      </div>
      <div className="ManagementUsersLayoutFooter"></div>
    </div>
  );
};

export default ManagementUsersLayout;
