export const UPSERT_USER_POINT_OF_SALE = `
mutation upsertUserPointOfSale($input: UpsertUserPointOfSaleInput!) {
  upsertUserPointOfSale(input: $input) {
    __typename
    ... on UserPointOfSale {
      id
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
