import { Col, Row } from 'antd';
import './LandingPage.less';
import image from './assets/banner_image.png';
import CardOnlyImage from '../../components/CardOnlyImage/CardOnlyImage';
import GraphqlService from '../../services/graphql/GraphqlService';
import { CustomMessage } from '../../hooks';
import { useContext, useState } from 'react';
import { ISubscription } from '../../interfaces/Subscription';
import { useEffect } from 'react';
import { PageLoading } from '@ant-design/pro-layout';
import { NavContext } from '@ionic/react';
import { PublicContext } from '../../contexts/PublicContext';

const LandingPage = () => {
  const { navigate } = useContext(NavContext);
  const { setNavbarText } = useContext(PublicContext);
  const colSpans = { xs: 24, md: 12 };
  const [subscriptions, setSubscriptions] = useState<
    (ISubscription & {
      event_picture_url: string | null;
    })[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { Query, customRequest } = GraphqlService();

  const { showMessageError } = CustomMessage();
  const getPublishedSubscriptions = async () => {
    setLoading(true);
    try {
      const data: ISubscription[] = await customRequest({
        query: Query.publishedSubscriptions,
        variables: {
          filter: {
            dates_range: [new Date(), null],
          },
        },
      });
      getAllEventPictures(data);
      setSubscriptions(
        data.map((subs) => ({
          ...subs,
          event_picture_url: subs.event?.event_picture_id ? null : '',
        })),
      );
    } catch (error: any) {
      showMessageError({
        context: 'LandingPage.getPublishedSubscriptions.1',
        error,
      });
    }
    setLoading(false);
  };

  const getAllEventPictures = (data: ISubscription[]) => {
    data.forEach((subscription) => {
      if (subscription.event?.event_picture_id) {
        try {
          getEventPicture(
            subscription.id,
            subscription.event?.event_picture_id,
          );
        } catch (error: any) {
          console.error(
            error.message
              ? error.message
              : 'Error al recuperar la imagen del equipo.',
          );
        }
      }
    });
    return;
  };

  const getEventPicture = async (
    subscription_id: number,
    event_picture_id: number,
  ) => {
    if (!event_picture_id) return;

    try {
      const data = await customRequest({
        query: Query.eventPictureLink,
        variables: { id: event_picture_id },
      });
      setSubscriptions((oldState) => {
        const newState = [...oldState];
        const indexOfSubscriptionElement = oldState.findIndex(
          (ele) => ele.id === subscription_id,
        );
        newState[indexOfSubscriptionElement].event_picture_url = data.result;
        return newState;
      });
    } catch (error: any) {
      showMessageError({
        context: 'CompleteEvent.getEventPicture.1',
        error: error,
      });
    }
  };

  useEffect(() => {
    getPublishedSubscriptions();
    if (process.env.REACT_APP_NAME) setNavbarText(process.env.REACT_APP_NAME);
  }, []);

  return (
    <div className="landing-page">
      <div
        className="landing-page__container-banner"
        style={{ backgroundImage: `url(${image})` }}
      >
        <h1 className="landing-page__container-banner__title">
          Grand-e-Ticket
        </h1>
        <h3 className="landing-page__container-banner__title">
          Eventos/Subscripciones
        </h3>
      </div>
      <div className="landing-page__container-cards">
        {loading ? (
          <>
            <PageLoading />
          </>
        ) : (
          <>
            <Row gutter={[16, 16]}>
              {subscriptions.length ? (
                <>
                  {subscriptions.map((subscription) => (
                    <>
                      <Col
                        className="landing-page__container-cards__event-subscription-card"
                        {...colSpans}
                      >
                        <>
                          <CardOnlyImage
                            onClick={() =>
                              subscription.event?.id &&
                              navigate(`/event/${subscription.event?.id}`)
                            }
                            img={subscription.event_picture_url}
                            data={subscription}
                            disabled={!subscription.quantity}
                          />
                        </>
                      </Col>
                    </>
                  ))}
                </>
              ) : (
                <div className="landing-page__container-cards__container-text-no-data">
                  <span className="landing-page__container-cards__container-text-no-data__text-no-data">
                    No se encontraron eventos
                  </span>
                </div>
              )}
            </Row>
          </>
        )}
      </div>
    </div>
  );
};
export default LandingPage;
