import { message, Modal } from 'antd';
import moment from 'moment';

export const MAX_SIZE_IMAGE_IN_MB = 10;
export const PREFIX_LEGAL_NUMBER = 20110;
export const SECONDS_CONSTANT = 1000;

const Tools = () => {};

const MESSAGE_CREATE_SUCCESS = 'Creado exitosamente';
const MESSAGE_CREATE_ERROR = 'Error al crear, intente nuevamente!';
const MESSAGE_UPDATE_SUCCESS = 'Actualizado exitosamente';
const MESSAGE_UPDATE_ERROR = 'Error al actualizar, intente nuevamente!';
const MESSAGE_DELETE_SUCCESS = 'Eliminado exitosamente';
const MESSAGE_DELETE_ERROR = 'Error al eliminar, intente nuevamente!';

const DEFAULT_TIMEOUT_LOADING = 150000;
const DEFAULT_TIMEOUT_MESSAGE = 1200;

/**
 * Iniciar un loading
 * @param msg
 */
Tools.messageLoading = (msg?: string, time?: number) => {
  message.destroy();
  message.loading(msg || 'Cargando...', time || DEFAULT_TIMEOUT_LOADING);
};

Tools.messageDestroy = (time?: number, callback?: Function) => {
  setTimeout(() => {
    message.destroy();
    if (callback) {
      callback();
    }
  }, time || DEFAULT_TIMEOUT_MESSAGE);
};

Tools.messageModalSuccess = (msg: string | JSX.Element) => {
  message.destroy();
  Modal.success({
    content: msg,
  });
};

Tools.messageModalError = (msg: string | JSX.Element) => {
  message.destroy();
  Modal.error({
    content: msg,
  });
};

Tools.messageModalInfo = (msg: string | JSX.Element) => {
  message.destroy();
  Modal.info({
    content: msg,
  });
};

/**
 * Resultado de un proceso de exito
 * @param msg
 */
Tools.messageSuccess = (msg: string, time?: number) => {
  message.destroy();
  message.success(msg);
  Tools.messageDestroy(time);
};

/**
 * Resultado de un proceso que dio error
 * @param msg
 */
Tools.messageError = (msg: string, time?: number) => {
  message.destroy();
  message.error(msg);
  Tools.messageDestroy(time);
};

/**
 * Para acciones de ABM
 */
Tools.messageCreating = (msg: string) => {
  Tools.messageLoading(`Creando ${msg} ...`);
};
Tools.messageUpdating = (msg: string) => {
  Tools.messageLoading(`Actualizando ${msg} ...`);
};
Tools.messageDeleting = (msg: string) => {
  Tools.messageLoading(`Eliminando ${msg} ...`);
};

/**
 * Para resultados de ABM
 */
Tools.messageCreateSuccess = () => {
  Tools.messageSuccess(MESSAGE_CREATE_SUCCESS);
};
Tools.messageCreateError = () => {
  Tools.messageError(MESSAGE_CREATE_ERROR);
};
Tools.messageUpdateSuccess = () => {
  Tools.messageSuccess(MESSAGE_UPDATE_SUCCESS);
};
Tools.messageUpdateError = () => {
  Tools.messageError(MESSAGE_UPDATE_ERROR);
};
Tools.messageDeleteSuccess = () => {
  Tools.messageSuccess(MESSAGE_DELETE_SUCCESS);
};
Tools.messageDeleteError = () => {
  Tools.messageError(MESSAGE_DELETE_ERROR);
};

/**
 * Set currency
 * TODO: system_settting for currency and locale and minimumFractionDigits
 */
Tools.formatCurrency = (val: any): string => {
  return new Intl.NumberFormat('es-CL', {
    minimumFractionDigits: 0,
    style: 'currency',
    currency: 'CLP',
  }).format(Number(val));
};

/**
 * Traduce el valor de ordenamiento de TablePro al valor que necesita el backend
 * @param data
 */
Tools.getTypeOrderByTableSortParam = (val: string) => {
  return val === 'ascend' ? 'asc' : 'desc';
};

Tools.getCarrierIdByUser = (carrierUser: any): number | null => {
  if (carrierUser && carrierUser.carrier && carrierUser.carrier.id) {
    return carrierUser.carrier.id;
  }
  return null;
};

/**
 * Retorna ArrayBuffer de un base64
 * @param base64
 */
Tools.base64ToArrayBuffer = (base64: string) => {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((_byte, i) => binaryString.charCodeAt(i));
};

/**
 * Retorna URL de un Blob
 * @param file
 */
Tools.createObjectURLOfBlob = (file: any) => {
  const arrayBuffer = Tools.base64ToArrayBuffer(file.img);
  const blob = new Blob([arrayBuffer], { type: file.mimetype });
  const fileURL: string = URL.createObjectURL(blob);
  return fileURL;
};

/**
 * Descarga archivo PDF
 * @param file
 * @param extension
 */
Tools.downloadFilePDF = (file: any) => {
  const createAndDownloadBlobFile = (arrayBuffer: any, filename: string) => {
    const blob = new Blob([arrayBuffer]);
    const fileName = `${filename}`;
    if ((navigator as any).msSaveBlob) {
      // IE 10+
      (navigator as any).msSaveBlob(blob, fileName);
    } else {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = file.filename;
      link.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const arrayBuffer = Tools.base64ToArrayBuffer(file.file);
  createAndDownloadBlobFile(arrayBuffer, file.filename);
};

/**
 * Descarga archivo de imagen (png - jpeg - gif)
 * @param image
 */
Tools.downloadImage = (image: any) => {
  const link = document.createElement('a');
  link.href = `data:${image.mimetype};base64,${image.img}`;
  link.download = image.filename;
  link.click();
};

Tools.filter = (list: any[], search: string) => {
  if (search) {
    const result: any[] = list.filter((item) => {
      let itemLower = JSON.stringify(item);
      itemLower = itemLower.toLowerCase();
      const searchSplit: any[] = search.toLowerCase().split(' ');
      let validate = 0;
      searchSplit.forEach((search) => {
        if (itemLower.indexOf(search) !== -1) {
          validate += 1;
        }
      });
      if (validate === searchSplit.length) {
        return true;
      } else {
        return false;
      }
    });
    return result;
  } else {
    return list;
  }
};

Tools.fileWeightOfSize = (size: number): number => {
  //lo paso a kbyte
  const kbyte = size / 1000;
  //lo paso a megas
  const mega = kbyte / 1000;
  //redondeo a un decimal
  return Number(mega.toFixed(2));
};

Tools.capitalize = (text: string): string => {
  if (text.length > 1) {
    const firstCharacter = text.charAt(0);
    return `${firstCharacter.toUpperCase()}${text.substring(1)}`;
  }
  return text;
};

Tools.stripPaginationVariables = (variables: any) => {
  const returningVariables = { ...variables };
  delete returningVariables.skip;
  delete returningVariables.take;
  return returningVariables;
};

Tools.getSkip = (pageSize: number | undefined, current: number | undefined) => {
  if (!pageSize || !current) {
    return 0;
  }
  return pageSize * (current - 1);
};

Tools.blobToUrlBase64 = (blob: Blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

Tools.sortByZone = (a: any, b: any) => {
  let [ahh, amm] = a.label.split('GMT')[1].split(')')[0].split(':');
  let [bhh, bmm] = b.label.split('GMT')[1].split(')')[0].split(':');
  return +ahh * 60 + amm - (+bhh * 60 + bmm);
};
Tools.isDefined = (value: any) => value !== null && value !== undefined;

Tools.getBase64WithCallback = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

interface IGetUrlOfBase64FileParameter {
  mimetype: string;
  fileBase64: string;
}

Tools.getUrlOfBase64File = (fileData: IGetUrlOfBase64FileParameter): string => {
  return `data:${fileData.mimetype};base64,${fileData.fileBase64}`;
};

Tools.fullDaysInRange = ({
  minDate,
  maxDate,
}: {
  minDate: Date;
  maxDate: Date;
}) => {
  return {
    minDate: moment(minDate).startOf('day'),
    maxDate: moment(maxDate).endOf('day'),
  };
};

Tools.imageSize = (src: string) => {
  const img = document.createElement('img');
  const promise: Promise<{ width: number; height: number }> = new Promise(
    (resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        resolve({ width, height });
      };
      img.onerror = reject;
    },
  );
  img.src = src;
  return promise;
};

Tools.imageDataFromSource = async (source: string) => {
  const image = Object.assign(new Image(), { src: source });
  await new Promise((resolve) =>
    image.addEventListener('load', () => resolve(undefined)),
  );
  const context = Object.assign(document.createElement('canvas'), {
    width: image.width,
    height: image.height,
  }).getContext('2d')!;
  context.imageSmoothingEnabled = false;
  context.drawImage(image, 0, 0);
  return context.getImageData(0, 0, image.width, image.height);
};

export { Tools };
