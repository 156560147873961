import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import ProTable, { ActionType } from '@ant-design/pro-table';
import { Button, Divider, Input, Modal, Select, Switch, Tooltip } from 'antd';
import { ExporterDropdown, SaveForm } from '../../components/common/ABM';
import { useCallback, useContext, useRef, useState, useEffect } from 'react';
import { ExportableColumn, ExportableTable } from '../../shared/Exporter';
import { SorterResult } from 'antd/lib/table/interface';
import GraphqlService from '../../services/graphql/GraphqlService';
import {
  ABM,
  Authorization,
  MomentJS,
  Tools,
  tooltipTrigger,
} from '../../shared';
import { showCollapseRender } from '../../shared/showCollapseRender';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import { ParamsType } from '@ant-design/pro-provider';
import { getPaginationArgs } from '../../shared/getPaginationArgs';
import { IPointOfSale, IPointOfSaleCreate } from '../../interfaces/PointOfSale';
import { ITenant } from '../../interfaces';
import AssignUsersToPointOfSaleModal, {
  IAssignUsersToPointOfSaleModalRef,
} from '../../components/PointOfSale/AssignUsersToPointOfSaleModal';

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = ['active'];

const LIST_SORTER = ['id', 'name'];

/**
 * Se configura por cada ABM diferente
 */
const TITLE_PRO_TABLE = 'Formulario de consulta';
const TITLE_CREATE_FORM = 'Formulario de alta';
const TITLE_UPDATE_FORM = 'Editar Ventanilla';
const INPUT_SEARCH_PLACEHOLDER = 'Buscar...';

export default function AdminPointOfSalePage() {
  // props
  // states
  const [searchText, setSearchText] = useState('');
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] =
    useState<IPointOfSale | undefined>(undefined);
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>('');
  const [dataTable, setDataTable] = useState<IPointOfSale[]>([]);
  const [tenantsCombo, setTenantsCombo] = useState<ITenant[]>([]);
  const [activated, setActivated] = useState(true);
  const assignUsersToPointOfSaleModalRef =
    useRef<IAssignUsersToPointOfSaleModalRef | null>(null);

  // services and hooks
  const { mobileOnSizeChangeProTable, changeView, showComponent } =
    useProTableForMobile({
      layout: 'horizontal',
    });
  const { Query, Mutation, customRequest } = GraphqlService();

  const {
    messageError,
    messageDeleteSuccess,
    messageDeleting,
    messageUpdating,
    messageUpdateSuccess,
    messageUpdateError,
    messageCreating,
    messageCreateSuccess,
    messageCreateError,
  } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  // contexts
  const { functions, user } = useContext(ContextApp);

  // methods

  const cleanupState = () => {
    setActivated(true);
  };

  useEffect(() => {
    if (!createModalVisible && !updateModalVisible) {
      cleanupState();
    }
  }, [createModalVisible, updateModalVisible]);

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const getTenantsForCombo = async () => {
    try {
      const data: ITenant[] = await customRequest({
        query: Query.tenants,
      });
      setTenantsCombo(data);
    } catch {
      // Intentional
    }
  };

  useEffect(() => {
    if (user) {
      getTenantsForCombo();
    }
  }, [user]);

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    },
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search = ABM.valuesResult(params);

      if (searchText) {
        variables.current.searchText = searchText;
      } else {
        delete variables.current.searchText;
      }

      LIST_FILTER.forEach((element) => {
        try {
          if (search[element]) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }

            variables.current.filter[element] = search[element];

            if (variables.current.filter[element] === 'true') {
              variables.current.filter[element] = true;
            } else if (variables.current.filter[element] === 'false') {
              variables.current.filter[element] = false;
            }
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      LIST_SORTER.forEach((element) => {
        try {
          if (search.sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search.sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      const countPromise = customRequest({
        query: Query.pointOfSaleCount,
        variables: variables.current,
      }).then((data: { count: number }) => data.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current,
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = customRequest({
        query: Query.pointsOfSale,
        variables: variables.current,
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);
      setDataTable(data);
      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const createPointOfSale = async (value: IPointOfSaleCreate) => {
    setFormLoading(true);
    messageCreating({
      context: 'AdminPointOfSalePage.createPointOfSale.1',
      message: 'Ventanilla',
    });

    try {
      await customRequest({
        mutation: Mutation.createPointOfSale,
        variables: {
          input: { ...value, active: activated },
        },
      });
      setCreateModalVisible(false);
      messageCreateSuccess({
        context: 'AdminPointOfSalePage.createPointOfSale.2',
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        setFormLoading(false);
        return messageError({
          context: 'AdminPointOfSalePage.createPointOfSale.3',
          message: error.message,
        });
      }
      messageCreateError({
        context: 'AdminPointOfSalePage.createPointOfSale.3',
      });
    }
    setFormLoading(false);
  };

  const updatePointOfSale = async (value: IPointOfSaleCreate) => {
    if (!editForm) return;

    setFormLoading(true);
    messageUpdating({
      context: 'AdminPointOfSalePage.updatePointOfSale.1',
      message: 'Ventanilla',
    });

    try {
      await customRequest({
        mutation: Mutation.updatePointOfSale,
        variables: {
          id: editForm.id,
          input: {
            ...value,
            active: activated,
          },
        },
      });
      messageUpdateSuccess({
        context: 'AdminPointOfSalePage.updatePointOfSale.2',
      });
      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      setFormLoading(false);
      if (error.status_code && error.message) {
        return messageError({
          context: 'AdminPointOfSalePage.updatePointOfSale.3',
          message: error.message,
        });
      }
      messageUpdateError({
        context: 'AdminPointOfSalePage.updatePointOfSale.3',
      });
    }
    setFormLoading(false);
  };

  const removeConfirmedPointOfSale = async (value: {
    id: number;
    name: string;
  }) => {
    messageDeleting({
      context: 'AdminPointOfSalePage.removeConfirmedPointOfSale.1',
      message: 'Ventanilla',
    });
    try {
      await customRequest({
        mutation: Mutation.deletePointOfSale,
        variables: {
          id: value.id,
        },
      });
      messageDeleteSuccess({
        context: 'AdminPointOfSalePage.removeConfirmedPointOfSale.2',
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      messageError({
        context: 'AdminPointOfSalePage.removeConfirmedPointOfSale.3',
        message: error.message,
      });
    }
  };

  const removePointOfSale = (value: { id: number; name: string }) => {
    Modal.confirm({
      content: (
        <>
          <div>¿Seguro que desea eliminar la ventanilla {value.name}?</div>
        </>
      ),
      cancelText: 'Cancelar',
      okText: 'Aceptar',
      onOk: () => {
        removeConfirmedPointOfSale(value);
      },
    });
  };

  const columns = useCallback(
    (
      editMode: boolean,
      createMode: boolean = false,
    ): ExportableColumn<IPointOfSale>[] => [
      {
        export: false,
        dataIndex: 'id',
        title: 'Id',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: 'name',
        title: 'Nombre',
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'El nombre es obligatorio',
            },
          ],
        },
        render: (_, record: { name: string }) => record.name || '-',
        renderFormItem: () => (
          <Input placeholder="Ingrese nombre" minLength={4} />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'tenant_id',
        title: 'Tenant',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: editMode,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'El tenant es obligatorio',
            },
          ],
        },
        render: (_, record) => record.tenant.name,
        renderDataExport: (record) => record.tenant.name,
        renderFormItem: () => (
          <Select
            options={tenantsCombo.map((tenant) => ({
              label: tenant.name,
              value: tenant.id.toString(),
            }))}
            className={tenantsCombo.length === 1 ? 'select-is-disabled' : ''}
            placeholder="Seleccione tenant..."
            allowClear
            showSearch
            disabled={tenantsCombo.length === 1}
            optionFilterProp="label"
          />
        ),
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: 'active',
        title: 'Activo',
        type: ABM.TYPE_COLUMN.BOOLEAN,
        render: (_, record) => (record.active ? 'Si' : 'No' || '-'),
        renderFormItem: () => {
          if (editMode || createMode) {
            return <Switch onChange={setActivated} checked={activated} />;
          } else {
            return (
              <Select
                options={[
                  {
                    label: 'Si',
                    value: 'true',
                  },
                  {
                    label: 'No',
                    value: 'false',
                  },
                ]}
                placeholder="Seleccione si o no..."
                allowClear
                showSearch
              />
            );
          }
        },
        align: 'left',
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        title: 'Op.',
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 140,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => {
          return (
            <>
              {Authorization.security(
                functions,
                EnumsValues.Functions.UpdatePointOfSale,
              ) && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="edit_point_of_sale_tooltip"
                    trigger={tooltipTrigger}
                    title="Modificar ventanilla"
                  >
                    <EditOutlined
                      className="pointer"
                      onClick={() => {
                        handleUpdateModalVisible(true);
                        setEditFormValues(record);
                        setActivated(record.active);
                      }}
                    />
                  </Tooltip>
                </>
              )}
              {Authorization.security(
                functions,
                EnumsValues.Functions.DeletePointOfSale,
              ) && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="assign_users_to_point_of_sale_tooltip"
                    trigger={tooltipTrigger}
                    title="Asignar usuarios"
                  >
                    <TeamOutlined
                      className="pointer"
                      onClick={() => {
                        setEditFormValues(record);
                        assignUsersToPointOfSaleModalRef.current?.toggleVisibility();
                      }}
                    />
                  </Tooltip>
                </>
              )}
              {Authorization.security(
                functions,
                EnumsValues.Functions.DeletePointOfSale,
              ) && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="remove_point_of_sale_tooltip"
                    trigger={tooltipTrigger}
                    title="Eliminar ventanilla"
                  >
                    <DeleteOutlined
                      className="pointer"
                      onClick={() => {
                        removePointOfSale(record);
                      }}
                    />
                  </Tooltip>
                </>
              )}
            </>
          );
        },
      },
    ],
    [dataTable, activated],
  );

  const exportableTable: ExportableTable<IPointOfSale> = {
    columns: columns(false),
    items: dataTable,
    filename: `point_of_sale_${MomentJS.momentDefaultFormat()}`,
  };

  const onAssignUsersToPointOfSaleModalClose = () => {
    setEditFormValues(undefined);
  };

  return (
    <>
      <ProTable<IPointOfSale>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        headerTitle={TITLE_PRO_TABLE}
        actionRef={actionRef}
        rowKey="id"
        search={{
          resetText: 'Limpiar',
          searchText: 'Buscar',
          collapseRender: showCollapseRender(columns(false)),
        }}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<IPointOfSale>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <div className="content-search-table">
            <Tooltip key="searchtext" title={'Buscar por nombre'}>
              <Input.Search
                size="middle"
                placeholder={INPUT_SEARCH_PLACEHOLDER}
                enterButton
                value={searchText}
                onSearch={handleSearch}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                allowClear={true}
              />
            </Tooltip>
          </div>,
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.CreatePointOfSale,
            ) && (
              <Button
                type="primary"
                onClick={() => {
                  setCreateModalVisible(true);
                }}
                icon={<PlusOutlined />}
              >
                Nuevo
              </Button>
            )}
          </>,
          <>
            <ExporterDropdown exportable={exportableTable} />
          </>,
          <Button onClick={changeView} className="buttonChangeProTableView">
            Cambiar Vista
          </Button>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        scroll={{
          x: 'max-content',
        }}
        columns={columns(false)}
      />
      <SaveForm
        loading={formLoading}
        title={TITLE_CREATE_FORM}
        onCancel={() => {
          setCreateModalVisible(false);
        }}
        values={{
          tenant_id: tenantsCombo.length === 1 ? tenantsCombo[0].id : undefined,
        }}
        modalVisible={createModalVisible}
        onOk={createPointOfSale}
        columns={columns(false, true)}
      />
      {editForm && (
        <SaveForm
          loading={formLoading}
          title={TITLE_UPDATE_FORM}
          modalVisible={updateModalVisible}
          values={{
            ...editForm,
            tenant_id: tenantsCombo.find(
              (tenant) => tenant.id === editForm.tenant_id,
            )?.name,
          }}
          columns={columns(true)}
          onOk={updatePointOfSale}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues(undefined);
          }}
        />
      )}
      <AssignUsersToPointOfSaleModal
        point_of_sale_id={editForm?.id || 0}
        onClose={onAssignUsersToPointOfSaleModalClose}
        ref={assignUsersToPointOfSaleModalRef}
      />
    </>
  );
}
