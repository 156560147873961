export const CREATE_SUBSCRIPTION = `
mutation createSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input) {
    __typename
    ... on Subscription {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DELETE_SUBSCRIPTION = `
mutation deleteSubscription($id: Int!, $change_reason: String) {
  deleteSubscription(id: $id, change_reason: $change_reason) {
    __typename
    ... on Subscription {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPDATE_SUBSCRIPTION = `
mutation updateSubscription($id: Int!, $input: UpdateSubscriptionInput!) {
  updateSubscription(id: $id, input: $input) {
    __typename
    ... on Subscription {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
