export const USER_POINTS_OF_SALE = `
query userPointsOfSale(
  $filter: FilterUserPointOfSaleInput
  $orderBy: UserPointOfSaleOrderInput
  $skip: Int
  $take: Int
) {
  userPointsOfSale(
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    __typename
    ... on UserPointOfSale {
      id
      user_id
      point_of_sale_id
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const GET_USERS_CANDIDATES_FOR_POINT_OF_SALE = `
query getUsersCandidatesForPointOfSale($point_of_sale_id: Int!) {
  getUsersCandidatesForPointOfSale(point_of_sale_id: $point_of_sale_id) {
    __typename
    ... on User {
      id
      username
      person {
        firstname
        lastname
      }
    }
  }
}
`;
