import { List } from 'antd';
import { IPointOfSale } from '../../interfaces/PointOfSale';
import PointOfSaleCard from './PointOfSaleCard';

function PointOfSaleList({
  pointsOfSale,
  onItemClick,
}: {
  pointsOfSale: IPointOfSale[];
  onItemClick: (pointOfSale: IPointOfSale) => void;
}) {
  return (
    <List
      itemLayout="horizontal"
      dataSource={pointsOfSale}
      size="small"
      renderItem={(item) => {
        return (
          <List.Item
            key={item.id}
            onClick={() => {
              onItemClick(item);
            }}
            style={{
              cursor: 'pointer',
            }}
          >
            <PointOfSaleCard pointOfSale={item} />
          </List.Item>
        );
      }}
    />
  );
}

export default PointOfSaleList;
