export const UPSERT_USERTENANT = `
mutation upsertUserTenant($input: UpsertUserTenantInput!) {
  upsertUserTenant(input: $input) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const CREATE_TENANT = `
mutation createTenant($input: CreateTenantInput!, $file: Upload) {
  createTenant(input: $input, file: $file) {
    __typename
    ... on Tenant {
      id
      name
      slug
      active
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DELETE_TENANT = `
mutation deleteTenants($ids: [Int!]!) {
  deleteTenants(ids: $ids) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPDATE_TENANT = `
mutation updateTenant($id: Int!, $input: UpdateTenantInput!, $file: Upload) {
  updateTenant(id: $id, input: $input, file: $file) {
    __typename
    ... on Tenant {
      id
      name
      slug
      active
      mercadopago_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
