export const POINTS_OF_SALE = `
query pointsOfSale(
  $onlyWithTenantsActive: Boolean
  $searchText: String
  $filter: PointOfSaleFilterInput
  $orderBy: PointOfSaleOrderByInput
  $skip: Int
  $take: Int
) {
  pointsOfSale(
    onlyWithTenantsActive: $onlyWithTenantsActive
    searchText: $searchText
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    __typename
    ... on PointOfSale {
      id
      name
      tenant_id
      active
      tenant {
        id
        name
        slug
        active
      }
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const POINT_OF_SALE_COUNT = `
query pointOfSaleCount(
  $onlyWithTenantsActive: Boolean
  $searchText: String
  $filter: PointOfSaleFilterInput
) {
  pointOfSaleCount(
    onlyWithTenantsActive: $onlyWithTenantsActive
    searchText: $searchText
    filter: $filter
  ) {
    __typename
    ... on  Count{
      count
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const POINT_OF_SALE = `
query pointOfSale($id: Int!) {
  pointOfSale(id: $id) {
    __typename
    ... on PointOfSale {
      id
      name
      tenant_id
      active
      tenant {
        id
        name
        slug
        active
      }
    }
    ... on ResultError {
      status_code
      message
      message_translation_key
    }
  }
}
`;
