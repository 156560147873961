import {
  Form,
  Typography,
  Input,
  InputNumber,
  Button,
  FormInstance,
} from 'antd';
import React, { useMemo } from 'react';
import { IPurchaseConfirmationFormValues } from '../../interfaces/IPurchaseConfirmationFormValues';
import { ISubscription } from '../../interfaces/Subscription';
import { ICreateTicketsInOrder } from '../../interfaces/Ticket';
import { EventCardSummary } from '../common/EventCardSummary/EventCardSummary';
import './PurchaseConfirmationForm.less';

const PurchaseConfirmationForm = ({
  subscription,
  quantity,
  onSubmit,
  loading,
  cardSumaryImageUrl,
  classname,
  textProps = {},
}: {
  subscription: ISubscription;
  quantity: number;
  onSubmit: (
    input: IPurchaseConfirmationFormValues,
    form: FormInstance<any>,
  ) => void | Promise<void>;
  loading?: boolean;
  cardSumaryImageUrl?: string;
  classname?: string;
  textProps?: {
    submitText?: string;
  };
}) => {
  const [form] = Form.useForm();

  const { submitText } = textProps;

  const createOrder = async (input: Record<string, string>) => {
    if (!input) return;
    let tickets: ICreateTicketsInOrder[] = [];
    persons.forEach((_element, index) => {
      if (subscription) {
        tickets.push({
          paid_amount: subscription.price,
          subscription_id: subscription.id,
          use_quantity: 1,
          due_date: subscription.end_date,
          firstname: input['firstname' + (index + 1)],
          lastname: input['lastname' + (index + 1)],
        });
      }
    });

    onSubmit(
      {
        person: {
          firstname: input.firstname,
          lastname: input.lastname,
          email: input.email,
        },
        ticket: tickets,
      },
      form,
    );
  };

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
  };
  const persons = useMemo(() => {
    const result: JSX.Element[] = [];
    for (let index = 0; index < quantity; index++) {
      result.push(
        <div key={index}>
          <Typography.Title level={5}>
            Datos de Persona {index + 1}
          </Typography.Title>
          <Form.Item
            name={'firstname' + (index + 1)}
            label="Nombre"
            rules={[
              {
                required: true,
                message: 'El nombre es obligatorio',
              },
            ]}
          >
            <Input className="input-data" placeholder="Nombre" />
          </Form.Item>
          <Form.Item
            name={'lastname' + (index + 1)}
            label="Apellido"
            rules={[
              {
                required: true,
                message: 'El apellido es obligatorio',
              },
            ]}
          >
            <Input className="input-data" placeholder="Apellido" />
          </Form.Item>
        </div>,
      );
    }
    return result;
  }, [quantity]);

  return (
    <>
      <div className={`container-purchase ${classname || ''}`}>
        <div className="purchase-section">
          <div className="input-data-section">
            <Form
              {...formItemLayout}
              form={form}
              onFinish={(values) => createOrder(values)}
            >
              <div className="purchase-title">
                <Typography.Title level={3}>
                  Confirmación de compra
                </Typography.Title>
              </div>
              <div>
                <Typography.Title level={5}>
                  Datos de facturación
                </Typography.Title>
                <Form.Item
                  name="firstname"
                  label="Nombre"
                  rules={[
                    {
                      required: true,
                      message: 'El nombre es obligatorio',
                    },
                  ]}
                >
                  <Input className="input-data" placeholder="Nombre" />
                </Form.Item>
                <Form.Item
                  name="lastname"
                  label="Apellido"
                  rules={[
                    {
                      required: true,
                      message: 'El apellido es obligatorio',
                    },
                  ]}
                >
                  <Input className="input-data" placeholder="Apellido" />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: 'El e-mail es obligatorio',
                    },
                    {
                      type: 'email',
                      message: 'El mail ingresado es inválido',
                    },
                  ]}
                >
                  <Input
                    type="email"
                    className="input-data"
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item name="phone" label="Teléfono">
                  <InputNumber
                    className="input-data"
                    placeholder="Teléfono"
                    controls={false}
                  />
                </Form.Item>
              </div>
              <div>{persons}</div>
            </Form>
          </div>
          <div className="card-event-section ">
            <div className="card-event-mobile-text ">
              <Typography.Title level={5}>Resumen</Typography.Title>
              Verificá los datos antes de confirmar la compra
            </div>
            <div className="card-event-summary">
              <EventCardSummary
                price={subscription.price * quantity}
                quantity={quantity}
                image={cardSumaryImageUrl || ''}
                date={subscription.end_date}
                ubication={
                  subscription.ubication
                    ? subscription.ubication
                    : subscription.event?.ubication
                }
              />
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="purchase-button"
                  size="large"
                  onClick={() => {
                    form.submit();
                  }}
                  loading={loading}
                >
                  {submitText || 'Comprar'}
                </Button>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseConfirmationForm;
