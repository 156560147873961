import { CloseCircleOutlined } from '@ant-design/icons';
import { NavContext } from '@ionic/react';
import { Button } from 'antd';
import { useContext, useEffect } from 'react';
import { PublicContext } from '../../contexts/PublicContext';
import './PayDisapproved.less';
export const PayDisapproved = () => {
  const { navigate } = useContext(NavContext);
  const { setNavbarText } = useContext(PublicContext);

  useEffect(() => {
    if (process.env.REACT_APP_NAME) setNavbarText(process.env.REACT_APP_NAME);
  }, []);

  return (
    <>
      <div className="pay-disapproved">
        <div className="pay-disapproved__container">
          <div className="pay-disapproved__container__container-icon">
            <CloseCircleOutlined className="pay-disapproved__container__container-icon__icon" />
          </div>
          <div className="pay-disapproved__container__container-title">
            <span>¡Ups!</span>
          </div>
          <div className="pay-disapproved__container__container-subtitle">
            <span>
              Algo salió mal y no pudimos generar tus tickets.
              <br /> Por favor intenta realizar la compra nuevamente
            </span>
          </div>
          <div className="pay-disapproved__container__container-button">
            <Button
              onClick={() => {
                navigate('/home');
              }}
              type="ghost"
            >
              Ir al inicio
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
