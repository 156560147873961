import { useContext, useEffect, useMemo, useState } from 'react';
import { CustomMessage } from '../../hooks';
import GraphqlService from '../../services/graphql/GraphqlService';
import './EventPublicPage.less';
import { NotFound404 } from '../../components/NotFound404/NotFound404';
import { PageLoading } from '@ant-design/pro-layout';
import { Typography, Button } from 'antd';
import { IEvent } from '../../interfaces/Event';
import { formatMoney } from '../../shared/formatMoney';
import { FORMAT_DATE_TIME_1 } from '../../shared/MomentJS';
import moment from 'moment';
import { NavContext } from '@ionic/react';
import { PublicContext } from '../../contexts/PublicContext';

const EventPublicPage = (props: any) => {
  const [event, setEvent] = useState<IEvent>();
  const [loadingEvent, setLoadingEvent] = useState(true);
  const { showMessageError } = CustomMessage();
  const [loadingEventPicture, setLoadingEventPicture] =
    useState<boolean>(false);
  const [urlFile, setUrlFile] = useState<string>();

  // contexts
  const { navigate } = useContext(NavContext);
  const { setNavbarText } = useContext(PublicContext);

  // services and hooks
  const { Query, customRequest } = GraphqlService();

  const getEvent = async () => {
    setLoadingEvent(true);
    try {
      setEvent(
        await customRequest({
          query: Query.publicEvent,
          variables: {
            id: parseInt(props.match.params.id),
          },
        }),
      );
    } catch (error: any) {
      console.error(error);
    }
    setLoadingEvent(false);
  };

  const getEventPicture = async (event_picture_id?: number) => {
    if (!event_picture_id) return;
    setLoadingEventPicture(true);
    try {
      const data = await customRequest({
        query: Query.eventPictureLink,
        variables: { id: event_picture_id },
      });
      setUrlFile(data.result);
    } catch (error: any) {
      showMessageError({
        context: 'EventPublicPage.getEventPicture.1',
        error: error,
      });
    } finally {
      setLoadingEventPicture(false);
    }
  };

  useEffect(() => {
    getEvent();
  }, []);

  useEffect(() => {
    getEventPicture(event?.event_picture_id);
    if (event?.name) setNavbarText(event.name);
  }, [event]);

  const subscriptions = useMemo(
    () =>
      event?.subscription.map((subs) => (
        <div className="subscription-item ">
          <div>
            <span>Nombre</span>
            <span>{subs.name}</span>
          </div>
          <div>
            <span>Ubicación</span>
            <span>{subs.ubication || event.ubication || 'Sin ubicación'}</span>
          </div>
          <div>
            <span>Fecha y hora</span>
            <span>{moment(subs.start_date).format(FORMAT_DATE_TIME_1)}</span>
          </div>
          <div>
            <span>Precio</span>
            <span>{formatMoney(subs.price)}</span>
          </div>
          <div>
            <span>Cant. de Tickets</span>
            <span>{subs.quantity}</span>
          </div>
          {/*TODO: dar color del tenant al botón*/}
          <Button
            type="primary"
            onClick={() => {
              navigate('/subscription/' + subs.id);
            }}
            className="select-button"
            disabled={subs.quantity === 0}
          >
            Seleccionar
          </Button>
        </div>
      )),
    [event],
  );

  return (
    <>
      {loadingEvent || loadingEventPicture ? (
        <PageLoading />
      ) : (
        <>
          {event ? (
            <>
              <div className="container-event">
                {(loadingEventPicture || urlFile) && (
                  <div className="banner-container">
                    {loadingEventPicture ? (
                      <div className="banner-loading">
                        <PageLoading />
                      </div>
                    ) : (
                      <img
                        className="banner"
                        src={urlFile || ''}
                        alt="event banner"
                      />
                    )}
                  </div>
                )}
                {event?.subscription.length ? (
                  <div className="subscription-section">
                    <div className="event-section">
                      <Typography.Title level={5} style={{ margin: 0 }}>
                        Seleccioná un evento
                      </Typography.Title>
                    </div>
                    {subscriptions}
                  </div>
                ) : (
                  <div className="subscription-section">
                    <div className="event-section">
                      <Typography.Title level={5} style={{ margin: 0 }}>
                        No hay eventos disponibles
                      </Typography.Title>
                    </div>
                    {subscriptions}
                  </div>
                )}
                <div className="event-section details-section">
                  <Typography.Title level={3}>{event.name}</Typography.Title>
                  <div className="details-item">
                    Ubicación: {event.ubication || 'Sin ubicación'}
                  </div>
                  <div className="description-item">{event.description}</div>
                </div>
              </div>
            </>
          ) : (
            <>
              <NotFound404 subtitle="Lo sentimos, el evento no existe o no está disponible." />
            </>
          )}
        </>
      )}
    </>
  );
};
export default EventPublicPage;
