import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Divider, List, Tooltip } from 'antd';
import React, { useRef, useState } from 'react';
import { CustomMessage } from '../../hooks';
import { ITicket } from '../../interfaces/Ticket';
import { TicketService } from '../../services/Ticket.service';
import { downloadFile, printBase64 } from '../../shared/file.utils';
import './PurchasedTicketItem.less';

const PurchasedTicketItem = ({ ticket }: { ticket: ITicket }) => {
  const base64File = useRef<string>();
  const { getTicketPDF } = TicketService();
  const [loading, setLoading] = useState(false);
  const { getErrorMessage, messageError } = CustomMessage();

  const getBase64File = async () => {
    let base64: string;
    if (!base64File.current) {
      const { result } = await getTicketPDF(ticket.id);
      base64 = result;
      base64File.current = result;
    } else {
      base64 = base64File.current;
    }
    return base64;
  };

  const downloadPDF = async () => {
    setLoading(true);
    try {
      const base64 = await getBase64File();
      downloadFile(base64, 'application/pdf', `Ticket_${ticket.id}.pdf`);
    } catch (error) {
      messageError({
        context: 'PurchasedTicketItem - downloadPDF',
        message: getErrorMessage(error),
      });
    }
    setLoading(false);
  };

  const printTicket = async () => {
    setLoading(true);
    try {
      const base64 = await getBase64File();
      printBase64(base64);
    } catch (error) {
      messageError({
        context: 'PurchasedTicketItem - downloadPDF',
        message: getErrorMessage(error),
      });
    }
    setLoading(false);
  };

  return (
    <List.Item className="PurchasedTicketItem">
      <div className="ticketData">
        <div>Ticket {ticket.id}</div>
        {ticket.firstname && ticket.lastname ? (
          <div>
            {ticket.firstname} {ticket.lastname}
          </div>
        ) : null}
      </div>
      <div className="ticketActions">
        <Tooltip title="Descargar ticket">
          <Button onClick={downloadPDF} disabled={loading} type="link">
            <DownloadOutlined />
          </Button>
        </Tooltip>
        <Divider type="vertical" />
        <Tooltip title="Imprimir ticket">
          <Button onClick={printTicket} disabled={loading} type="link">
            <PrinterOutlined />
          </Button>
        </Tooltip>
      </div>
    </List.Item>
  );
};

export default PurchasedTicketItem;
