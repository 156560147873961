import { Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import PointOfSaleList from '../../components/PointOfSale/PointOfSaleList';
import { PointOfSaleContext } from '../../contexts/PointOfSaleContext';
import { CustomMessage } from '../../hooks';
import { IPointOfSale } from '../../interfaces/PointOfSale';
import { PointOfSaleService } from '../../services/PointOfSale.service';

const PointOfSaleSelectionPage = () => {
  const { setPointOfSale } = useContext(PointOfSaleContext);
  const [pointsOfSale, setPointsOfSale] = useState<IPointOfSale[]>([]);
  const { getPointOfSalesForCurrentUser } = PointOfSaleService();
  const { messageError, getErrorMessage } = CustomMessage();
  const history = useHistory();
  const [, i18n] = useTranslation();

  useEffect(() => {
    let ignore = false;

    getPointOfSalesForCurrentUser()
      .then((result) => {
        if (!ignore) {
          setPointsOfSale(
            result.sort(
              (a, b) =>
                a.tenant.name.localeCompare(b.tenant.name, i18n.language) ||
                a.name.localeCompare(b.name, i18n.language),
            ),
          );
        }
      })
      .catch((err) =>
        messageError({
          context: 'PointOfSaleSelectionPage - getPointOfSalesForCurrentUser',
          message: getErrorMessage(err),
        }),
      );

    return () => {
      ignore = false;
    };
  }, []);

  const onPointOfSaleSelect = (pointOfSaleSelected: IPointOfSale) => {
    setPointOfSale(pointOfSaleSelected);
    history.push('/app/pointofsale/eventSelection');
  };

  return (
    <>
      <Typography.Title level={4}>Seleccione una ventanilla</Typography.Title>
      <PointOfSaleList
        onItemClick={onPointOfSaleSelect}
        pointsOfSale={pointsOfSale}
      />
    </>
  );
};

export default PointOfSaleSelectionPage;
