export const CREATE_EVENT = `
mutation createEvent($input: CreateEventInput!, $file: Upload) {
  createEvent(input: $input, file: $file) {
    __typename
    ... on Event {
      id
      name
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DELETE_EVENT = `
mutation deleteEvent($id: Int!) {
  deleteEvent(id: $id) {
    __typename
    ... on Event {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPDATE_EVENT = `
mutation updateEvent($id: Int!, $input: UpdateEventInput!, $file: Upload) {
  updateEvent(id: $id, input: $input, file: $file) {
    __typename
    ... on Event {
      id
      name
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
