import { Header } from 'antd/lib/layout/layout';
import './index.less';
import { AvatarComponent } from './AvatarComponent/index';
import CurrentRouteLabel from './CurrentRouteLabel/CurrentRouteLabel';

export const HeaderComponent = () => {
  return (
    <Header className="HeaderComponent">
      <CurrentRouteLabel className="HeaderRouteLabel" />
      {/* Este div habría que sacarlo luego y poner un BreadCrumb o usar algun estado del título actual de la página */}
      <div className="Filler"></div>
      <AvatarComponent />
    </Header>
  );
};
