import { DatePicker } from 'antd';
import { FORMAT_DATE_TIME_1 } from './MomentJS';

export const genericDatePicker = () => (
  <DatePicker
    format={[FORMAT_DATE_TIME_1]}
    placeholder="Seleccione fecha"
    showTime={{
      showHour: true,
      showMinute: true,
    }}
    allowClear={false}
    inputReadOnly
  />
);
