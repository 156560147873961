import { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Input, Form, Button } from 'antd';
import { Tools } from '../../shared';
import './ResetPasswordContent.less';
import GraphqlService from '../../services/graphql/GraphqlService';
import { useTranslation } from 'react-i18next';
import { NavContext } from '@ionic/react';
import { CustomMessage } from '../../hooks';
import { EnumsValues } from '../../enums/EnumsValues';

const ResetPasswordContentPage = () => {
  // const { selectedLanguage } = useContext(ContextApp);
  const [t] = useTranslation();
  const [formErrors, setFormErrors] = useState(true);
  const { navigate } = useContext(NavContext);
  const history = useHistory();

  // services and hooks
  const { Mutation, customRequest } = GraphqlService();

  const { messageError, messageModalInfo } = CustomMessage();

  interface IDataResetPassword {
    email: string;
  }
  const resetPassword = async (values: any) => {
    const { email } = values as IDataResetPassword;
    try {
      await customRequest({
        mutation: Mutation.resetPassword,
        variables: {
          email: email,
          // language_id: selectedLanguage?.id,
        },
      });
      messageModalInfo({
        context: 'TableUser.resetPassword.3',
        message: t(
          'label.resetPassword.successMessage',
          'En caso de ser correcto el correo, enviaremos un email con instrucciones para restablecer la contraseña',
        ),
        props: {
          content: t(
            'label.resetPassword.successMessage',
            'En caso de ser correcto el correo, enviaremos un email con instrucciones para restablecer la contraseña',
          ),
          afterClose: () => {
            navigate('/signin/login');
          },
        },
      });
    } catch (error) {
      messageError({
        context: 'TableUser.resetPassword.3',
        message: t(
          'label.resetPassword.failedMessage',
          'Hemos tenido un problema para recuperar tu contraseña. Intentálo nuevamente',
        ),
      });
    }
  };

  return (
    <div className="container_reset_password_content">
      <div className="container_text_reset_password_content">
        <p className="text_reset_password_content">
          {t(
            'label.resetPassword.enterEmail',
            'Ingresa tu correo electrónico para reestablecer contraseña.',
          )}
        </p>
      </div>
      <div className="container_form_reset_password_content">
        <Form
          name="reset_password"
          onFinish={resetPassword}
          onFieldsChange={(_, allFields) => {
            setFormErrors(() =>
              allFields.some((field) => !!field.errors?.length),
            );
          }}
        >
          <div className="items">
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t(
                    'validation.resetPassword.emailRequired',
                    'Por favor ingrese el email',
                  ),
                },
                {
                  max: EnumsValues.MaxLengthInputs.SystemUser_Email,
                  message: t(
                    'validation.resetPassword.emailLengthValidation',
                    'El email debe tener un máximo de {{max}} caracteres',
                    {
                      max: EnumsValues.MaxLengthInputs.SystemUser_Email,
                    },
                  ),
                },
                {
                  type: 'email',
                  message: t(
                    'validation.resetPassword.emailType',
                    'No es un email válido!',
                  ),
                },
              ]}
            >
              <Input
                placeholder={Tools.capitalize(
                  t('abm.full-email', 'Correo electrónico'),
                )}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="btn_reset_password"
                disabled={formErrors}
              >
                {Tools.capitalize(
                  t('button.resetPassword.reset', 'restablecer'),
                )}
              </Button>
              <Button
                type="primary"
                className="btn_back"
                onClick={() => history.goBack()}
              >
                {Tools.capitalize(t('ant-design.pageheader.back', 'volver'))}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetPasswordContentPage;
