import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  UploadOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import ProTable, { ActionType } from '@ant-design/pro-table';
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Switch,
  Tooltip,
  Upload,
  Image,
  Spin,
  InputNumber,
  Typography,
} from 'antd';
import { ExporterDropdown, SaveForm } from '../../components/common/ABM';
import imageNotFound from '../../assets/imageNotFound.jpg';
import {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { ExportableColumn, ExportableTable } from '../../shared/Exporter';
import { SorterResult } from 'antd/lib/table/interface';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ABM, Authorization, MomentJS, Tools } from '../../shared';
import { isPlatform } from '@ionic/react';
import { showCollapseRender } from '../../shared/showCollapseRender';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import { ParamsType } from '@ant-design/pro-provider';
import { getPaginationArgs } from '../../shared/getPaginationArgs';
import { ITenant } from '../../interfaces';
import DebouncerInput from '../../components/common/DebouncerInput/Index';
import { RcFile } from 'antd/lib/upload';
import { IAppSetting } from '../../interfaces/AppSetting';
import { tooltipTrigger } from '../../shared/antdUtils';
import './TenantPage.less';
/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = ['name', 'slug', 'id', 'active'];
const LIST_SORTER = ['name'];

/**
 * Se configura por cada ABM diferente
 */
const TITLE_PRO_TABLE = 'Formulario de consulta';
const TITLE_CREATE_FORM = 'Formulario de alta';
const TITLE_UPDATE_FORM = 'Editar Tenant';
const INPUT_SEARCH_PLACEHOLDER = 'Buscar...';

export default function TenantPage() {
  // props

  // states
  const [searchText, setSearchText] = useState('');
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] =
    useState<ITenant | undefined>(undefined);
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>('');
  const [dataTable, setDataTable] = useState<ITenant[]>([]);
  const [tenantsCombo, setTenantsCombo] = useState<ITenant[]>([]);
  const [maxSizeFile, setMaxSizeFile] = useState<number>(
    EnumsValues.SystemLimits.MaxSizeOfFiles,
  );
  const [errorFile, setErrorFile] = useState<string>();
  const [urlFile, setUrlFile] = useState<string>();
  const [fileMetaData, setFileMetaData] =
    useState<RcFile & { filename?: string }>();
  const [uploadImage, setUploadImage] = useState<boolean>(false);
  const [deleteCurrentImage, setDeleteCurrentImage] = useState<boolean>(false);
  const [loadingTenantPicture, setLoadingTenantPicture] =
    useState<boolean>(false);
  const [loadedPictureId, setLoadedPictureId] = useState<number | undefined>();
  const [previewImageTenantId, setPreviewImageTenantId] = useState<number>();
  const [authLinkMP, setAuthLinkMP] = useState<string>();

  // services and hooks
  const { mobileOnSizeChangeProTable, changeView, showComponent } =
    useProTableForMobile({
      layout: 'horizontal',
    });
  const { Query, Mutation, customRequest, customFileRequest } =
    GraphqlService();
  const {
    messageError,
    messageDeleteSuccess,
    messageDeleting,
    messageUpdateSuccess,
    messageUpdateError,
    messageCreateSuccess,
    messageCreating,
    messageUpdating,
    messageDestroy,
  } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  // contexts
  const { functions } = useContext(ContextApp);

  // methods

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const getTenantsForCombo = async () => {
    try {
      const data: ITenant[] = await customRequest({
        query: Query.tenants,
      });
      setTenantsCombo(data);
    } catch {
      // Intentional
    }
  };

  useEffect(() => {
    getTenantsForCombo();
  }, []);

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    },
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search = JSON.parse(JSON.stringify(params));

      if (searchText) {
        variables.current.searchText = searchText;
      } else {
        delete variables.current.searchText;
      }

      LIST_FILTER.forEach((element) => {
        try {
          if (Tools.isDefined(search[element])) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }

            variables.current.filter[element] = search[element];
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });
      variables.current.filter ?? (variables.current.filter = {});
      variables.current.filter.id?.length === 0 &&
        (variables.current.filter.id = undefined);

      LIST_SORTER.forEach((element) => {
        try {
          if (search.sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search.sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      const countPromise = customRequest({
        query: Query.tenantCount,
        variables: variables.current,
      }).then((data: { count: number }) => data.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current,
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = customRequest({
        query: Query.tenants,
        variables: variables.current,
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);
      setDataTable(data);
      setFormLoading(false);
      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };
  const getTenantPicture = async (tenant_picture_id?: number) => {
    let currentPictureId: number | undefined | null;
    setLoadedPictureId(tenant_picture_id);
    if (!tenant_picture_id) return;
    setLoadingTenantPicture(true);
    try {
      const data = await customRequest({
        query: Query.getTenantPicture,
        variables: { id: tenant_picture_id },
      });
      setLoadedPictureId((oldValue) => {
        currentPictureId = oldValue;
        return tenant_picture_id;
      });
      if (tenant_picture_id === currentPictureId) {
        setUrlFile(data.result);
      }
    } catch (error: any) {
      messageError({
        context: 'TenantPage.getTenantPicture.1',
        message: error.message,
      });
    } finally {
      setLoadingTenantPicture(false);
    }
  };

  const getLimitMaxSizeFileSetting = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.LimitMaxSizeFile },
        },
      });
      setMaxSizeFile(Number(data.setting_value));
    } catch (error) {
      // Intentional
    }
  };

  useEffect(() => {
    getLimitMaxSizeFileSetting();
  }, []);

  const createTenant = async (value: {
    name: string;
    slug: string;
    tenant_picture_id?: number;
    mercadopago_id?: number;
  }) => {
    setFormLoading(true);
    delete value.tenant_picture_id;
    try {
      await validateAvailableSlug(null, value.slug, null);
      messageCreating({
        context: 'TenantPage.createTenant.1',
        message: 'Tenant',
      });
      await customFileRequest(
        {
          mutation: Mutation.createTenant,
          variables: {
            input: value,
            file:
              fileMetaData && uploadImage
                ? {
                    filename: fileMetaData?.filename,
                    mimetype: fileMetaData?.type,
                    encoding: 'base64',
                  }
                : undefined,
          },
        },
        fileMetaData && uploadImage
          ? [
              {
                file: fileMetaData,
                path: 'variables.file',
              },
            ]
          : [],
      );
      setCreateModalVisible(false);

      if (value.mercadopago_id) {
        showModalWithMPAuthLink();
      } else {
        messageCreateSuccess({
          context: 'TenantPage.createTenent.2',
        });
      }

      if (actionRef.current) {
        actionRef.current.reload();
      }
      getTenantsForCombo();
    } catch (error: any) {
      if (error.status_code && error.message) {
        setFormLoading(false);
        return messageError({
          context: 'TenantPage.createTenent.3',
          message: error.message,
        });
      }
    }
    resetImageStates();
    setFormLoading(false);
  };

  const updateTenant = async (value: {
    name: string;
    slug: string;
    tenant_picture_id?: number;
    mercadopago_id?: number;
  }) => {
    if (!editForm) return;
    setFormLoading(true);
    delete value.tenant_picture_id;
    try {
      await validateAvailableSlug(null, value.slug, null);
      messageUpdating({
        context: 'TenantPage.updateTenant.1',
        message: 'tenant',
      });

      const updatedTenant = await customFileRequest(
        {
          mutation: Mutation.updateTenant,
          variables: {
            id: editForm.id,
            input: {
              ...value,
              deleteCurrentImage: deleteCurrentImage && !uploadImage,
            },
            file:
              fileMetaData && uploadImage
                ? {
                    filename: fileMetaData?.name,
                    mimetype: fileMetaData?.type,
                    encoding: 'base64',
                  }
                : undefined,
          },
        },
        fileMetaData && uploadImage
          ? [
              {
                file: fileMetaData,
                path: 'variables.file',
              },
            ]
          : [],
      );

      if (editForm.mercadopago_id !== updatedTenant.mercadopago_id) {
        showModalWithMPAuthLink();
      } else {
        messageUpdateSuccess({
          context: 'TenantPage.updateTenant.2',
        });
      }

      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }

      getTenantsForCombo();
    } catch (error: any) {
      setFormLoading(false);
      if (error.status_code && error.message) {
        return messageError({
          context: 'TenantPage.updateTenant.3',
          message: error.message,
        });
      }
      messageUpdateError({ context: 'TenantPage.updateTenant.3' });
    }
    handleUpdateModalVisible(false);
    setEditFormValues(undefined);
    resetImageStates();
    setFormLoading(false);
  };

  const removeConfirmedTenant = async (value: { id: number; name: string }) => {
    messageDeleting({
      context: 'TenantPage.removeConfirmedTenant.1',
      message: 'Tenant',
    });
    try {
      await customRequest({
        mutation: Mutation.deleteTenants,
        variables: {
          ids: [value.id],
        },
      });
      messageDeleteSuccess({
        context: 'TenantPage.removeConfirmedTenant.2',
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
      getTenantsForCombo();
    } catch (error: any) {
      messageError({
        context: 'TenantPage.removeConfirmedTenant.3',
        message: error.message,
      });
    }
  };

  const removeTenant = (value: { id: number; name: string }) => {
    Modal.confirm({
      content: (
        <>
          <div>¿Seguro que desea eliminar el tenant {value.name}?</div>
        </>
      ),
      cancelText: 'Cancelar',
      okText: 'Aceptar',
      onOk: () => {
        removeConfirmedTenant(value);
      },
    });
  };

  const findTenantBySlug = async (slug: string): Promise<ITenant | null> => {
    try {
      return await customRequest({
        query: Query.tenant,
        variables: {
          input: {
            slug: slug,
            only_active: false,
          },
        },
      });
    } catch (err: any) {
      if (err.status_code !== 2) {
        messageError({
          context: 'TenantPage.findTenantBySlug.3',
          message: err.message,
        });
      }
      return null;
    }
  };

  const validateAvailableSlug = async (
    _rule: any,
    value: any,
    _validatorCallback: any,
  ): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      if (value) {
        try {
          const tenant = await findTenantBySlug(value);
          if (tenant && (!editForm || editForm.slug !== tenant.slug)) {
            reject('Slug no disponible');
          } else {
            resolve('');
          }
        } catch (err) {
          resolve('');
        }
      } else {
        resolve('');
      }
    });
  };

  const resetImageStates = () => {
    setErrorFile(() => undefined);
    setFileMetaData(() => undefined);
    setLoadingTenantPicture(() => false);
    setUploadImage(() => false);
    setUrlFile(() => undefined);
    setDeleteCurrentImage(() => false);
  };

  const uploadRequest = (uploadRequestOptions: any) => {
    setLoadingTenantPicture(() => true);
    const { onSuccess, file } = uploadRequestOptions;
    const fileRc = file as RcFile;
    if (fileRc.size > maxSizeFile) {
      setLoadingTenantPicture(() => false);
      setErrorFile('El tamaño de la imagen excede el límite permitido.');
    } else {
      setErrorFile(undefined);
      Tools.getBase64WithCallback(file, (fileUrl: string) => {
        setUrlFile(fileUrl);
      });
      setFileMetaData(fileRc);
      if (onSuccess) {
        setLoadingTenantPicture(() => false);
        setUploadImage(true);

        // TODO: Se realiza de esta manera por un bug de antd
        setTimeout(onSuccess, 0);
      }
    }
  };

  const columns = useCallback(
    (): ExportableColumn<ITenant>[] => [
      {
        export: false,
        dataIndex: 'id',
        title: 'Id',
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
        renderFormItem: () => (
          <Select
            options={tenantsCombo.map((tenant) => ({
              label: tenant.name,
              value: tenant.id,
            }))}
            placeholder="Seleccione tenant..."
            mode="multiple"
            allowClear
            showSearch
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        className: 'name-cell',
        dataIndex: 'name',
        title: 'Nombre',
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'El nombre es obligatorio',
            },
          ],
        },
        render: (_, record: { name: string }) => record.name || '-',
        renderFormItem: () => (
          <Input placeholder="Ingrese nombre" minLength={4} />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'slug',
        title: 'Slug',
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: true,
              message: 'El slug es obligatorio',
            },
            {
              validator: validateAvailableSlug,
            },
          ],
        },
        render: (_, record: { slug: string }) => record.slug || '-',
        renderFormItem: (_, __, formInstance) => (
          <DebouncerInput
            id="slug"
            placeholder="Ingrese slug"
            minLength={4}
            onChange={(value) => {
              formInstance.setFieldsValue({ slug: value });
              formInstance.validateFields(['slug']);
            }}
            initialValue={editForm ? editForm.slug : undefined}
          />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: 'tenant_picture_id',
        title: 'Imagen',
        type: ABM.TYPE_COLUMN.STRING,
        renderFormItem: () => {
          const uploadButton = (
            <Button>
              <UploadOutlined />
              Upload
            </Button>
          );
          return (
            <>
              {errorFile ? (
                <Alert
                  style={{ marginTop: '14px' }}
                  message={errorFile}
                  type="error"
                  showIcon
                />
              ) : null}
              <Form.Item>
                <Upload
                  fileList={
                    loadingTenantPicture || fileMetaData
                      ? [
                          {
                            status: loadingTenantPicture
                              ? 'uploading'
                              : undefined,
                            name:
                              fileMetaData?.filename ||
                              fileMetaData?.name ||
                              '-',
                            uid: '1',
                            url: urlFile,
                          },
                        ]
                      : undefined
                  }
                  listType="picture"
                  className="avatar-uploader"
                  showUploadList={true}
                  onRemove={() => {
                    setErrorFile(() => undefined);
                    setFileMetaData(() => undefined);
                    setLoadingTenantPicture(() => false);
                    setUploadImage(() => false);
                    setUrlFile(() => undefined);
                    setDeleteCurrentImage(() => true);
                    setEditFormValues((old) => {
                      if (old?.tenant_picture_id)
                        old.tenant_picture_id = undefined;
                      return old;
                    });
                  }}
                  multiple={false}
                  maxCount={1}
                  customRequest={(uploadRequestOptions) => {
                    uploadRequest(uploadRequestOptions);
                  }}
                >
                  {uploadButton}
                </Upload>
              </Form.Item>
            </>
          );
        },
        align: 'left',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'active',
        title: 'Activo',
        render: (_, record) => {
          if (record.active) {
            return <CheckOutlined style={{ color: 'green' }} />;
          }
          return <CloseOutlined style={{ color: 'red' }} />;
        },
        renderDataExport: (record) => {
          return record.active ? 'Si' : 'No';
        },
        align: 'center',
        formItemProps: {
          valuePropName: 'checked',
        },
        renderFormItem: () => {
          return <Switch />;
        },
        type: ABM.TYPE_COLUMN.BOOLEAN,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: 'active',
        title: 'Activo',
        type: ABM.TYPE_COLUMN.BOOLEAN,
        renderFormItem: () => {
          return (
            <Select
              options={[
                {
                  label: 'Si',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              allowClear
            />
          );
        },
        hideInForm: true,
        hideInTable: true,
      },
      {
        export: false,
        dataIndex: 'mercadopago_id',
        title: (
          <div>
            Id de Mercado Pago
            <Tooltip
              trigger={tooltipTrigger}
              title={
                <div>
                  Aparece en la página{' '}
                  <Typography.Text style={{ color: 'inherit' }} copyable>
                    https://www.mercadopago.com.ar/developers/panel
                  </Typography.Text>{' '}
                  del tenant como "User ID"
                </div>
              }
            >
              <InfoCircleOutlined style={{ paddingLeft: '0.25rem' }} />
            </Tooltip>
          </div>
        ),
        type: ABM.TYPE_COLUMN.NUMBER,
        hideInTable: true,
        hideInSearch: true,
        formItemProps: {
          rules: [
            {
              validator: (_, value) => {
                if (value && !Number.isInteger(Number(value))) {
                  return Promise.reject(
                    'El ID de mercado pago debe ser un número entero',
                  );
                }
                return Promise.resolve();
              },
            },
          ],
        },
        renderFormItem: () => {
          return (
            <InputNumber
              placeholder="Ingrese ID"
              controls={false}
              precision={0}
            />
          );
        },
      },
      {
        title: 'Op.',
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 100,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.ReadTenantPicture,
            ) && (
              <>
                <Tooltip
                  key="preview_tenant"
                  trigger={tooltipTrigger}
                  title="Previsualizar imagen del tenant"
                >
                  <EyeOutlined
                    className="pointer"
                    onClick={() => {
                      setPreviewImageTenantId(record.id);
                      getTenantPicture(record.tenant_picture_id);
                    }}
                  />
                </Tooltip>
              </>
            )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.TenantUpdate,
            ) && (
              <>
                <Divider type="vertical" />
                <Tooltip
                  key="edit_tenant_tooltip"
                  trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                  title="Modificar tenant"
                >
                  <EditOutlined
                    className="pointer"
                    onClick={() => {
                      setEditFormValues(record);
                      getTenantPicture(record.tenant_picture_id);
                      handleUpdateModalVisible(true);
                    }}
                  />
                </Tooltip>
              </>
            )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.TenantDelete,
            ) && (
              <>
                <Divider type="vertical" />
                <Tooltip
                  key="remove_tenant_tooltip"
                  trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                  title="Eliminar tenant"
                >
                  <DeleteOutlined
                    className="pointer"
                    onClick={() => {
                      removeTenant(record);
                    }}
                  />
                </Tooltip>
              </>
            )}
          </>
        ),
      },
    ],
    [
      dataTable,
      editForm,
      urlFile,
      maxSizeFile,
      loadingTenantPicture,
      errorFile,
      fileMetaData,
    ],
  );
  let LIST_FILTER_NAMES = columns()
    // eslint-disable-next-line array-callback-return
    .filter((value) => {
      if (
        LIST_FILTER.find(
          (element) =>
            element === value.dataIndex && value.hideInTable === false,
        )
      ) {
        return value.title;
      }
    })
    .map((element) => {
      return element.title;
    });

  const exportableTable: ExportableTable<ITenant> = {
    columns: columns(),
    items: dataTable,
    filename: `tenant_${MomentJS.momentDefaultFormat()}`,
  };

  const previewTenantImage = useMemo(() => {
    if (!previewImageTenantId) {
      return null;
    }
    return (
      <Modal
        visible
        footer={null}
        width="max-content"
        bodyStyle={{ paddingBottom: 48 }}
        onCancel={() => {
          setPreviewImageTenantId(undefined);
          resetImageStates();
        }}
        title="Previsualización"
        destroyOnClose
      >
        {!urlFile && loadedPictureId != null ? (
          <div
            className="tenant-image-preview"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image
              src={urlFile ? urlFile : ''}
              alt="Imagen no encontrada"
              preview={false}
              className="tenant-image-preview"
              fallback={imageNotFound}
            />
          </div>
        )}
      </Modal>
    );
  }, [previewImageTenantId, dataTable, urlFile, loadingTenantPicture]);

  const getMPAuthLink = useCallback(async (): Promise<string> => {
    if (authLinkMP) {
      return authLinkMP;
    }

    const { authenticationLink } = await customRequest({
      query: Query.getAuthenticationLink,
    });

    setAuthLinkMP(authenticationLink);

    return authenticationLink;
  }, [authLinkMP]);

  const showModalWithMPAuthLink = useCallback(async () => {
    const authLink = await getMPAuthLink();

    messageDestroy();
    Modal.info({
      content: (
        <div>
          Por favor comparta el siguiente link con el tenant para autorizar el
          uso de Mercado Pago:{' '}
          <Typography.Text copyable>{authLink}</Typography.Text>
        </div>
      ),
      className: 'modal-auth-link',
    });
  }, [getMPAuthLink]);

  return (
    <>
      <ProTable<ITenant>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        headerTitle={TITLE_PRO_TABLE}
        actionRef={actionRef}
        rowKey="id"
        search={{
          resetText: 'Limpiar',
          searchText: 'Buscar',
          collapseRender: showCollapseRender(columns()),
        }}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<ITenant>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <div className="content-search-table">
            <Tooltip
              key="searchtext"
              title={'Buscar por: ' + LIST_FILTER_NAMES.join(', ')}
            >
              <Input.Search
                size="middle"
                placeholder={INPUT_SEARCH_PLACEHOLDER}
                enterButton
                value={searchText}
                onSearch={handleSearch}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                allowClear={true}
              />
            </Tooltip>
          </div>,
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.TenantCreate,
            ) && (
              <Button
                type="primary"
                onClick={() => {
                  setCreateModalVisible(true);
                }}
                icon={<PlusOutlined />}
              >
                Nuevo
              </Button>
            )}
          </>,
          <>
            <ExporterDropdown exportable={exportableTable} />
          </>,
          <Button onClick={changeView} className="buttonChangeProTableView">
            Cambiar Vista
          </Button>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns()}
      />
      <SaveForm
        loading={formLoading}
        title={TITLE_CREATE_FORM}
        onCancel={() => {
          setCreateModalVisible(false);
          resetImageStates();
        }}
        modalVisible={createModalVisible}
        onOk={createTenant}
        columns={columns()}
        notIgnoreFalsyValues={true}
      />
      {editForm && (
        <SaveForm
          loading={formLoading}
          title={TITLE_UPDATE_FORM}
          modalVisible={updateModalVisible}
          values={{
            ...editForm,
            active: editForm.active || undefined,
          }}
          columns={columns()}
          onOk={updateTenant}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues(undefined);
            resetImageStates();
          }}
          notIgnoreFalsyValues={true}
        />
      )}
      {previewTenantImage}
    </>
  );
}
