import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { ContextApp } from '../../contexts/ContextApp';
const DefaultRedirectPage = () => {
  const { user } = useContext(ContextApp);
  if (user) {
    return <Redirect to="/app/home" />;
  } else {
    return <Redirect to="/home" />;
  }
};

export default DefaultRedirectPage;
