import { useState, useEffect, useContext } from 'react';
import { Input, Form, Button, Result } from 'antd';
import { PageLoading } from '@ant-design/pro-layout';
import { useLocation } from 'react-router';
import { Tools } from '../../shared';
import './SetPasswordContent.less';
import GraphqlService from '../../services/graphql/GraphqlService';
import { NavContext } from '@ionic/react';
import { IAppSetting } from '../../interfaces/AppSetting';
import { EnumsValues } from '../../enums/EnumsValues';
import { useTranslation } from 'react-i18next';
import { CustomMessage } from '../../hooks';

const SetPasswordContentPage = () => {
  // services and hooks
  const [t] = useTranslation();
  const { navigate } = useContext(NavContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { search } = useLocation();
  const [form] = Form.useForm();
  const [formErrors, setFormErrors] = useState(true);
  const queryStrings = new URLSearchParams(search);
  const token = queryStrings.get('token');
  const [validToken, setValidToken] = useState<boolean | null>(false);
  const [passwordRegex, setPasswordRegex] = useState<IAppSetting>();
  const [isFormSubmited, setIsFormSubmited] = useState<boolean>(false);

  const { Query, Mutation, customRequest } = GraphqlService();

  const { messageError, messageModalSuccess, getErrorMessage } =
    CustomMessage();

  const getRegex = async (): Promise<void> => {
    try {
      const data = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.PasswordRegex },
        },
      });
      setPasswordRegex(() => data);
    } catch (error) {
      messageError({
        context: 'User.getRegex.1',
        message: getErrorMessage(error),
      });
    }
  };

  const compareToFirstPassword = (_: any, value: any) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject(
        t('validation.passwordsNotMatch', 'Las contraseñas no coinciden'),
      );
    } else {
      return Promise.resolve();
    }
  };

  const validateToken = async () => {
    /* Verificamos si el token es válido*/
    try {
      await customRequest({
        query: Query.verifyRecoveryPasswordToken,
        variables: { token },
      });
      setValidToken(true);
    } catch (error) {
      setValidToken(false);
    }
  };
  useEffect(() => {
    getRegex();
    if (token) {
      validateToken();
    }
  }, [token]);

  interface IDataSetPassword {
    token: string;
    password: string;
  }
  const setPassword = async (values: any) => {
    const { password } = values as IDataSetPassword;
    setLoading(true);
    try {
      await customRequest({
        mutation: Mutation.setPassword,
        variables: {
          token,
          password,
        },
      });
      setLoading(false);
      messageModalSuccess({
        context: 'TableUser.changePassword.3',
        message: t(
          'label.setPassword.successMessage',
          '¡Tu contraseña fue restablecida con éxito!',
        ),
      });
    } catch (error) {
      setLoading(false);
      messageError({
        context: 'TableUser.resetPassword.3',
        message: t(
          'label.setPassword.failedMessage',
          'Hemos tenido un problema para reestablecer tu contraseña. Intentálo nuevamente',
        ),
      });
    }
  };

  return loading || validToken === null ? (
    <PageLoading />
  ) : !validToken ? (
    <>
      <label>
        {t(
          'label.setPassword.invalidLink',
          'El link ya no es válido, vuelva a solicitar un nuevo link.',
        )}
      </label>
      <br />
      <br />
      <Button
        type="primary"
        className="btn_return_login"
        onClick={() => navigate('/signin/login')}
      >
        {Tools.capitalize(t('button.backToLogin', 'volver al login'))}
      </Button>
    </>
  ) : !isFormSubmited ? (
    <div className="container_set_password_content">
      <div className="container_text_set_password_content">
        <p className="text_set_password_content">
          {t(
            'label.setPassword.enterNewPassword',
            'Ingresa tu nueva contraseña',
          )}
        </p>
      </div>
      <div className="container_form_set_password_content">
        <Form
          name="set_password"
          onFinish={(value) => {
            setPassword(value);
            setIsFormSubmited(true);
          }}
          form={form}
          onFieldsChange={(_, allFields) => {
            setFormErrors(() =>
              allFields.some((field) => !!field.errors?.length),
            );
          }}
        >
          <div className="items">
            <Form.Item
              hasFeedback
              name="password"
              rules={[
                {
                  required: true,
                  message: Tools.capitalize(
                    t(
                      'validation.generalValidationRequired',
                      'Campo requerido',
                    ),
                  ),
                },
                {
                  validator(_, value) {
                    setTimeout(() => {
                      form.validateFields(['confirm']);
                    }, 50);
                    if (passwordRegex) {
                      let regex = new RegExp(
                        String(passwordRegex?.setting_value),
                        'g',
                      );
                      if (regex.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(passwordRegex?.description),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input.Password
                placeholder={Tools.capitalize(t('abm.password', 'contraseña'))}
              />
            </Form.Item>
            <Form.Item
              hasFeedback
              name="confirm"
              rules={[
                {
                  required: true,
                  message: Tools.capitalize(
                    t(
                      'validation.generalValidationRequired',
                      'Campo requerido',
                    ),
                  ),
                },
                {
                  validator: compareToFirstPassword,
                },
              ]}
            >
              <Input.Password
                placeholder={Tools.capitalize(
                  t('abm.repeatPassword', 'Repetir contraseña'),
                )}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="btn_set_password"
                disabled={formErrors}
              >
                {Tools.capitalize(
                  t('button.updatePassword', 'actualizar contraseña'),
                )}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  ) : (
    <Result
      status="success"
      title={t(
        'label.setPassword.successMessage',
        '¡Tu contraseña fue restablecida con éxito!',
      )}
    />
  );
};

export default SetPasswordContentPage;
